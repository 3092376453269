import { computed } from 'vue';

import FlightTime from '../FlightTime/FlightTimeComponent.vue';
import StopDrawing from '../../components/StopDrawing/StopDrawingComponent.vue';
import MobileStopDrawing from '../../components/MobileStopDrawing/MobileStopDrawingComponent.vue';
import { useStore } from 'vuex';
import detailUtils from '../../utils/detailUtils';

export default {
  name: 'FlightDetailsModalContent',
  components: { MobileStopDrawing, FlightTime, StopDrawing },
  props: ['totalTravelers', 'flightDetails', 'pageLabels', 'getAirport', 'getAirlineLogo', 'getPriceClass', 'chunkifyPerks', 'howManyCols'],
  setup: (props) => {
    const store = useStore();

    const flightDetails = computed(() => props?.flightDetails || store.getters.flightDetails);
    const totalTravelers = computed(() => props?.totalTravelers || store.getters.totalTravelers);
    const pageLabels = computed(() => props?.pageLabels || store.getters.pageLabels);
    const { getAirport, getPriceClass, getAirlineLogo, howManyCols, chunkifyPerks } = detailUtils;
    const isDetailsModalOpen = computed({
      get: () => { return store.getters.isDetailsModalOpen; },
      set: (newValue) => { store.dispatch('setIsDetailsModalOpen', newValue); }
    });

    const comparePriceClasses = computed(() => {
      try {
        // console.log('comparing price classes', flightDetails.value.priceClasses.outbound?.priceCode, flightDetails.value.priceClasses.inbound?.priceCode);
        const a = getPriceClass(flightDetails.value.priceClasses.outbound?.priceCode);
        const b = getPriceClass(flightDetails.value.priceClasses.inbound?.priceCode);
        return JSON.stringify(a) === JSON.stringify(b);
      } catch (error) {
        console.error(error);
        return false;
      }
    });

    return {
      comparePriceClasses,
      totalTravelers,
      flightDetails,
      pageLabels,
      getAirport,
      getAirlineLogo,
      getPriceClass,
      chunkifyPerks,
      howManyCols,
      isDetailsModalOpen
    };
  }
};
