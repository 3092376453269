<section class="pdp-num-cl-0 payment-details-page"
         v-if="sessionResolved">
  <Header />
  <div class="pdp-num-cl-1 flex justify-content-center mt-3 xl:mt-5">
    <div class="pdp-num-cl-2 dynwid px-2">
      <div class="pdp-num-cl-3 breadcrumb">
        <Breadcrumb :home="breadcrumbs.home"
                    :model="breadcrumbs.items">
          <template #item="{ item }">
            <a style="height: 30px; padding-left: 6px; padding-right: 6px;"
               :href="item.url"
               :class="{ active: item.state == 'current', 'disabled': item.state == 'next' }"
               class="pdp-num-cl-5 breadcrumb-item flex justify-content-center align-items-center">
              <div class="pdp-num-cl-6 flex">
                <img v-if="item.icon"
                     :src="item.icon"
                     class="pdp-num-cl-7 home-icon">
                <div class="pdp-num-cl-8 flex"
                     v-if="!item.icon">
                  <div>
                    {{ item.number }}{{'.'}}
                  </div>
                  <div class="pdp-num-cl-9 hidden xl:block">
                    {{' '}}{{ item.label }}
                  </div>
                  <div class="pdp-num-cl-10 block xl:hidden"
                       v-if="item.state === 'current'">
                    {{' '}}{{ item.label }}
                  </div>
                </div>
              </div>
            </a>
          </template>
        </Breadcrumb>
      </div>
      <h2 class="pdp-num-cl-11 font-semibold mt-5 mb-2">
        {{paymentStoreData.data.content.pageLabels.headline}}
      </h2>
    </div>
  </div>
  <div class="pdp-num-cl-12 flex gray justify-content-center mt-1">
    <div class="pdp-num-cl-13 dynwid">
      <div class="pdp-num-cl-14 grid pt-6 pb-6"
           id="main-container">
        <div class="pdp-num-cl-15 col-12 xl:col-9">
          <div class="pdp-num-cl-16 grid grid-nogutter">
            <Accordion class="pdp-num-cl-17 col-12"
                       v-model:activeIndex="activeIndex">
              <AccordionTab :header="paymentStoreData.data.content.pageLabels.flightDetails">
                <div class="pdp-num-cl-18 block xl:hidden">

                  <!-- HEADER -->
                  <div class="pdp-num-cl-19 flex justify-content-between align-items-center p-3">
                    <div>{{ paymentStoreData.data.content.pageLabels.flightDetails }}</div>
                  </div>

                  <!-- CITIES -->
                  <div class="pdp-num-cl-20 flex justify-content-center align-items-center">
                    <div class="pdp-num-cl-21 text-xl">
                      {{paymentStoreData.data.content.flightDetails.outbound.segments[0].departure.code}}
                    </div>
                    <div class="pdp-num-cl-22 px-2"><i class="pdp-num-cl-23 pi pi-sort-alt pi pi-sort-alt"></i></div>
                    <div class="pdp-num-cl-24 text-xl">{{
                      paymentStoreData.data.content.flightDetails.outbound.segments[paymentStoreData.data.content.flightDetails.outbound.segments.length
                      -1].arrival.code }}</div>
                  </div>

                  <!-- PRICE PER PERSON -->
                  <div class="pdp-num-cl-25 flex justify-content-center pb-2">
                    <div>
                      <div class="pdp-num-cl-26 price-per-person-label text-xs text-center mt-1">
                        {{ paymentStoreData.data.content.pageLabels.pricePerPerson }}
                      </div>
                      <div class="pdp-num-cl-27 price-per-person-amount text-center text-xl font-bold">
                        <FormattedCurrency :amount="paymentStoreData.data.content.sidebar.priceInfo.totalPrice[paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex]]?.amount"
                                           :currency="paymentStoreData.data.content.sidebar.priceInfo.totalPrice[paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex]]?.currency"
                                           :currencyPosition="paymentStoreData.data.content.sidebar.priceInfo.totalPrice[paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex]]?.currencyPosition" />
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-28 bg-gray-400 h1p"> </div>

                  <!-- LEGS -->
                  <!-- DEPARTURE -->
                  <div class="pdp-num-cl-29 departure-legs">
                    <div class="pdp-num-cl-30 departure-header flex flex-wrap justify-content-between">
                      <div class="pdp-num-cl-31 col-5 text-lg font-medium pl-3"><span>{{
                          paymentStoreData.data.content.pageLabels.outbound
                          }}</span></div>
                      <div class="pdp-num-cl-32 col-5 text-xs font-medium pb-0">
                        <div class="pdp-num-cl-33 text-right pr-2">{{
                          paymentStoreData.data.content.pageLabels.flightDuration }}</div>
                        <div class="pdp-num-cl-34 text-right pr-2">{{
                          paymentStoreData.data.content.flightDetails.outbound.duration }}
                        </div>
                      </div>
                      <div class="pdp-num-cl-35 col-12 pt-0">
                        <div v-for="(leg, index) in paymentStoreData.data.content.flightDetails.outbound.segments">
                          <div v-if="index > 0"
                               class="pdp-num-cl-36 py-2">
                            <div class="pdp-num-cl-37 grid bg-gray-200 border-round">
                              <div class="pdp-num-cl-38 col-8 text-xs">
                                <span>{{ paymentStoreData.data.content.pageLabels.stopIn }} {{
                                  getAirport(leg.departure.code).city
                                  }}</span> <span class="pdp-num-cl-39 text-gray-600">{{ leg.departure.code }}</span>
                              </div>
                              <div class="pdp-num-cl-40 col-4 text-xs text-right">
                                {{ leg.stopDuration }}
                              </div>
                            </div>
                          </div>
                          <div class="pdp-num-cl-41 flex flex-wrap justify-content-between align-items-center">
                            <div class="pdp-num-cl-42 col-12 flex justify-content-between flight-times-and-wrapper">
                              <div>
                                <FlightTime class="pdp-num-cl-43 start-time"
                                            :description="true"
                                            :airport="getAirport(leg.departure.code)"
                                            :date="leg.departure.date"
                                            :time="leg.departure.time"></FlightTime>
                              </div>
                              <div class="pdp-num-cl-44 pt-3">
                                <MobileStopDrawing></MobileStopDrawing>
                              </div>
                              <div class="pdp-num-cl-45 landing-airport">
                                <FlightTime class="pdp-num-cl-46 end-time"
                                            :description="true"
                                            :airport="getAirport(leg.arrival.code)"
                                            :date="leg.arrival.date"
                                            :time="leg.arrival.time">
                                </FlightTime>
                              </div>
                            </div>
                            <div
                                 class="pdp-num-cl-47 col-5 pb-0 flex flex-wrap justify-content-start align-items-center">
                              <div class="pdp-num-cl-48 w-12 flex align-items-center">
                                <img :src="getAirlineLogo(leg.marketingAirline)"
                                     class="pdp-num-cl-49 mobile-details-logo"
                                     alt="Airline logo">
                                <div class="pdp-num-cl-50 text-gray-600 text-xs">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                            </div>
                            <div class="pdp-num-cl-51 col-5 flex justify-content-end align-items-center text-xs">{{
                              leg.duration }}
                            </div>
                            <div class="pdp-num-cl-52 w-12 line-height-1 text-xs pl-2 pt-1">
                              {{paymentStoreData.data.content.airlines[leg.marketingAirline].name}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-53 bg-gray-400 h1p"> </div>

                  <!-- Return -->
                  <div class="pdp-num-cl-54 return-legs">
                    <div v-if="paymentStoreData.data.content.flightDetails.inbound"
                         class="pdp-num-cl-55 return-header flex flex-wrap justify-content-between">
                      <div class="pdp-num-cl-56 col-5 text-lg font-medium pl-3"><span>{{
                          paymentStoreData.data.content.pageLabels.inbound
                          }}</span></div>
                      <div class="pdp-num-cl-57 col-5 text-xs font-medium pb-0">
                        <div class="pdp-num-cl-58 text-right pr-2">{{
                          paymentStoreData.data.content.pageLabels.flightDuration }}</div>
                        <div class="pdp-num-cl-59 text-right pr-2">{{
                          paymentStoreData.data.content.flightDetails.inbound.duration }}
                        </div>
                      </div>
                      <div class="pdp-num-cl-60 col-12 pt-0">
                        <div v-for="(leg, index) in paymentStoreData.data.content.flightDetails.inbound.segments">
                          <div v-if="index > 0"
                               class="pdp-num-cl-61 py-2">
                            <div class="pdp-num-cl-62 grid bg-gray-200 border-round">
                              <div class="pdp-num-cl-63 col-8 text-xs">
                                <span>{{ paymentStoreData.data.content.pageLabels.stopIn }} {{
                                  getAirport(leg.departure.code).city
                                  }}</span> <span class="pdp-num-cl-64 text-gray-600">{{ leg.departure.code }}</span>
                              </div>
                              <div class="pdp-num-cl-65 col-4 text-xs text-right">
                                {{ leg.stopDuration }}
                              </div>
                            </div>
                          </div>
                          <div class="pdp-num-cl-66 flex flex-wrap justify-content-between">
                            <div class="pdp-num-cl-67 col-12 flex justify-content-between flight-times-and-wrapper">
                              <div>
                                <FlightTime class="pdp-num-cl-68 start-time"
                                            :description="true"
                                            :airport="getAirport(leg.departure.code)"
                                            :date="leg.departure.date"
                                            :time="leg.departure.time"></FlightTime>
                              </div>
                              <div class="pdp-num-cl-69 pt-3">
                                <MobileStopDrawing></MobileStopDrawing>
                              </div>
                              <div class="pdp-num-cl-70 landing-airport">
                                <FlightTime class="pdp-num-cl-71 end-time"
                                            :description="true"
                                            :airport="getAirport(leg.arrival.code)"
                                            :date="leg.arrival.date"
                                            :time="leg.arrival.time">
                                </FlightTime>
                              </div>
                            </div>
                            <div
                                 class="pdp-num-cl-72 col-5 pb-0 flex flex-wrap justify-content-start align-items-center">
                              <div class="pdp-num-cl-73 w-12 flex align-items-center">
                                <img :src="getAirlineLogo(leg.marketingAirline)"
                                     class="pdp-num-cl-74 mobile-details-logo"
                                     alt="Airline logo">
                                <div class="pdp-num-cl-75 text-gray-600 text-xs">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                            </div>
                            <div
                                 class="pdp-num-cl-76 col-5 flex justify-content-end align-items-center text-xs text-gray-600">
                              {{
                              leg.duration }}
                            </div>
                            <div class="pdp-num-cl-77 w-12 line-height-1 text-xs pl-2 pt-1">
                              {{paymentStoreData.data.content.airlines[leg.marketingAirline].name}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-78 bg-gray-400 h1p"> </div>
                </div>
                <div class="pdp-num-cl-79 hidden xl:block">
                  <!-- DEPARTURE -->
                  <div class="pdp-num-cl-80 departure-legs">
                    <div class="pdp-num-cl-81 departure-header flex flex-wrap">
                      <div class="pdp-num-cl-82 px-6 col-12 flex">
                        <div class="pdp-num-cl-83 col-7 px-0 text-lg font-medium">
                          <span>{{ paymentStoreData.data.content.pageLabels.outbound }}</span>
                        </div>
                        <div class="pdp-num-cl-84 col-5 xl:col-3 text-xs text-center">
                          <div class="pdp-num-cl-85 text-gray-650">
                            {{ paymentStoreData.data.content.pageLabels.flightDurationTotal }}
                          </div>
                          <div class="pdp-num-cl-86 font-medium">
                            {{ paymentStoreData.data.content.flightDetails.outbound.duration }}
                          </div>
                        </div>
                        <div class="pdp-num-cl-87 hidden xl:block col-2">
                          <!-- empty col just in case -->
                        </div>
                      </div>
                      <div class="pdp-num-cl-88 col-12 px-6 pt-0">
                        <div v-for="(leg, index) in paymentStoreData.data.content.flightDetails.outbound.segments">
                          <div v-if="index > 0"
                               class="pdp-num-cl-89 py-2">
                            <div>
                              <div class="pdp-num-cl-90 flex bg-gray-200 border-round">
                                <div class="pdp-num-cl-91 col-7 text-xs">
                                  {{ paymentStoreData.data.content.pageLabels.stopIn }} {{
                                  getAirport(leg.departure.code).city }} ({{ leg.departure.code }})
                                </div>
                                <div class="pdp-num-cl-92 col-3 text-xs text-center">
                                  {{ leg.stopDuration }}
                                </div>
                                <div class="pdp-num-cl-93 col-2">
                                  <!-- empty col just in case -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="pdp-num-cl-94 flex flex-wrap justify-content-between">
                            <div
                                 class="pdp-num-cl-95 col-12 xl:col-7 px-0 flex justify-content-between flight-times-and-connector">
                              <FlightTime class="pdp-num-cl-96 start-time"
                                          :description="true"
                                          :airport="getAirport(leg.departure.code)"
                                          :date="leg.departure.date"
                                          :time="leg.departure.time">
                              </FlightTime>
                              <div class="pdp-num-cl-97 flex flex-auto align-items-center">
                                <div class="pdp-num-cl-98 col-12 px-4">
                                  <MobileStopDrawing>
                                  </MobileStopDrawing>
                                </div>
                              </div>
                              <FlightTime class="pdp-num-cl-99 end-time"
                                          :description="true"
                                          :airport="getAirport(leg.arrival.code)"
                                          :date="leg.arrival.date"
                                          :time="leg.arrival.time">
                              </FlightTime>
                            </div>
                            <div
                                 class="pdp-num-cl-100 col-3 flex px-0 align-items-center text-xs text-gray-650 justify-content-start xl:justify-content-center">
                              {{ leg.duration }}
                            </div>
                            <div
                                 class="pdp-num-cl-101 col-5 xl:col-2 px-2 xl:p-0 flex flex-wrap align-content-center justify-content-end flight-number">
                              <div class="pdp-num-cl-102 w-12 flex align-items-center justify-content-end">
                                <div class="pdp-num-cl-103 flex align-items-center">
                                  <img :src="getAirlineLogo(leg.marketingAirline)"
                                       class="pdp-num-cl-104 dialog-details-logo"
                                       alt="Airline logo">
                                </div>
                                <div
                                     class="pdp-num-cl-105 flight-code text-gray-650 text-xs pt-0 text-right pr-0 white-space-nowrap">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                              <div class="pdp-num-cl-106 w-12 line-height-1 text-xs text-right">
                                {{paymentStoreData.data.content.airlines[leg.marketingAirline].name.slice(0,18)}}{{(paymentStoreData.data.content.airlines[leg.marketingAirline].name.length
                                > 18 && '.') || ''}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-107 block bg-gray-400 h1p">
                     
                  </div>
                  <!-- RETURN -->
                  <div class="pdp-num-cl-108 return-legs">
                    <div v-if="paymentStoreData.data.content.flightDetails.inbound"
                         class="pdp-num-cl-109 return-header flex flex-wrap justify-content-between">
                      <div class="pdp-num-cl-110 col-12 px-6 flex">
                        <div class="pdp-num-cl-111 col-7 px-0 text-lg font-medium">
                          <span>{{ paymentStoreData.data.content.pageLabels.inbound }}
                          </span>
                        </div>
                        <div class="pdp-num-cl-112 col-5 xl:col-3 text-xs text-center">
                          <div class="pdp-num-cl-113 text-gray-650">
                            {{ paymentStoreData.data.content.pageLabels.flightDurationTotal }}
                          </div>
                          <div class="pdp-num-cl-114 font-medium">
                            {{ paymentStoreData.data.content.flightDetails.inbound.duration }}
                          </div>
                        </div>
                        <div class="pdp-num-cl-115 hidden xl:block col-2">
                          <!-- empty col just in case -->
                        </div>
                      </div>
                      <div class="pdp-num-cl-116 col-12 px-6 pt-0">
                        <div v-for="(leg, index) in paymentStoreData.data.content.flightDetails.inbound.segments">
                          <div v-if="index > 0"
                               class="pdp-num-cl-117 py-2">
                            <div>
                              <div class="pdp-num-cl-118 flex bg-gray-200 border-round">
                                <div class="pdp-num-cl-119 col-7 text-xs">
                                  {{ paymentStoreData.data.content.pageLabels.stopIn }} {{
                                  getAirport(leg.departure.code).city }} ({{ leg.departure.code }})
                                </div>
                                <div class="pdp-num-cl-120 col-3 text-xs text-center">
                                  {{ leg.stopDuration }}
                                </div>
                                <div class="pdp-num-cl-121 col-2">
                                  <!-- empty col just in case -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="pdp-num-cl-122 flex flex-wrap justify-content-between">
                            <div
                                 class="pdp-num-cl-123 col-12 xl:col-7 px-0 flex justify-content-between flight-times-and-connector">
                              <FlightTime class="pdp-num-cl-124 start-time"
                                          :description="true"
                                          :airport="getAirport(leg.departure.code)"
                                          :date="leg.departure.date"
                                          :time="leg.departure.time">
                              </FlightTime>
                              <div class="pdp-num-cl-125 flex flex-auto align-items-center">
                                <div class="pdp-num-cl-126 col-12 px-4">
                                  <MobileStopDrawing>
                                  </MobileStopDrawing>
                                </div>
                              </div>
                              <FlightTime class="pdp-num-cl-127 end-time"
                                          :description="true"
                                          :airport="getAirport(leg.arrival.code)"
                                          :date="leg.arrival.date"
                                          :time="leg.arrival.time">
                              </FlightTime>
                            </div>
                            <div
                                 class="pdp-num-cl-128 col-3 flex px-0 align-items-center text-xs text-gray-650 justify-content-start xl:justify-content-center">
                              {{ leg.duration }}
                            </div>
                            <div
                                 class="pdp-num-cl-129 col-5 xl:col-2 px-2 xl:p-0 flex flex-wrap align-content-center justify-content-end flight-number">
                              <div class="pdp-num-cl-130 w-12 flex align-items-center justify-content-end">
                                <div class="pdp-num-cl-131 flex align-items-center">
                                  <img :src="getAirlineLogo(leg.marketingAirline)"
                                       class="pdp-num-cl-132 dialog-details-logo"
                                       alt="Airline logo">
                                </div>
                                <div
                                     class="pdp-num-cl-133 flight-code text-gray-650 text-xs pt-0 text-right pr-0 white-space-nowrap">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                              <div class="pdp-num-cl-134 w-12 line-height-1 text-xs text-right">
                                {{paymentStoreData.data.content.airlines[leg.marketingAirline].name.slice(0,18)}}{{(paymentStoreData.data.content.airlines[leg.marketingAirline].name.length
                                > 18 && '.') || ''}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
            <Accordion class="pdp-num-cl-135 col-12 mt-3"
                       v-model:activeIndex="baggageAccordionActiveIndex">
              <AccordionTab :header="paymentStoreData.data.content.pageLabels.baggage">
                <div>
                  <div class="pdp-num-cl-136 p-3">
                    <div v-if="paymentStoreData.data.content.baggageAllowance">
                      <div v-if="paymentStoreData.data.content.baggageAllowance.outbound">
                        <div class="pdp-num-cl-137 text-lg">
                          {{paymentStoreData.data.content.pageLabels.outbound}}
                        </div>
                        <div v-for="leg in paymentStoreData.data.content.baggageAllowance.outbound"
                             class="pdp-num-cl-138 pt-2">
                          <div class="pdp-num-cl-139 flex">
                            <div class="pdp-num-cl-140 pr-1">
                              {{paymentStoreData.data.content.airports[leg.departure] .city}}
                            </div>
                            <div class="pdp-num-cl-141 text-gray-650 pl-1">
                              {{paymentStoreData.data.content.airports[leg.departure].code}}
                            </div>
                            <div class="pdp-num-cl-142 px-1">
                              {{' - '}}
                            </div>
                            <div class="pdp-num-cl-143 px-1">
                              {{paymentStoreData.data.content.airports[leg.arrival].city}}
                            </div>
                            <div class="pdp-num-cl-144 text-gray-650 pl-1">
                              {{paymentStoreData.data.content.airports[leg.arrival].code}}
                            </div>
                          </div>
                          <div class="pdp-num-cl-145 grid"
                               v-for="(ageGroup, key) in leg.travelers">
                            <div class="pdp-num-cl-146 col-2 px-0 flex align-items-end">
                              {{paymentStoreData.data.content.pageLabels[key]}}
                            </div>
                            <div class="pdp-num-cl-147 col-5 flex align-items-end">
                              <img :src="ageGroup.checked.image"
                                   class="pdp-num-cl-148 h-2rem" />
                              <div class="pdp-num-cl-149 pl-1 line-height-1">
                                {{paymentStoreData.data.content.pageLabels.baggageAllowance.checked[ageGroup.checked.id]}}
                              </div>
                            </div>
                            <div class="pdp-num-cl-150 col-5 flex align-items-end">
                              <img :src="ageGroup.carryOn.image"
                                   class="pdp-num-cl-151 h-1rem" />
                              <div class="pdp-num-cl-152 pl-1 line-height-1">
                                {{paymentStoreData.data.content.pageLabels.baggageAllowance.carryOn[ageGroup.carryOn.id]}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="paymentStoreData.data.content.baggageAllowance.inbound">
                        <div class="pdp-num-cl-153 text-lg pt-3">
                          {{paymentStoreData.data.content.pageLabels.inbound}}
                        </div>
                        <div v-for="leg in paymentStoreData.data.content.baggageAllowance.inbound"
                             class="pdp-num-cl-154 pt-2">
                          <div class="pdp-num-cl-155 flex">
                            <div class="pdp-num-cl-156 pr-1">
                              {{paymentStoreData.data.content.airports[leg.departure].city}}
                            </div>
                            <div class="pdp-num-cl-157 text-gray-650 pl-1">
                              {{paymentStoreData.data.content.airports[leg.departure].code}}
                            </div>
                            <div class="pdp-num-cl-158 px-1">
                              {{' - '}}
                            </div>
                            <div class="pdp-num-cl-159 px-1">
                              {{paymentStoreData.data.content.airports[leg.arrival].city}}
                            </div>
                            <div class="pdp-num-cl-160 text-gray-650 pl-1">
                              {{paymentStoreData.data.content.airports[leg.arrival].code}}
                            </div>
                          </div>
                          <div class="pdp-num-cl-161 grid"
                               v-for="(ageGroup, key) in leg.travelers">
                            <div class="pdp-num-cl-162 col-2 px-0 flex align-items-end">
                              {{paymentStoreData.data.content.pageLabels[key]}}
                            </div>
                            <div class="pdp-num-cl-163 col-5 flex align-items-end">
                              <img :src="ageGroup.checked.image"
                                   class="pdp-num-cl-164 h-2rem" />
                              <div class="pdp-num-cl-165 pl-1 line-height-1">
                                {{paymentStoreData.data.content.pageLabels.baggageAllowance.checked[ageGroup.checked.id]}}
                              </div>
                            </div>
                            <div class="pdp-num-cl-166 col-5 flex align-items-end">
                              <img :src="ageGroup.carryOn.image"
                                   class="pdp-num-cl-167 h-1rem" />
                              <div class="pdp-num-cl-168 pl-1 line-height-1">
                                {{paymentStoreData.data.content.pageLabels.baggageAllowance.carryOn[ageGroup.carryOn.id]}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
            <Card class="pdp-num-cl-169 col-12 border-1 border-gray-300 mt-3">
              <template #header>
                <div class="pdp-num-cl-170 grid">
                  <div class="pdp-num-cl-171 col-12 text-lg p-3">
                    {{ paymentStoreData.data.content.pageLabels.form.payment.headline || '$paymentMethod'}}
                  </div>
                </div>
              </template>
              <template #content>
                <TabView class="pdp-num-cl-172 -mt-5"
                         v-model:activeIndex="paymentMethodActiveIndex"
                         @tab-change="onTabChange">
                         >
                  <TabPanel v-for="(paymentType, index) in paymentStoreData.data.content.form.payment.types"
                  :header="paymentStoreData.data.content.pageLabels.form.payment[paymentType].title"> 
                    <div v-if="paymentType == 'inv'"
                              >
                      <div style="font-size: 14px;">
                        {{paymentStoreData.data.content.pageLabels.form.payment.inv.headline}}
                      </div>
                      <div class="pdp-num-cl-209 pt-3"
                           style="font-size: 13px;">
                        {{paymentStoreData.data.content.pageLabels.form.payment.inv.subtitle}}
                      </div>
                    </div>
                    <div v-if="paymentType == 'cc'"
                              >
                      <div class="pdp-num-cl-173 grid justify-content-center">
                        <div class="pdp-num-cl-174 col-12 xl:col-8 card-interact -mb-5">
                          <div class="pdp-num-cl-175 grid">
                            <div class="pdp-num-cl-176 col-12 credit-cards">
                              <div>
                                {{paymentStoreData.data.content.pageLabels.form.payment.cc.title}}
                              </div>
                              <div class="pdp-num-cl-177 flex">
                                <div v-for="(creditCard, key) in paymentStoreData.data.content.form.payment.cc.image">
                                  <img :src="creditCard"
                                       :alt="creditCard"
                                       srcset=""
                                       class="pdp-num-cl-178 mr-2"
                                       :class="{'disabled-cc': checkCcKey(key)}">
                                </div>
                              </div>
                              <div>
                                {{paymentStoreData.data.content.pageLabels.form.payment.cc.cardHolder}}
                              </div>
                              <input name="cc-name"
                                     type="text"
                                     :class="['p-inputtext p-component', { 'p-filled': false }]"
                                     @input="regexifyCardholder(paymentStoreData.data.content.form.payment.cc.fields.cardHolder.allowedCharacterSet, 'cardHolder', $event)"
                                     class="pdp-num-cl-181 w-12"
                                     v-model="cc.cardHolder" />
                              <div :class="{'block': cc.cardHolder == '' && checkInvalid}"
                                   v-if="cc.cardHolder == '' && checkInvalid"
                                   class="pdp-num-cl-183 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.required }}
                              </div>
                              <div :class="{'block': cc.cardHolder != '' && !checkAgainstRegex(cc.cardHolder, paymentStoreData.data.content.form.payment.cc.fields.cardHolder.regex) && checkInvalid}"
                                   v-if="cc.cardHolder != '' && !checkAgainstRegex(cc.cardHolder, paymentStoreData.data.content.form.payment.cc.fields.cardHolder.regex) && checkInvalid"
                                   class="pdp-num-cl-185 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.cardHolder }}
                              </div>
                              <div class="pdp-num-cl-186 w-12 my-2 pt-0 xl:pt-2">
                                <div class="pdp-num-cl-187 flex flex-wrap">
                                  <div class="pdp-num-cl-188 col-12 xl:col-6 p-0 xl:pr-2">
                                    <div>
                                      {{paymentStoreData.data.content.pageLabels.form.payment.cc.number}}
                                    </div>
                                    <input name="cc-number"
                                           type="text"
                                           :class="['p-inputtext p-component', { 'p-filled': false }]"
                                           class="pdp-num-cl-190 w-12"
                                           v-model="cc.number"
                                           @input.stop="addSpacesToCc"
                                           :maxLength="cardLength" />
                                    <div :class="{'block': cc.number.length < cardLength && checkInvalid}"
                                         v-if="cc.number.length < cardLength && checkInvalid"
                                         class="pdp-num-cl-192 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                      {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.required }}
                                    </div>
                                    <div :class="{'block': !isCcLuhnCompliant() }"
                                         class="pdp-num-cl-194 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                      {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.cardNumber }}
                                    </div>
                                    <div :class="{'block': cc.number != '' && !checkAgainstRegex(cc.number, paymentStoreData.data.content.form.payment.cc.fields.number.regex) && checkInvalid}"
                                         v-if="cc.number != '' && !checkAgainstRegex(cc.number, paymentStoreData.data.content.form.payment.cc.fields.number.regex) && checkInvalid"
                                         class="pdp-num-cl-196 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                      {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.cardNumber }}
                                    </div>
                                  </div>
                                  <div class="pdp-num-cl-197 col-12 xl:col-4 p-0 xl:pr-2">
                                    <div>
                                      {{paymentStoreData.data.content.pageLabels.form.payment.cc.expireDate}}
                                    </div>
                                    <input name="cc-exp"
                                           type="text"
                                           :class="['p-inputtext p-component', { 'p-filled': false }]"
                                           class="pdp-num-cl-199 w-12"
                                           v-model="cc.expireDate"
                                           @input.stop="validateExpiryDate"
                                           placeholder="MM/YY"
                                           maxLength="5" />
                                    <div :class="{'block': (cc.expireDate == '' || cc.expireDate.length < 5) && checkInvalid}"
                                         v-if="cc.expireDate == '' && checkInvalid"
                                         class="pdp-num-cl-201 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                      {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.required}}
                                    </div>
                                    <div :class="{'block': cc.expireDate != '' && !isValidExpireDate(cc.expireDate) && checkInvalid}"
                                         v-if="cc.expireDate != '' && !isValidExpireDate(cc.expireDate) && checkInvalid"
                                         class="pdp-num-cl-203 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                      {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.expireDate }}
                                    </div>
                                  </div>
                                  <div class="pdp-num-cl-204 col-12 xl:col-2 p-0">
                                    <div>
                                      {{ paymentStoreData.data.content.pageLabels.form.payment.cc.cvv }}
                                    </div>
                                    <input name="cc-csc"
                                           type="text"
                                           :class="['p-inputtext p-component', { 'p-filled': false }]"
                                           class="pdp-num-cl-206 w-12"
                                           @input="fixCvv"
                                           v-model="cc.cvv"
                                           :maxLength="cvvLength" />
                                    <div :class="{'block': cc.cvv.length < cvvLength && checkInvalid}"
                                         v-if="cc.cvv.length < cvvLength && checkInvalid"
                                         class="pdp-num-cl-208 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                      {{ paymentStoreData.data.content.pageLabels.form.payment.regexError.cvv }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div v-if="paymentStoreData.data.content.sidebar.priceInfo.totalPrice.swb" -->
                    <div v-if="paymentType == 'swb'"
                              >
                      <div>
                        <img :src="paymentStoreData.data.content.pageLabels.form.payment.swb.image"
                             alt="SWB"
                             style="width: 150px;">
                      </div>
                      <div class="pdp-num-cl-210 my-4">
                        {{paymentStoreData.data.content.pageLabels.form.payment.swb.text1}}
                      </div>
                      <div class="pdp-num-cl-211 text-blue-400 font-bold">
                        {{paymentStoreData.data.content.pageLabels.form.payment.swb.subtitle}}
                      </div>
                      <div>
                        {{paymentStoreData.data.content.pageLabels.form.payment.swb.text2}}
                      </div>
                      <div class="pdp-num-cl-212 mt-4">
                        <a :href="paymentStoreData.data.content.pageLabels.form.payment.swb.link.url">
                          {{paymentStoreData.data.content.pageLabels.form.payment.swb.link.label}}
                        </a>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </template>
            </Card>
            <Card class="pdp-num-cl-213 col-12 mt-3 border-1 border-gray-300 total-price-and-button">
              <template #content>
                <div class="pdp-num-cl-214 checkboxes-and-button">
                  <div>
                    <div class="pdp-num-cl-215 col-12 flex pl-0 pt-0 pb-3">
                      <Checkbox v-model="consentPassport"
                                :binary="true">
                      </Checkbox>
                      <span :class="{'textus-redus': !consentPassport && checkInvalid}"
                            class="pdp-num-cl-217 text-xs pl-1">
                        {{paymentStoreData.data.content.pageLabels.form.consents.consentPassport}}
                      </span>
                    </div>
                    <div class="pdp-num-cl-218 col-12 flex pl-0 pt-0 pb-3">
                      <Checkbox v-model="consentVisa"
                                :binary="true">
                      </Checkbox>
                      <span :class="{'textus-redus': !consentVisa && checkInvalid}"
                            class="pdp-num-cl-220 text-xs pl-1">
                        {{paymentStoreData.data.content.pageLabels.form.consents.consentVisa}}
                      </span>
                    </div>
                    <div class="pdp-num-cl-221 col-12 flex pl-0 pt-0 pb-0">
                      <Checkbox v-model="consentTc"
                                :binary="true">
                      </Checkbox>
                      <span :class="{'textus-redus': !consentTc && checkInvalid}"
                            class="pdp-num-cl-223 text-xs pl-1">
                        {{paymentStoreData.data.content.pageLabels.form.consents.consentTc}}
                      </span>
                    </div>
                  </div>
                  <div class="pdp-num-cl-224 flex flex-wrap align-items-center justify-content-end">
                    <div>
                      <div class="pdp-num-cl-225 col-12 text-lg font-semibold p-0 text-left">
                        <FormattedCurrency :currency="paymentStoreData.data.content.sidebar.priceInfo.totalPrice[paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex]]?.currency"
                                           :currencyPosition="paymentStoreData.data.content.sidebar.priceInfo.totalPrice[paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex]]?.currencyPosition"
                                           :amount="paymentStoreData.data.content.sidebar.priceInfo.totalPrice[paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex]]?.amount" />
                      </div>
                      <div class="pdp-num-cl-226 col-12 text-right p-0">
                        <Button @click="submitPayment"
                                class="pdp-num-cl-227 text-center"
                                style="min-width: 165px">
                          <div class="pdp-num-cl-228 col-12 py-0 text-white">
                            <span class="pdp-num-cl-229 text-white">{{
                              paymentStoreData.data.content.pageLabels.form.button }}</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
        <div class="pdp-num-cl-230 col-12 xl:col-3 hidden xl:block"
             :class="{'relative': !isSidebarFixed}">
          <Card id="side-card"
                class="pdp-num-cl-232 side-card border-1 border-gray-300"
                :class="{'fixed top-0 mt-2': isSidebarFixed, 'absolute bottom-0 mb-2': isSidebarDockedToBottom}">
            <template #header>
              <div class="pdp-num-cl-234 p-3 text-base line-height-1 border-bottom-1 border-gray-300">
                {{ paymentStoreData.data.content.pageLabels.sidebar.headline }}
              </div>
            </template>
            <template #content>
              <div class="pdp-num-cl-235 p-0 pb-2">
                <div class="pdp-num-cl-236 reservation-sidebar-outbound">
                  <div class="pdp-num-cl-237 flex align-items-center ">
                    <img :src="airplaneIcon"
                         class="pdp-num-cl-238 autocomplete-icon">
                    <div>
                      {{ paymentStoreData.data.content.pageLabels.outbound }}
                    </div>
                  </div>
                  <div class="pdp-num-cl-239 ">
                    <FlightTime class="pdp-num-cl-240 start-time"
                                :airport="paymentStoreData.data.content.sidebar.flight.outbound.departure.code"
                                :date="paymentStoreData.data.content.sidebar.flight.outbound.departure.date"
                                :time="paymentStoreData.data.content.sidebar.flight.outbound.departure.time">
                    </FlightTime>
                  </div>
                  <div v-if="paymentStoreData.data.content.sidebar.flight.outbound.stops?.length > 0">
                    <div class="pdp-num-cl-241 my-1">
                      <div class="pdp-num-cl-242 flex align-items-center">
                        <div class="pdp-num-cl-243 col-12 py-1 px-0 text-xs text-gray-400 font-light">
                          {{ paymentStoreData.data.content.pageLabels.stopIn }} <span
                                class="pdp-num-cl-244 text-gray-400 font-light"
                                v-for="code in paymentStoreData.data.content.sidebar.flight.outbound.stops">
                            {{ code
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-245 ">
                    <FlightTime class="pdp-num-cl-246 start-time"
                                :airport="paymentStoreData.data.content.sidebar.flight.outbound.arrival.code"
                                :date="paymentStoreData.data.content.sidebar.flight.outbound.arrival.date"
                                :time="paymentStoreData.data.content.sidebar.flight.outbound.arrival.time">
                    </FlightTime>
                  </div>
                </div>
                <div v-if="paymentStoreData.data.content.sidebar.flight.inbound"
                     class="pdp-num-cl-247 reservation-sidebar-inbound pt-3">
                  <div class="pdp-num-cl-248 flex align-items-center ">
                    <img :src="airplaneIcon"
                         class="pdp-num-cl-249 autocomplete-icon">
                    <div>
                      {{ paymentStoreData.data.content.pageLabels.inbound }}
                    </div>
                  </div>
                  <div class="pdp-num-cl-250 ">
                    <FlightTime class="pdp-num-cl-251 start-time"
                                :airport="paymentStoreData.data.content.sidebar.flight.inbound.departure.code"
                                :date="paymentStoreData.data.content.sidebar.flight.inbound.departure.date"
                                :time="paymentStoreData.data.content.sidebar.flight.inbound.departure.time">
                    </FlightTime>
                  </div>
                  <div v-if="paymentStoreData.data.content.sidebar.flight.inbound.stops?.length > 0">
                    <div class="pdp-num-cl-252 my-1">
                      <div class="pdp-num-cl-253 flex align-items-center">
                        <div class="pdp-num-cl-254 col-12 py-1 px-0 text-xs text-gray-400 font-light">
                          {{ paymentStoreData.data.content.pageLabels.stopIn }} <span
                                class="pdp-num-cl-255 text-gray-400 font-light"
                                v-for="code in paymentStoreData.data.content.sidebar.flight.inbound.stops">
                            {{ code
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-256 ">
                    <FlightTime class="pdp-num-cl-257 start-time"
                                :airport="paymentStoreData.data.content.sidebar.flight.inbound.arrival.code"
                                :date="paymentStoreData.data.content.sidebar.flight.inbound.arrival.date"
                                :time="paymentStoreData.data.content.sidebar.flight.inbound.arrival.time">
                    </FlightTime>
                  </div>
                </div>
              </div>
              <div v-if="paymentStoreData.data.content.sidebar.priceClasses"
                   class="pdp-num-cl-258 pb-2">
                <div class="pdp-num-cl-259 bg-gray-400 h1p">
                   
                </div>
                <div class="pdp-num-cl-260 font-sm pt-2"
                     v-if="!paymentStoreData.data.content.sidebar.priceClasses.display.includes('none')">
                  {{ paymentStoreData.data.content.pageLabels.sidebar.priceClasses }}
                </div>
                <div v-if="paymentStoreData.data.content.sidebar.priceClasses 
                     && (
                           paymentStoreData.data.content.sidebar.priceClasses.display.includes('outbound') 
                           || paymentStoreData.data.content.sidebar.priceClasses.display.includes('joined') 
                           || paymentStoreData.data.content.sidebar.priceClasses.display.includes('separated')
                     )">
                  <div class="pdp-num-cl-261 font-sm pt-1">
                    <div class="w-12">
                      <div v-if="paymentStoreData.data.content.sidebar.priceClasses.display.includes('outbound')">{{
                        paymentStoreData.data.content.pageLabels.priceClasses.display.outbound }}</div>
                      <div v-if="paymentStoreData.data.content.sidebar.priceClasses.display.includes('separated')">{{
                        paymentStoreData.data.content.pageLabels.priceClasses.display.outbound }}</div>
                      <div v-if="paymentStoreData.data.content.sidebar.priceClasses.display.includes('joined')">{{
                        paymentStoreData.data.content.pageLabels.priceClasses.display.joined }}</div>
                      <div class="font-semibold pb-2 pt-1">
                        {{ paymentStoreData.data.content.sidebar.priceClasses.outbound &&
                        paymentStoreData.data.content.sidebar.priceClasses.outbound.name }}
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-263 pb-2">
                    <div class="pdp-num-cl-264 w-12 p-0"
                         v-for="perk in paymentStoreData.data.content.sidebar.priceClasses.outbound.items">
                      <div class="pdp-num-cl-265 flex align-items-center h-2rem -my-1">
                        <div class="pdp-num-cl-266 flex align-items-center justify-content-center pr-2">
                          <img :src="perk.image"
                               class="pdp-num-cl-267 perks-image" />
                        </div>
                        <div class="pdp-num-cl-268 font-medium text-xs">
                          <span>
                            {{ perk.label }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="paymentStoreData.data.content.sidebar.priceClasses 
                     && (
                           paymentStoreData.data.content.sidebar.priceClasses.display.includes('inbound') 
                           || paymentStoreData.data.content.sidebar.priceClasses.display.includes('separated')
                     )">
                  <div class="pdp-num-cl-261 font-sm pt-1">
                    <div class="w-12">
                      <div v-if="paymentStoreData.data.content.sidebar.priceClasses.display.includes('inbound')">{{
                        paymentStoreData.data.content.pageLabels.priceClasses.display.inbound }}</div>
                      <div v-if="paymentStoreData.data.content.sidebar.priceClasses.display.includes('separated')">{{
                        paymentStoreData.data.content.pageLabels.priceClasses.display.inbound }}</div>
                      <div class="font-semibold pb-2 pt-1">
                        {{ paymentStoreData.data.content.sidebar.priceClasses.inbound &&
                        paymentStoreData.data.content.sidebar.priceClasses.inbound.name }}
                      </div>
                    </div>
                  </div>
                  <div class="pdp-num-cl-271 w-12 p-0"
                       v-for="perk in paymentStoreData.data.content.sidebar.priceClasses.inbound.items">
                    <div class="pdp-num-cl-272 flex align-items-center h-2rem -my-1">
                      <div class="pdp-num-cl-273 flex align-items-center justify-content-center pr-2">
                        <img :src="perk.image"
                             class="pdp-num-cl-274 perks-image" />
                      </div>
                      <div class="pdp-num-cl-275 font-medium text-xs">
                        <span>
                          {{ perk.label }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="paymentStoreData.data.content.fareRules">
                <Button class="bp-num-cl-457 bg-transparent bodrer-0 text-blue-400 p-0 m-0 pb-2"
                        @click="toggleFareRulesDialog">
                  {{ paymentStoreData.data.content.pageLabels.fareRules }}
                </Button>
                <Dialog :header="paymentStoreData.data.content.pageLabels.sidebar.fareRules"
                        v-model:visible="fareRulesDialogVisible"
                        class="bp-num-cl-458 fare-rules-modal shadow-5"
                        :modal="true">
                  <div>
                    <div v-html="paymentStoreData.data.content.fareRules.replaceAll('courier', '')">
                    </div>
                  </div>
                </Dialog>
              </div>
              <!-- <div class="pdp-num-cl-276 bg-gray-400 h1p" v-if="!paymentStoreData.data.content.sidebar.priceClasses.display.includes('none')">  -->
              <div class="pdp-num-cl-276 bg-gray-400 h1p">
                 
              </div>
              <div class="pdp-num-cl-277 pb-2"
                   :class="{'pt-2':!paymentStoreData.data.content.sidebar.priceClasses.display.includes('none')}">
                {{ paymentStoreData.data.content.pageLabels.travelers }}
              </div>
              <div v-for="(ageGroup, index) in paymentStoreData.data.content.sidebar.priceInfo.travelers">
                <div class="pdp-num-cl-278 grid pb-2">
                  <div class="pdp-num-cl-279 w-12">
                    {{ paymentStoreData.data.content.pageLabels.sidebar[ageGroup.type] }}
                  </div>
                  <div class="pdp-num-cl-280 w-6">
                    {{ ageGroup.quantity }}
                    x
                    <FormattedCurrency :amount="ageGroup.amount"
                                       :currency="ageGroup.currency"
                                       :currencyPosition="ageGroup.currencyPosition" />
                  </div>
                  <div class="pdp-num-cl-281 w-6 text-right">
                    <FormattedCurrency :amount="ageGroup.total"
                                       :currency="ageGroup.currency"
                                       :currencyPosition="ageGroup.currencyPosition" />
                  </div>
                </div>
                <div class="pdp-num-cl-282 flex flex-wrap"
                     v-if="index == paymentStoreData.data.content.sidebar.priceInfo.travelers.length - 1">
                  <div class="pdp-num-cl-283 w-12 flex justify-content-between mb-2">
                    <div v-if="paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex] == 'swb'">
                      {{paymentStoreData.data.content.pageLabels.sidebar.serviceFee}}
                    </div>
                    <div v-if="paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex] == 'swb'">
                      <FormattedCurrency class="pdp-num-cl-284 font-normal"
                                         :amount="paymentStoreData.data.content.sidebar.priceInfo.totalPrice.swb.serviceFee"
                                         :currency="ageGroup.currency"
                                         :currencyPosition="ageGroup.currencyPosition" />
                    </div>
                  </div>
                  <div class="pdp-num-cl-285 w-12 flex justify-content-between">
                    <div class="pdp-num-cl-286 font-bold">
                      {{ paymentStoreData.data.content.pageLabels.sidebar.totalPrice }}
                    </div>
                    <FormattedCurrency :amount="paymentStoreData.data.content.sidebar.priceInfo.totalPrice[paymentStoreData.data.content.form.payment.types[paymentMethodActiveIndex]]?.amount"
                                       :currency="ageGroup.currency"
                                       :currencyPosition="ageGroup.currencyPosition" />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <!-- EXPIRED DIALOG -->
  <Dialog :closeOnEscape="false"
          :header="paymentStoreData.data.content.pageLabels.timer.dialog.headline"
          v-model:visible="displayExpiredFlightModal"
          class="pdp-num-cl-287 expired-modal shadow-5"
          :modal="true">
    <p class="pdp-num-cl-288 m-0">
      {{ paymentStoreData.data.content.pageLabels.timer.dialog.subtitle }}
    </p>
    <template #footer
              class="pdp-num-cl-289 pb-1">
      <div class="pdp-num-cl-290 flex flex-wrap justify-content-center">
      </div>
      <div class="pdp-num-cl-291 col-12 text-center">
        <Button @click="retrySearch"
                autofocus>
          <div class="pdp-num-cl-292 text-white px-3">
            {{ paymentStoreData.data.content.pageLabels.timer.dialog.buttons.yes }}
          </div>
        </Button>
      </div>
      <div class="pdp-num-cl-293 col-12 text-center">
        <Button @click="goHome"
                class="pdp-num-cl-294 p-button-text py-0">
          <div class="pdp-num-cl-295 px-2 py-0">
            {{ paymentStoreData.data.content.pageLabels.timer.dialog.buttons.no }}
          </div>
        </Button>
      </div>
    </template>
  </Dialog>

  <!-- PRICE CHANGE DIALOG -->
  <Dialog :header="paymentStoreData.data.content.pageLabels.priceChanged.dialog.headline[paymentStoreData.data.content.priceChange.trend]"
          v-model:visible="priceChangeDialogOpened"
          class="bp-num-cl-477 expired-modal shadow-5"
          :modal="true">
    <div>
      <div class="bp-num-cl-478 m-0 pb-2 text-center flex w-12 justify-content-center">
        {{ paymentStoreData.data.content.pageLabels.priceChanged.dialog.old }}
        <div class="bp-num-cl-479 font-semibold">
          {{ ' ' }}
          <FormattedCurrency :currency="paymentStoreData.data.content.priceChange.price.currency"
                             :amount="`${paymentStoreData.data.content.priceChange.price.old}`"
                             :currencyPosition="paymentStoreData.data.content.priceChange.price.currencyPosition" />
        </div>
      </div>
      <div class="bp-num-cl-480 pt-2 -mb-3 text-center flex w-12 justify-content-center">
        {{ paymentStoreData.data.content.pageLabels.priceChanged.dialog.new }}
        <div class="bp-num-cl-481 font-semibold">
          {{ ' ' }}
          <FormattedCurrency :currency="paymentStoreData.data.content.priceChange.price.currency"
                             :amount="`${paymentStoreData.data.content.priceChange.price.new}`"
                             :currencyPosition="paymentStoreData.data.content.priceChange.price.currencyPosition" />
          {{ ' ' }}
        </div>
        <div class="bp-num-cl-482 triangle"
             :class="{
                up: paymentStoreData.data.content.priceChange.trend != 'cheaper',
                down: paymentStoreData.data.content.priceChange.trend == 'cheaper' 
        }">
          {{ '▲' }}
        </div>
      </div>
    </div>
    <template #footer
              class="bp-num-cl-483 pb-1">
      <div class="bp-num-cl-484 flex flex-wrap justify-content-center">
      </div>
      <div class="bp-num-cl-485 col-12 text-center">
        <Button @click="() => { priceChangeDialogOpened = false; }"
                autofocus>
          <div class="bp-num-cl-486 text-white px-3">
            {{ paymentStoreData.data.content.pageLabels.priceChanged.buttons.yes }}
          </div>
        </Button>
      </div>
      <div class="bp-num-cl-487 col-12 text-center">
        <Button @click="retrySearch"
                class="bp-num-cl-488 p-button-text py-0">
          <div class="bp-num-cl-489 px-2 py-0">
            {{ paymentStoreData.data.content.pageLabels.priceChanged.buttons.no }}
          </div>
        </Button>
      </div>
    </template>
  </Dialog>

</section>