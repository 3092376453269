import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/home/HomeView.vue';
import Contact from '@/views/ContactPage/ContactPageView.vue';
import FlightResults from '@/views/FlightResults/FlightResultsView.vue';
import About from '@/views/About/AboutView.vue';
import Book from '@/views/BookPage/BookPageView.vue';
import Pay from '@/views/PaymentDetailsPage/PaymentDetailsPageView.vue';
import PnrPage from '@/views/PnrPage/PnrPageView.vue';
import FiddleVue from '@/views/FiddleView.vue';
import NotFoundPage from '@/views/NotFoundView.vue';

const routes = [
  {
    path: '/',
    name: 'RootHome',
    component: Home,
    props: true
  },
  {
    path: '/:language',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/:language/info/:about',
    name: 'About',
    component: About,
    props: true
  },
  {
    path: '/:language/contact',
    name: 'Contact',
    component: Contact,
    props: true
  },
  {
    path: '/:language/flight/search/:searchSessionlessParamsEncoded',
    name: 'FlightResults',
    component: FlightResults,
    props: true
  },
  {
    path: '/:language/flight/book',
    name: 'Book',
    component: Book,
    props: true
  },
  {
    path: '/:language/flight/payment',
    name: 'Confirm',
    component: Pay,
    props: true
  },
  {
    path: '/fiddle',
    name: 'Fiddle',
    component: FiddleVue,
    props: true
  },
  {
    path: '/:language/flight/create-pnr',
    name: 'PnrPage',
    component: PnrPage,
    props: true
  },
  {
    path: '/:language/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/:language'
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundPage
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
