<section class="stop-drawing">
  <div class="lower flex align-items-center">
    <div class="start-dot"></div>
    <div class="through-line -mx-1"></div>
    <div class="end-dot"></div>
  </div>
  <div class="upper">
    <div class="onestop" v-if="stops == 1">
      <div class="dot-wrapper flex justify-content-center align-items-center">
        <div class="blue-dot"></div>
      </div>
    </div>
    <div class="twostops flex justify-content-between align-items-center mx-1" v-if="stops > 1">
      <div class="dot-wrapper flex justify-content-center align-items-center">
        <div class="blue-dot"></div>
      </div>
      <div class="dot-wrapper flex justify-content-center align-items-center">
        <div class="blue-dot"></div>
      </div>
      <div class="dot-wrapper flex justify-content-center align-items-center">
        <div class="blue-dot"></div>
      </div>
      <div class="dot-wrapper flex justify-content-center align-items-center">
        <div class="blue-dot"></div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="stop-drawing">
  <div class="flex">
    <div class="lower">
      <div class="start-dot"></div>
      <div class="through-line"></div>
      <div class="end-dot"></div>
    </div>
  </div>
  <div class="upper">
    <div class="onestop" v-if="stops == 1">
      <div class="only-stop flex justify-content-center align-items-center">
        <div class="white-dot"></div>
        <div class="blue-dot"></div>
      </div>
    </div>
    <div class="twostops" v-if="stops > 1">
      <div class="first-stop flex justify-content-center align-items-center">
        <div class="white-dot"></div>
        <div class="blue-dot"></div>
      </div>
      <div class="second-stop flex justify-content-center align-items-center">
        <div class="white-dot"></div>
        <div class="blue-dot"></div>
      </div>
    </div>
  </div>
</section> -->