<template>
  <div class="date-text-input">
    <InputText class="w-12" id="name" v-model="passengerData['ADT'].firstName"
      @input.prevent="regexify('qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM', 'ADT', 'firstName', $event)" />
      <div style="border: 1px solid red;">{{ passengerData.ADT.firstName }}</div>
      <br>
      <br>
      <input type="text" v-model="x" />
      <div style="border: 1px solid red;">{{ x }}</div>
  </div>
</template>
<script setup>
/* eslint-disable */
import { ref } from '@vue/reactivity';
const props = defineProps({
  minDate: String,
  maxDate: String,
  modelValue: String
})

const passengerData = ref({ ADT: { firstName: 'Milan' } });

const regexify = (charset, passenger, field, event) => {
      const regex = new RegExp(`[^${charset}]+`);
      (regex);
      passengerData.value[passenger][field] = passengerData.value[passenger][field].replace(regex, '');
    };

const x = ref('Milos')

/* eslint-enable */
</script>
<style lang="scss" scoped>

</style>
