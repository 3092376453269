import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'About',
  components: {},
  props: ['language', 'about'],
  setup (props) {
    const store    = useStore();
    const language = props.language;
    const body     = ref('');
    const headline = ref('');
    const router   = useRouter();

    const aboutData = fetch(`https://${store.getters.mainData.config.baseUrl}${router.currentRoute.value.fullPath}`)
      .then(res => res.json())
      .then(d => {
        body.value = d.data.content.body;
        headline.value = d.data.content.headline;
        return Promise.resolve(d.data);
      })
      .catch(err => {
        console.error(err);
        return [];
      });

    return {
      body,
      language,
      aboutData,
      headline
    };
  }
};
