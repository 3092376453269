<section class="contact-page" v-if="!isLoading">
  <Header />
  <div class="flex flex-wrap">
    <div class="col-12">
      <div class="flex justify-content-center">
        <div class="col-12 xl:col-8 xl:mt-5 xl:pt-5 p-0 bg-white dynwid">
          <h2 class="mb-0">Kontaktirajte nas</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 p-0 xl:pt-2">
    <div class="flex justify-content-center detail-container xl:pt-5 pb-5">
      <div class="pr-0 pl-0 pt-0 bg-white detail-card">
        <div v-if="contactData.content.map">
          <div class="col-12 p-0">
            <iframe :src="contactData.content.map.google.src" frameborder="0" class="gmap"></iframe>
          </div>
        </div>
        <div class="flex justify-content-center">
          <div class="col-12 xl:col-11">
            <div class="flex flex-wrap justify-content-between">
              <div class="col-12 lg:col-6 details">
                <div class="grid">
                  <div class="col-12">
                    <div class="flex align-content-start address">
                      <div class="mr-1">
                        <img :src="contactData.content.info.address.icon.url" alt="" class="w1e pt-1">
                      </div>
                      <div class="list pl-2">
                        <h4 class="m-0">{{ contactData.content.info.address.label }}</h4>
                        <div v-for="text in contactData.content.info.address.text">{{ text }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="flex align-content-start phone">
                      <div class="mr-1">
                        <img :src="contactData.content.info.phone.icon.url" alt="" class="w1e pt-1">
                      </div>
                      <div class="list pl-2">
                        <h4 class="m-0">{{ contactData.content.info.phone.headline }}</h4>
                        <a class="phone-number no-underline" :href="'tel:' + contactData.content.info.phone.text">
                          <strong v-for="text in contactData.content.info.phone.text" class="phone-number">{{ text
                            }}</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="grid work-hours">
                      <div class="mr-1">
                        <img :src="contactData.content.info.workingHours.icon.url" alt="" class="w1e pt-1">
                      </div>
                      <div class="list col-11 xl:col-11 pt-0">
                        <h4 class="m-0">{{ contactData.content.info.workingHours.headline }}</h4>
                        <div v-if="contactData.content.info.workingHours.callCenter">
                          <h5 class="mt-2 mb-0">{{ contactData.content.info.workingHours.callCenter.subtitle }}</h5>
                          <div v-for="text in contactData.content.info.workingHours.callCenter.text" class="grid">
                            <div class="col-12 p-0">{{ text }}</div>
                          </div>
                        </div>

                        <div v-if="contactData.content.info.workingHours.office">
                          <h5 class="mt-2 mb-0">{{ contactData.content.info.workingHours.office.subtitle }}</h5>
                          <div v-for="text in contactData.content.info.workingHours.office.text" class="grid">
                            <div class="col-12 p-0">{{ text }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 lg:col-6 email-form">
                <!-- contactData.content.form.fields -->
                <div class="flex justify-content-center">
                  <div v-if="v$ && v$.value.name" class="col-12 px-0 lg:px-2">
                    <form @submit.prevent="handleSubmit(!v$.value.$invalid)" class="p-fluid">
                      <div class="field">
                        <div class="list p-float-label">
                          <div class="mt-1">{{ contactData.content.pageLabels.form.name }}</div>
                          <InputText id="name" v-model="v$.value.name.$model" />
                        </div>
                        <small v-if="v$.value.name.$invalid && submitted" class="absolute p-error">
                          <span v-for="(error, index) of v$.value.name.$silentErrors" :key="index">
                            <small class="p-error text-xs">{{ error.$message }}</small>
                          </span></small>
                      </div>
                      <div class="field">
                        <div class="list p-float-label">
                          <div class="mt-5">{{ contactData.content.pageLabels.form.email }}</div>
                          <InputText id="email" v-model="v$.value.email.$model" aria-describedby="email-error" />
                        </div>
                        <div class="absolute" v-if="v$.value.email.$invalid && submitted">
                          <span v-for="(error, index) of v$.value.email.$silentErrors" :key="index">
                            <small class="p-error text-xs">{{ error.$message }}</small>
                          </span>
                        </div>
                      </div>
                      <div class="field">
                        <div class="list p-float-label">
                          <div class="mt-5">{{ contactData.content.pageLabels.form.message }}</div>
                          <TextArea id="message" v-model="v$.value.message.$model" :autoResize="true" aria-describedby="message-error" class="-mb-2" />
                        </div>
                        <span v-if="v$.value.message.$error && submitted">
                          <span id="message-error" v-for="(error, index) of v$.value.message.$silentErrors" :key="index">
                            <small class="absolute p-error">{{ error.$message }}</small>
                          </span>
                        </span>
                        <small v-else-if="(v$.value.message.$invalid && submitted) || v$.value.message.$pending.$response" class="absolute p-error">{{ v$.value.message.required.$message.replace('Value is required', contactData.content.form.fields.email.regexError) }}</small>
                      </div>
                      <Button type="submit" class="mt-3 submit-email">{{ contactData.content.pageLabels.form.button }}</Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</section>