import { getHomepage } from '@/api/ibe.api';
import { computed, onMounted, onUpdated, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'Home',
  props: ['language'],
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();

    const homeSessionResolved = ref(false);

    const homeData = computed(() => store.getters.mainData);

    const fieldsToValidate = computed(() => {
      const fields = store.getters.fieldsToValidate;
      store.dispatch('setFieldsToValidate', fields);
      return fields;
    });

    onUpdated(() => {
      // console.log('removing expiry home');
      toast.removeGroup('expiry');
      store.dispatch('setExpiry', Date.now().valueOf() + 1000 * 60 * 60 * 24 * 7);
    });

    onMounted(async () => {
      // console.log('props.language' + props.language);
      // console.log('router.currentRoute.value.params.language' + router.currentRoute.value.params.language);
      await getHomepage(props.language || router.currentRoute.value.params.language, store, router);
      homeSessionResolved.value = true;
    });

    const pageTop = ref(null);

    const populateFlightQueryFromOffer = (offer) => {
      fieldsToValidate.value.departure = offer.data.departure;
      fieldsToValidate.value.arrival = offer.data.arrival;
      fieldsToValidate.value.dates = [new Date(offer.data.departureDate), new Date(offer.data.returnDate)];
      pageTop.value.scrollIntoView({ behavior: 'smooth' });
    };

    return {
      homeData,
      pageTop,
      fieldsToValidate,
      homeSessionResolved,
      populateFlightQueryFromOffer
    };
  }
};
