<section class="footer">
  <div class="footer-top flex justify-content-center">
    <div class="grid grid-nogutter dynwid">
      <section v-if="!menuMiddle" class="text-white flex flex-wrap w-12">
        <div class="col-12 lg:col-6">
          <div class="grid">
            <div v-for="(leftItem, index1) in menuLeft"
                 :key="index1"
                 class="col-6">
              <h4 class="mt-0">{{ leftItem.label }}</h4>
              <ul v-for="(menuItem, index2) in leftItem.list"
                  :key="index2">
                <li>
                  <a :href="menuItem.url"
                     class="impressum-link">{{ menuItem.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6">
          <div class="grid">
            <div class="col-12 pb-0">
              <h4 class="subscribe-title mt-0 mb-0 p-2 line-height-1 text-right">
                {{ menuRight.topMenu.label }}
              </h4>
            </div>
            <form class="col-12 pt-0">
              <div class="flex flex-wrap justify-content-between">
                <div class="col-12 pb-0 lg:col-9 px-0 lg:px-2">
                  <InputText id="footer-email"
                             v-model="v$.email.$model"
                             aria-describedby="footer-email-error"
                             :placeholder="pageLabels.form?.email" />
                  <div class="subscribe-response-wrapper">
                    <div v-if="v$.email.$errors && submitted">
                      <span id="footer-email-error"
                            v-for="(error, index) of v$.email.$errors"
                            :key="index">
                        <small class="p-error text-xs">{{ error.$message }}</small>
                      </span>
                      <small v-if="(v$.email.$invalid && v$.email.$errors.length == 0 && submitted) || v$.email.$pending.$response"
                             class="p-error text-xs">{{ v$.email.required.$message }}</small>
                    </div>
                    <small v-if="subscriptionSuccessful"
                           class="text-white text-xs">{{ successfulSubscriptionResponse}}</small>
                  </div>
                </div>
                <div class="col-12 lg:col-3 p-0 pt-2">
                  <div class="col-md-4 col-12 p-0">
                    <Button class="submit-button flex justify-content-center"
                            @click="handleSubmit(!v$.$invalid)">
                      <div class="text-white">
                        {{ pageLabels.form?.button }}
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <div class="w-12 flex justify-content-end align-items-center pt-3 mt-1">
              <div class="text-white text-lg font-semibold line-height-1">
                {{ menuRight.bottomMenu.label }}
              </div>
              <a v-for="(social, index) in menuRight.bottomMenu.icons"
                 class="flex py-0 px-2"
                 :key="index"
                 :href="social.url">
                <img :src="social.icon"
                     alt=""
                     style="height: 32px;" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section v-if="menuMiddle"
               class="text-white flex flex-wrap w-12 -mt-3">
        <div class="col-12 lg:col-4 text-center lg:text-left">
          <img :src="menuLeft.image"
               :alt="menuLeft.image"
               srcset="">
        </div>
        <div class="col-12 lg:col-4 text-center">
          <div class="text-white">{{menuMiddle.label}}</div>
          <div class="flex justify-content-center pt-2">
            <div v-for="(icon, index1) in menuMiddle.icons"
                 :key="icon + index1">
              <a :href="icon.url">
                <img :src="icon.icon"
                     alt=""
                     style="height: 32px;"
                     :class="{'pl-2': index1 != 0}" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-4">
          <div class="text-white text-center lg:text-right">
            <div v-for="(menuItem, index2) in menuRight.list"
                 class="text-white"
                 :key="index2">
              <span class="text-white">{{ menuItem.label }}{{' '}}<a :href="menuItem.href"
                   class="impressum-link text-white hover:underline"> {{ menuItem.link }}</a></span>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
  <div class="footer-bottom flex justify-content-center">
    <div class="container dynwid">
      <div class="row">
        <div class="col-12 copyright">
          <p class="text-md-left text-center"
             v-html="copyright.label"></p>
        </div>
      </div>
    </div>
  </div>
</section>