<script setup>
import initialState from '@/store/initialState';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Button from 'primevue/button';

const store = useStore();
const data = ref(Object.assign({}, initialState.default404));

const redirectUrl = computed(() => data.value.data.content.redirectUrl);

onMounted(() => {
  fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/page-not-found`)
    .then(response => response.json())
    .then(d => {
      data.value = d;
    });
});
</script>

<style scoped></style>

<template>
  <section>
    <div class="hidden xl:block">
      <Header></Header>
      <div class="w-full flex justify-content-center align-items-center" style="height: calc(100vh - 382px);"
        v-if="data.status === 'success'">
        <div class="text-center">
          <div class="text-8xl text-blue-500 line-height-1">
            {{ data.data.content.headline }}
          </div>
          <div class="text-2xl mt-2">
            {{ data.data.content.subtitle }}
          </div>
          <div class="text-lg mt-3">
            {{ data.data.content.text }}
          </div>
          <Button class="mt-4">
            <template #default>
              <a :href="redirectUrl" class="text-white no-underline">
                {{ data.data.content.button }}
              </a>
            </template>
          </Button>
        </div>
      </div>
      <Footer class="xl:absolute xl:bottom-0 w-full"></Footer>
    </div>
    <div class="block xl:hidden">
      <Header></Header>
      <div class="w-full flex justify-content-center align-items-center py-6"
        v-if="data.status === 'success'">
        <div class="text-center">
          <div class="text-8xl text-blue-500 line-height-1">
            {{ data.data.content.headline }}
          </div>
          <div class="text-2xl mt-2">
            {{ data.data.content.subtitle }}
          </div>
          <div class="text-lg mt-3">
            {{ data.data.content.text }}
          </div>
          <Button class="mt-4">
            <template #default>
              <a :href="redirectUrl" class="text-white no-underline">
                {{ data.data.content.button }}
              </a>
            </template>
          </Button>
        </div>
      </div>
      <Footer class="w-full"></Footer>
    </div>
  </section>
</template>
