import { computed, onBeforeMount, onBeforeUnmount, onMounted, onUpdated, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import FlightTime from '../../components/FlightTime/FlightTimeComponent.vue';
import StopDrawing from '../../components/StopDrawing/StopDrawingComponent.vue';
import MobileStopDrawing from '../../components/MobileStopDrawing/MobileStopDrawingComponent.vue';
import { useToast } from 'primevue/usetoast';
import detailUtils from '../../utils/detailUtils';
import store from '@/store';
import axios from 'axios';
import { expiryTimeForMessage } from '@/utils/watcherNew';

export default {
  name: 'FlightResults',
  components: { FlightTime, StopDrawing, MobileStopDrawing },
  setup: () => {
    const { getAirport, getPriceClass, getAirlineLogo, howManyCols, chunkifyPerks, getFilters, filterFlightResultsFlights } = detailUtils;

    const displayExpiredFlightModal = computed({
      get: () => {
        // console.log('get displayExpiredFlightModal whose value is: ', s_.getters.displayExpiredFlightModal);
        return s_.getters.displayExpiredFlightModal;
      },
      set: (newValue) => { s_.dispatch('setDisplayExpiredFlightModal', newValue); }
    });

    const s_ = useStore();
    const r_ = useRouter();
    const toast = useToast();
    const expiryToastAdded = ref(false);
    // toast.removeGroup('expiry');
    expiryToastAdded.value = false;

    store.dispatch('setAllFlightSearchImagesLoaded', true);
    const allFlightSearchImagesLoaded = computed(() => s_.getters.allFlightSearchImagesLoaded);

    const expiryTime = ref(9999);
    const lastExpiryTimeUpdate = ref(Date.now().valueOf() - 1000);
    const filters = ref({});
    const flightTabData = ref({});
    const totalItemsCount = ref(0);
    const offset = ref(0);
    const limit = ref(10);
    const selectedStops = ref([]);
    const selectedAirlines = ref([]);
    const selectedTimes = ref([]);
    const selectedBaggage = ref([]);
    const flightDetails = ref({});
    const isFiltersSidebarOpen = ref(false);
    const ftv = ref({});
    const fieldsToValidate = ref(s_.getters.fieldsToValidate);
    const resultsSessionResolved = ref(false);

    const baggage = computed(() => s_.getters.flightResults.baggage);
    const loading = computed(() => s_.getters.loading);
    const language = computed(() => s_.getters.language);
    const pageLabels = computed(() => s_.getters.flightResults.pageLabels);
    const flightResults = computed(() => s_.getters.flightResults);
    const isMobileFlightSearchOpen = computed(() => s_.getters.isMobileFlightSearchOpen);

    const travelerLabel = computed(() => totalTravelers.value > 1 ? pageLabels.value.travelers : pageLabels.value.traveler);

    const arrow = computed(() => { return Array.isArray(s_.getters.fieldsToValidate.dates) ? 'https://putnik-backend.ibe-servers.com/assets/images/general/arrows.svg' : 'https://putnik-backend.ibe-servers.com/assets/images/general/arrow.svg'; });

    const isDetailsSidebarOpen = computed({
      get: () => { return s_.getters.isDetailsSidebarOpen; },
      set: (newValue) => { s_.dispatch('setDetailsSidebarOpen', newValue); }
    });
    const isDetailsModalOpen = computed({
      get: () => { return s_.getters.isDetailsModalOpen; },
      set: (newValue) => { s_.dispatch('setIsDetailsModalOpen', newValue); }
    });

    const stopComputingSlicedFlights = ref(false);

    const populateFlightDetails = (flight) => {
      flightDetails.value = flight;
      const ffos = flight.flight?.outbound?.segments;
      flightDetails.value.oda = computed(() => { return { ...getAirport(ffos[0].departure.code), time: ffos[0].departure.time, date: ffos[0].departure.date }; });
      flightDetails.value.oaa = computed(() => { return { ...getAirport(ffos.at(-1).arrival.code), time: ffos.at(-1).arrival.time, date: ffos.at(-1).arrival.date }; });
      if ('inbound' in flight.flight) {
        const ffis = flight.flight?.inbound?.segments;
        flightDetails.value.ida = computed(() => { return { ...getAirport(ffis[0].departure.code), time: ffis[0].departure.time, date: ffis[0].departure.date }; });
        flightDetails.value.iaa = computed(() => { return { ...getAirport(ffis.at(-1).arrival.code), time: ffis.at(-1).arrival.time, date: ffis.at(-1).arrival.date }; });
      }
    };

    const populateFlightDetailsForSlice = (flight) => {
      const ffos = flight.flight?.outbound?.segments;
      flight.oda = computed(() => { return { ...getAirport(ffos[0].departure.code), time: ffos[0].departure.time, date: ffos[0].departure.date }; });
      flight.oaa = computed(() => { return { ...getAirport(ffos.at(-1).arrival.code), time: ffos.at(-1).arrival.time, date: ffos.at(-1).arrival.date }; });
      if ('inbound' in flight.flight) {
        const ffis = flight.flight?.inbound?.segments;
        flight.ida = computed(() => { return { ...getAirport(ffis[0].departure.code), time: ffis[0].departure.time, date: ffis[0].departure.date }; });
        flight.iaa = computed(() => { return { ...getAirport(ffis.at(-1).arrival.code), time: ffis.at(-1).arrival.time, date: ffis.at(-1).arrival.date }; });
      }
    };

    const slicedFlights = computed(() => {
      const flightResultFlights = filterFlightResultsFlights(flightResults, selectedAirlines, selectedTimes, selectedStops, selectedBaggage);

      const sliced = flightResultFlights.slice(offset.value, offset.value + limit.value);
      sliced.forEach(flight => {
        flight.expanded = ref(false);
        try {
          if (stopComputingSlicedFlights.value) {
            return;
          } else {
            flight = populateFlightDetailsForSlice(flight);
          }
        } catch (error) {
          console.error(error);
        }
      });
      totalItemsCount.value = flightResultFlights.length;
      return sliced;
    });

    const getBaggageFilter = (baggage) => {
      try {
        const outboundBaggage = {};
        baggage.outbound.list.forEach((b, i) => { outboundBaggage[i] = b; });
        baggage.outbound.list = outboundBaggage;
        if ('inbound' in baggage) {
          const inboundBaggage = {};
          baggage.inbound.list.forEach((b, i) => { inboundBaggage[i] = b; });
          baggage.inbound.list = inboundBaggage;
        }
        return getFilters(baggage);
      } catch (error) {
        return [];
      }
    };

    const groupedAirlines = computed(() => getFilters(flightResults.value.filters.airlines));
    const groupedTimes = computed(() => getFilters(flightResults.value.filters.times));
    const groupedStops = computed(() => getFilters(flightResults.value.filters.stops));
    const groupedBaggage = computed(() => getBaggageFilter(flightResults.value.filters.baggage));

    const totalTravelers = computed(() => {
      return s_.getters.passengerCountObject.ADT + s_.getters.passengerCountObject.CHD + s_.getters.passengerCountObject.INF;
    });

    const comparePriceClasses = computed(() => {
      try {
        const a = getPriceClass(flightDetails.value.priceClasses.outbound?.priceCode);
        const b = getPriceClass(flightDetails.value.priceClasses.inbound?.priceCode);
        return JSON.stringify(a) === JSON.stringify(b);
      } catch (error) {
        console.error(error);
        return false;
      }
    });

    const toggleMobileFlightSearch = () => {
      s_.dispatch('setIsMobileFlightSearchOpen', !isMobileFlightSearchOpen.value);
    };

    const reserve = ($event, flight) => {
      closeDetailsDialog();
      s_.dispatch('setLoading', true);
      s_.dispatch('setLoader', s_.getters.flightResults.loader);
      populateFlightDetails(flight); console.log('triggered 2');
      s_.dispatch('setFlightDetails', flightDetails.value);
      s_.dispatch('setPageLabels', pageLabels.value);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `https://${s_.getters.mainData.config.baseUrl}/${s_.getters.language}/flight/book`, true);

      xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          if (response.status === 'failure') {
            if (Object.keys(response.data).includes('error')) {
              console.error(response.data.error);
              toast.add({ severity: 'error', summary: '', detail: response.data.error.showMessage, life: 5000 });
              store.dispatch('setLoading', false);
              return;
            }
          }
          if (response.status !== 'success') {
            return;
          }
          if (Object.keys(response.data).includes('error')) {
            console.error(response.data.error);
            s_.dispatch('setLoading', false);
            if (response.data.error.redirectUrl) {
              r_.push(response.data.error.redirectUrl);
            }
            setTimeout(() => {
              toast.add({ severity: 'error', summary: '', detail: response.data.error.showMessage, life: 5000 });
            }, 200);
            return;
          }

          if ('loader' in response.data || (response.data.content && 'loader' in response.data.content)) {
            s_.dispatch('setLoader', response.data.loader || (response.data.content && 'loader' in response.data.content && response.data.content.loader));
          }

          const json = await fetch(`${response.data.config.apiUrl}/${response.data.config.bookParamsEncoded}`).then(res => res.json());

          if (json.data.content && 'error' in json.data.content) {
            if (json.data.error.redirectUrl) {
              r_.push(json.data.error.redirectUrl);
            }
            setTimeout(() => {
              toast.add({ severity: 'error', summary: '', detail: json.data.error.showMessage, life: 5000 });
              s_.dispatch('setLoading', false);
            }, 200);
            return;
          }

          s_.dispatch('setFlightBookData', json.data);
          window.localStorage.setItem('session', json.data.config.session.value);
          window.localStorage.setItem('bookParamsEncoded', json.data.config.paymentParamsEncoded || json.data.config.bookParamsEncoded);
          setTimeout(() => {
            s_.dispatch('setLoading', true);
            s_.dispatch('setShouldGetLoaderDataOnMounted', false);
          }, 1000);
          setTimeout(() => {
            r_.push(`/${s_.getters.language}/flight/book`);
          }, 2000);
        }
      };
      const data = `{"flightId": "${flightDetails.value.id}","session": "${s_.getters.session.value}"}`;
      xhr.send(data);
    };

    const openDetailsDialog = () => {
      ('opening');
      isDetailsModalOpen.value = true;
    };
    const closeDetailsDialog = () => {
      isDetailsModalOpen.value = false;
    };

    const openFiltersSidebar = () => {
      isFiltersSidebarOpen.value = true;
    };
    const closeFiltersSidebar = () => {
      isFiltersSidebarOpen.value = false;
    };
    const refreshPage = () => {
      location.reload();
    };
    const goHome = () => {
      toast.removeAllGroups();
      r_.push(`/${s_.getters.language}`);
    };

    const m = () => {
      s_.dispatch('setLoading', !s_.getters.loading);
    };

    const logChange = (event) => {
    };

    const changePage = (event) => {
    };

    const jan01ify = (dateString) => {
      const date = new Date(Date.parse(dateString));
      return `${date.toLocaleString(s_.getters.locale, { day: '2-digit' })} ${date.toLocaleDateString(s_.getters.locale, { month: 'long' }).slice(0, 3)}`;
    };

    const resetOffset = (event) => {
      offset.value = 0;
    };

    const openDetailsSidebar = () => {
      isDetailsSidebarOpen.value = true;
      store.dispatch('setIsDetailsSidebarOpen', true);
    };

    const closeDetailsSidebar = () => {
      isDetailsSidebarOpen.value = false;
      store.dispatch('setIsDetailsSidebarOpen', false);
    };

    const toggleDetailsSidebarOrModal = (event, flight) => {
      // console.log('toggleDetailsSidebarOrModal');
      // console.log(flight);
      // console.log('aaaaaaaaaaaaa');

      // console.log('populateFlightDetails(flight) with flight: ', flight);

      stopComputingSlicedFlights.value = true;
      setTimeout(() => {
        populateFlightDetails(flight); console.log('triggered 3');
        stopComputingSlicedFlights.value = false;

        if (window.innerWidth > 1200) {
          openDetailsDialog();
        } else {
          openDetailsSidebar();
        }
      }, 200);
    };

    onBeforeMount(() => {
      displayExpiredFlightModal.value = false;
    });

    onMounted(() => {
      resetOffset(undefined);
      displayExpiredFlightModal.value = false;

      expiryToastAdded.value = false;
      s_.dispatch('setLoading', true);
      setTimeout(() => { getFlights(s_, r_).then(r => resolveFlights(r)); }, 1000);
    });

    onUpdated(() => {
      try {
        if (s_.getters.loading !== true) {
          setTimeout(() => {
            let flightTimeElements;
            let flightNumberElements = [];
            let mobileStopDrawingElements = [];
            if (isDetailsModalOpen.value) {
              flightTimeElements = Array.from(document.querySelectorAll('.p-dialog-content .flight-time'));
              flightNumberElements = Array.from(document.querySelectorAll('.p-dialog-content .flight-number .flight-code'));
            } else if (isDetailsSidebarOpen.value) {
              flightTimeElements = Array.from(document.querySelectorAll('.p-sidebar-content .flight-time'));
              mobileStopDrawingElements = Array.from(document.querySelectorAll('.mobile-stop-drawing'));
              flightNumberElements = Array.from(document.querySelectorAll('.flight-number .flight-code'));
            } else {
              flightTimeElements = Array.from(document.querySelectorAll('.single-flight .flight-time'));
            }
            const b = flightTimeElements.map(item => item.offsetWidth);
            const c = Math.max.apply(null, b);
            flightTimeElements.forEach(element => {
              element.style.cssText = `width: ${c}px`;
            });
            const d = flightNumberElements.map(item => item.offsetWidth);
            const e = Math.max.apply(null, d);
            flightNumberElements.forEach(element => {
              element.style.cssText = `width: ${e}px; text-align: right; white-space: nowrap;`;
            });
            const screenWidth = window.innerWidth;
            const mobileStopDrawingWidth = screenWidth - 40 - 2 * c;
            mobileStopDrawingElements.forEach(element => {
              element.style.cssText = `width: ${mobileStopDrawingWidth}px`;
            });
          }, 50);
        }
      } catch (error) {
        //
      }
    });

    if (!store.getters.isExpiryBeingWatched) {
      //  watchExpire(expiryTime, lastExpiryTimeUpdate, expiryToastAdded, toast, flightResults.value.pageLabels.timer.headline, displayExpiredFlightModal);
      store.dispatch('setIsExpiryBeingWatched', true);
    }

    watch(() => s_.getters.searchSessionlessParamsEncoded, (newValue, oldValue) => {
      if (oldValue !== '') {
        expiryToastAdded.value = false;
        getFlights(s_, r_)
          .then(r => resolveFlights(r));
      }
    }, { deep: true });

    watch(() => fieldsToValidate, (nv_) => {
      s_.dispatch('setFieldsToValidate', nv_);
      fieldsToValidate.value = s_.getters.fieldsToValidate;
    }, { deep: true });

    watch(() => s_.getters.language, async (newValue) => {
      await getFlights(s_, r_);
      resolveFlights();
    }, { deep: true });

    watch(() => s_.getters.newSearches, (newValue) => {
      resetFiltersAndPagination();
    }, { deep: true });

    let sessionCheckIntervalId = null;
    sessionCheckIntervalId = setInterval(() => {
      // console.log('here');
      if (`${s_.getters.session.value}` === 'undefined' || `${s_.getters.session.value}` === '') {
        return;
      }
      axios.get(`https://${s_.getters.mainData.config.baseUrl}/sr/flight/get-session/${s_.getters.session.value}`).then(response => {
        // console.log('response.data: ', response.data.data.ttl);
        expiryTime.value = response.data.data.ttl;

        if (expiryTime.value > 300) {
          toast.removeGroup('expiry');
          expiryToastAdded.value = false;
          // console.log('Expiry still greater than 300 seconds, not adding toast');
        }

        const now = new Date();
        const expiryDate = new Date(Date.now() + expiryTime.value * 1000);
        const remaining = (expiryDate - now) / 1000;

        store.dispatch('setExpiryTimeForMessage', expiryTimeForMessage(Math.floor(remaining)));

        if (!expiryToastAdded.value && expiryTime.value < 300) {
          // console.log('lets goooooo');
          toast.removeGroup('expiry');
          setTimeout(() => {
            toast.add({
              severity: 'info',
              group: 'expiry',
              summary: flightResults.value.pageLabels.timer.headline,
              detail: computed(() => store.getters.expiryTimeForMessage),
              life: (remaining + 1) * 1000
            });
          }, 10);
          expiryToastAdded.value = true;
        } else {
          // console.log('Expiry toast already added or remaining time is greater than 300 seconds, not adding toast, these vars are ', expiryToastAdded.value, expiryTime.value);
        }
        if (remaining <= 1) {
          clearInterval(sessionCheckIntervalId);
          toast.removeAllGroups();
          // console.log('Session watcher expired');
          displayExpiredFlightModal.value = true;
        } else {
          displayExpiredFlightModal.value = false;
        }
      }).catch(error => {
        console.error(error);
      });
    }, 1000);

    store.dispatch('setComingFromSearchToBook', true);

    onBeforeUnmount(() => {
      clearInterval(sessionCheckIntervalId);
      toast.removeGroup('expiry');
    });

    return {
      allFlightSearchImagesLoaded,
      groupedBaggage,
      selectedBaggage,
      comparePriceClasses,
      reserve,
      refreshPage,
      goHome,
      displayExpiredFlightModal,
      arrow,
      howManyCols,
      chunkifyPerks,
      expiryTime,
      toggleMobileFlightSearch,
      isMobileFlightSearchOpen,
      totalTravelers,
      m,
      closeFiltersSidebar,
      openFiltersSidebar,
      isFiltersSidebarOpen,
      baggage,
      travelerLabel,
      fieldsToValidate,
      flightDetails,
      flightResults,
      flightTabData,
      groupedAirlines,
      groupedStops,
      groupedTimes,
      isDetailsModalOpen,
      isDetailsSidebarOpen,
      language,
      limit,
      loading,
      offset,
      pageLabels,
      selectedAirlines,
      selectedStops,
      selectedTimes,
      showSidebarCloseIcon: true,
      slicedFlights,
      totalItemsCount,
      resultsSessionResolved,
      changePage,
      getAirlineLogo,
      getAirport,
      getPriceClass,
      jan01ify,
      logChange,
      openDetailsDialog,
      resetOffset,
      toggleDetailsSidebarOrModal,
      closeDetailsSidebar
    };

    function resetFiltersAndPagination() {
      selectedAirlines.value = [];
      selectedStops.value = [];
      selectedTimes.value = [];
      offset.value = 0;
    }

    function resolveFlights(f) {
      resetFiltersAndPagination();
      Promise.resolve(f).then(response => {
        if (!response) {
          setTimeout(() => {
            store.dispatch('setLoading', false);
          }, 1000);
          return;
        }
        if (response?.content?.error) {
          console.error(response.content.error);
          if (response.content.error.redirectUrl) {
            r_.push(response.content.error.redirectUrl);
          }
          setTimeout(() => {
            s_.dispatch('setLoading', false);
            if (response.content.error.type === 'toast') {
              toast.add({ severity: 'error', summary: '', detail: response.content.error.showMessage, life: 5000 });
            }
          }, 200);
        } else {
          if (response?.config?.session) {
            s_.dispatch('setSession', response.config.session);
          }
          const rcsp = response.config.searchParams;
          ftv.value = rcsp;
          s_.dispatch('setDepartureCache', new Date(rcsp.departureDate));
          if ('returnDate' in rcsp && rcsp.returnDate !== '') {
            s_.dispatch('setOneWayFromStore', false);
            s_.dispatch('setReturnCache', new Date(rcsp.returnDate));
            fieldsToValidate.value.dates = [new Date(ftv.value.departureDate), new Date(ftv.value.returnDate)];
          } else {
            fieldsToValidate.value.dates = new Date(ftv.value.departureDate);
            s_.dispatch('setOneWayFromStore', true);
            s_.dispatch('setReturnCache', null);
          }
          expiryTime.value = response.config.session.ttl;
          store.dispatch('setExpiry', Date.now().valueOf() + response.config.session.ttl * 1000);
          filters.value = response.content.filters;
          flightTabData.value = response.content.form;
          fieldsToValidate.value.departure = ftv.value.departure;
          fieldsToValidate.value.arrival = ftv.value.arrival;
          s_.dispatch('setFlightResults', response.content);
          s_.dispatch('setFlightResultsHeader', response.header);
          const passengerObject = {
            ADT: rcsp.travelers.ADT ? Number.parseInt(rcsp.travelers.ADT) : 1,
            CHD: rcsp.travelers.CHD ? Number.parseInt(rcsp.travelers.CHD) : 0,
            INF: rcsp.travelers.INF ? Number.parseInt(rcsp.travelers.INF) : 0
          };
          s_.dispatch('setPassengerCountObject', passengerObject);
          // r_.push('/' + window.location.href.split('/')[3] + `${response.config.url}${response.config.searchSessionlessParamsEncoded}`.slice(3));
          r_.push(`${response.config.url}${response.config.searchSessionlessParamsEncoded}`);

          setTimeout(() => {
            s_.dispatch('setLoading', false);
          }, 200);
        }
      }).catch(err => {
        console.error(err);
        r_.push(`/${s_.getters.language}`);
      }).finally(() => {
        resultsSessionResolved.value = true;
      });
    }

    async function getFlights() {
      try {
        if (s_.getters.searchSessionlessParamsEncoded === '') {
          s_.dispatch('setSearchSessionlessParamsEncoded', r_.currentRoute.value.params.searchSessionlessParamsEncoded);
        }
        const baseUrl_ = s_.getters.mainData.config.baseUrl;
        const lang_ = r_.currentRoute.value.params.language;
        const spe_ = s_.getters.searchSessionlessParamsEncoded;
        const session = window.localStorage.getItem('session');
        const a = await fetch(`https://${baseUrl_}/${lang_}/flight/search/${spe_}${session ? `/${session}` : ''}`);
        const b = await a.json();

        if (b.data?.content?.error) {
          console.error(b.data?.content?.error);
          if (b.data?.content?.error.redirectUrl) {
            r_.push(b.data?.content?.error.redirectUrl);
            if (b.data?.content?.error.type === 'toast') {
              toast.add({ severity: 'error', summary: '', detail: b.data.content.error.showMessage, life: 5000 });
            }
          }
          return;
        }

        if (b.data?.error) {
          console.error(b.data?.error);
          if (b.data?.error.showMessage?.redirectUrl) {
            r_.push(b.data?.error.showMessage.redirectUrl);
            if (b.data?.error.type === 'toast') {
              toast.add({ severity: 'error', summary: '', detail: b.data.error.showMessage, life: 5000 });
            }
          }
          return;
        }

        window.localStorage.setItem('session', b.data.config.session.value);

        if ('loader' in b.data || (b.data.content && 'loader' in b.data.content)) {
          setTimeout(() => {
            store.dispatch('setLoader', b.data.loader || (b.data.content && 'loader' in b.data.content && b.data.content.loader));
          }, 5000);
        }
        return b.data;
      } catch (err) {
        console.error(err);
        return {
        };
      }
    }
  }
};
