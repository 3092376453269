export const unformatted = [
  {
    url: '/sr',
    apiUrl: 'https://putnik-backend.ibe-servers.com/sr',
    state: 'previous',
    icon: 'https://putnik-backend.ibe-servers.com/assets/images/general/home.svg',
    label: 'Home'
  },
  {
    url: '/sr/flight/search/WS9CRUcyMDIyLTA4LTA1L0JDTjIwMjItMDgtMjAvMS82MmJjNjMwNmVkYmM2',
    apiUrl: 'https://putnik-backend.ibe-servers.com/sr/flight/search/NjJiYzYzMDZlZGJjNg==',
    state: 'previous',
    label: 'Ponude'
  },
  {
    state: 'current',
    label: 'Rezervacija'
  },
  {
    state: 'next',
    label: 'Plaćanje'
  },
  {
    state: 'next',
    label: 'Potvrda'
  }
];

export const formatted = {
  home: {
    url: '/sr',
    state: 'previous',
    icon: 'https://putnik-backend.ibe-servers.com/assets/images/general/home.svg',
    label: 'Home',
    number: '1'
  },
  items: [
    {
      url: '/sr/flight/search/WS9CRUcyMDIyLTA4LTA1L0JDTjIwMjItMDgtMjAvMS82MmJjNjMwNmVkYmM2',
      apiUrl: 'https://putnik-backend.ibe-servers.com/sr/flight/search/NjJiYzYzMDZlZGJjNg==',
      state: 'previous',
      label: 'Ponude',
      number: '2'
    },
    {
      state: 'current',
      label: 'Rezervacija',
      number: '3'
    },
    {
      state: 'next',
      label: 'Plaćanje',
      number: '4'
    },
    {
      state: 'next',
      label: 'Potvrda',
      number: '5'
    }
  ]
};
