import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import initialState from '../../store/initialState';
import FlightTime from '../FlightTime/FlightTimeComponent.vue';
import detailUtils from '@/utils/detailUtils';

export default {
  name: 'Loader',
  components: { FlightTime },
  props: [],
  inheritAttrs: false,
  setup: () => {
    const jan01ify = detailUtils.jan01ify;

    onMounted(() => {
      const condition = (router.currentRoute.value.params.searchSessionlessParamsEncoded
        || window.localStorage.getItem('paymentParamsEncoded')
        || window.localStorage.getItem('bookParamsEncoded'))
        && store.getters.shouldGetLoaderDataOnMounted;
      if (condition) {
        getLoaderData();
      }
    });

    const router = useRouter();
    watch(() => router.currentRoute.value.params.searchSessionlessParamsEncoded, (nv_) => {
      if (store.getters.shouldGetLoaderDataOnMounted) {
        getLoaderData();
      }
    });
    const shouldBreak = ref(window.innerWidth < 1200);
    const resyze = () => {
      shouldBreak.value = innerWidth < 1200;
    };
    window.addEventListener('resize', resyze);

    const store = useStore();
    const loading = computed(() => store.getters.loading);
    const loader = computed(() => store.getters.loader);
    const m = () => {
      store.dispatch('setLoading', !store.getters.loading);
    };
    const loaderLoading = ref(false);

    const departureDateCorrect = computed(() => {
      return jan01ify(loader.value.flightData.departureDate) !== 'Invalid Date';
    });

    return {
      jan01ify,
      loaderLoading,
      shouldBreak,
      loading,
      loader,
      m,
      departureDateCorrect
    };

    async function getLoaderData() {
      if (store.getters.shouldGetLoaderDataOnMounted) {
        loaderLoading.value = true;
        try {
          const url = `https://putnik-backend.ibe-servers.com${router.currentRoute.value.fullPath}`
            .replace('/search', '/search/loader')
            .replace('/book', `/book/loader/${window.localStorage.getItem('bookParamsEncoded')}`)
            .replace('/payment', `/payment/loader/${window.localStorage.getItem('paymentParamsEncoded')}`);
          const a = await fetch(url);
          const b = await a.json();
          store.dispatch('setLoader', b.data.loader);
        } catch (err) {
          console.error(err);
          store.dispatch('setLoader', initialState.defaultLoader);
        } finally {
          store.dispatch('setShouldGetLoaderDataOnMounted', false);
        }
        setTimeout(() => {
          loaderLoading.value = false;
        }, 100);
      }
    }
  }
};
