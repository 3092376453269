import { ref, computed, watch, onUpdated } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
import router from '../../router';
import { useToast } from 'primevue/usetoast';
import initialState from '../../store/initialState';
import axios from 'axios';

export default {
  name: 'FlightSearch',
  props: ['flightTabData', 'fieldsToValidate', 'selectedStops', 'selectedAirlines', 'selectedTimes'],
  setup(props) {
    const store = useStore();

    const toast = useToast();
    const fieldsToValidate = ref(store.getters.fieldsToValidate);
    watch(() => fieldsToValidate, (nv_) => {
      store.dispatch('setFieldsToValidate', nv_);
      fieldsToValidate.value = store.getters.fieldsToValidate;
    });
    const maxPassengerCount = 9;

    const rules = {
      departure: { required },
      arrival: { required },
      dates: { required, mustBeCool: isValidDateOrDateRange }
    };

    onUpdated(() => {
      if (Array.isArray(fieldsToValidate.value.dates) && fieldsToValidate.value.dates[1] && isNaN(fieldsToValidate.value.dates[1])) {
        fieldsToValidate.value.dates[1] = null;
      }
    });

    const adultCount = ref(store.getters.passengerCountObject.ADT);
    const childCount = ref(store.getters.passengerCountObject.CHD);
    const infantCount = ref(store.getters.passengerCountObject.INF);
    const businessClass = ref(false);
    const calendarOverlay = ref();
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const dates = ref();
    const fetchedAirports = ref([]);
    const minDate = ref(new Date());
    const oneWay = ref(false);
    const passengerPickerOverlay = ref();
    const departure = ref();
    const arrival = ref();
    const showCalendarOverlay = ref(false);
    const showCalendarSidebar = ref(false);
    const submitted = ref(false);
    const datePickerOption = ref('departure');

    const oneWayFromStore = computed(() => store.getters.oneWayFromStore);
    const isOneWay = computed(() => determineOneWay());
    const formData = computed(() => store.getters.mainData.content.searchTabs.flight.form);

    const departingAirportError = computed(() => formData.value.fields[0].errors.empty);
    const airplaneIcon = computed(() => formData.value.fields[0].image.url);
    const departureAirportPlaceholder = computed(() => formData.value.fields[0].placeholder);

    const returnAirportError = computed(() => formData.value.fields[1].errors.empty);
    const returnAirportPlaceholder = computed(() => formData.value.fields[1].placeholder);

    const selectDepartingFlightLabel = computed(() => formData.value.fields[2].headline);
    const dateError = computed(() => formData.value.fields[2].errors.empty);
    const oneWayLabel = computed(() => formData.value.fields[2].oneWayFlight.label);

    const selectReturnFlightLabel = computed(() => formData.value.fields[3].headline);

    const businessClassLabel = computed(() => formData.value.fields[4].cabin.label);
    const passengerLabel = computed(() => formData.value.fields[4].placeholder);
    const adultLabel = computed(() => formData.value.fields[4].items[0].label);
    const adultSubtitle = computed(() => formData.value.fields[4].items[0].subtitle);
    const childLabel = computed(() => formData.value.fields[4].items[1].label);
    const childSubtitle = computed(() => formData.value.fields[4].items[1].subtitle);
    const infantLabel = computed(() => formData.value.fields[4].items[2].label);
    const infantSubtitle = computed(() => formData.value.fields[4].items[2].subtitle);

    const submitLabel = computed(() => formData.value.fields[5].label);

    const departurePlaceholder = computed(() => props.flightTabData.fields ? props.flightTabData.fields[2].placeholder : '');
    const returnPlaceholder = computed(() => props.flightTabData.fields ? props.flightTabData.fields[3].placeholder : '');

    const calendarWeekdays = computed(() => {
      const datesPlusOneSunday = Array.from(store.getters.mainData.config.datepicker.dayNamesShort);
      datesPlusOneSunday.push(store.getters.mainData.config.datepicker.dayNamesShort[0]);
      const whereToStart = store.getters.mainData.config.datepicker.firstDayOfWeek;
      return datesPlusOneSunday.slice(whereToStart, whereToStart + 7);
    });

    const departureCache = computed({
      get: () => { return store.getters.departureCache; },
      set: (newValue) => { store.dispatch('setDepartureCache', newValue); }
    });
    const returnCache = computed({
      get: () => { return store.getters.returnCache; },
      set: (newValue) => { store.dispatch('setReturnCache', newValue); }
    });

    const maxDate = computed(() => new Date(store.getters.mainData.content.searchTabs.flight.form.fields[2].maxDate));

    const showOneWayOption = computed(() => datePickerOption.value === 'return');

    const departureCalendarLabel = computed(() => {
      const label = formatCalendarLabel(departureCache, fieldsToValidate, departurePlaceholder, 0);
      store.dispatch('setDepartureCalendarLabel', label);
      return label;
    });

    const departureCalendarLabelIsDateCheck = computed(() => {
      try {
        const isDate = departureCalendarLabel.value.split('').some(char => !isNaN(char));
        const departureCalendarLabelClass = document.querySelector('.departure-calendar-button > span.p-button-label');
        if (!isDate) {
          departureCalendarLabelClass.classList.add('text-gray-600');
        } else {
          departureCalendarLabelClass.classList.remove('text-gray-600');
        }
        return departureCalendarLabel.value.split('').some(char => !isNaN(char));
      } catch (error) {
        return false;
      }
    });

    const returnCalendarLabel = computed(() => {
      const label = formatCalendarLabel(returnCache, fieldsToValidate, returnPlaceholder, 1);
      store.dispatch('setReturnCalendarLabel', label);  // Fixed dispatch action
      return label;
    });

    const returnCalendarLabelIsDateCheck = computed(() => {
      try {
        const isDate = returnCalendarLabel.value.split('').some(char => !isNaN(char));
        const returnCalendarLabelClass = document.querySelector('.return-calendar-button > span.p-button-label');
        if (!isDate) {
          returnCalendarLabelClass.classList.add('text-gray-600');
        } else {
          returnCalendarLabelClass.classList.remove('text-gray-600');
        }
        return returnCalendarLabel.value.split('').some(char => !isNaN(char));
      } catch (error) {
        return false;
      }
    });

    const dateSelectionLabel = computed(() => datePickerOption.value === 'departure' ? selectDepartingFlightLabel.value : selectReturnFlightLabel.value);
    const computedDateOption = computed(() => (oneWay.value || oneWayFromStore.value) ? 'single' : 'range');
    const discretePassengerLabel = computed(() => `${totalPassengerCount.value} ${totalPassengerCount.value === 1 ? passengerLabel.value.singular : passengerLabel.value.plural}`);

    /* Passenger Computeds */
    const totalPassengerCount = computed(() => adultCount.value + childCount.value + infantCount.value);
    const maxAdultPassengers = computed(() => maxPassengerCount - (childCount.value + infantCount.value));
    const maxChildPassengers = computed(() => maxPassengerCount - (adultCount.value + infantCount.value));
    const maxInfantPassengers = computed(() => Math.min(maxPassengerCount - (adultCount.value + childCount.value), adultCount.value));
    const minAdultCount = computed(() => infantCount.value > 1 ? infantCount.value : 1);

    const setDatePickerToDeparture = () => {
      minDate.value = new Date();
      oneWay.value = false;
      store.dispatch('setOneWayFromStore', false);
      datePickerOption.value = 'departure';
    };

    const setDatePickerToReturn = () => {
      datePickerOption.value = 'return';
    };

    const toggleOneWay = () => {
      calendarOverlay.value.hide();
      showCalendarOverlay.value = false;
      showCalendarSidebar.value = false;
      fieldsToValidate.value.dates = departureCache.value;
      oneWay.value = true;
      store.dispatch('setOneWayFromStore', true);
      returnCache.value = null;
      datePickerOption.value = 'departure';
    };

    const selectDate = (event) => {
      switch (datePickerOption.value) {
        case 'return':
          fieldsToValidate.value.dates[0] = departureCache.value;
          fieldsToValidate.value.dates[1] = event;
          returnCache.value = event;
          showCalendarOverlay.value = false;
          calendarOverlay.value.hide();
          showCalendarSidebar.value = false;
          break;

        case 'departure':
          fieldsToValidate.value.dates[0] = event;
          if (returnCache.value < fieldsToValidate.value.dates[0] || oneWay.value === true) {
            fieldsToValidate.value.dates[1] = null;
            returnCache.value = null;
          }
          if (oneWay.value === false && oneWayFromStore.value === false && returnCache.value) {
            fieldsToValidate.value.dates[1] = returnCache.value;
          }
          oneWay.value = false;
          store.dispatch('setOneWayFromStore', false);
          departureCache.value = event;
          minDate.value = event;
          datePickerOption.value = 'return';
          break;

        default:
          console.error('unknown error');
          break;
      }
    };

    const submitQuery = async () => {
      // Reset filters if on step 2
      store.dispatch('incrementNewSearches', 1);

      toast.removeAllGroups();
      store.dispatch('setShouldGetLoaderDataOnMounted', false);
      store.dispatch('setLoading', true);
      store.dispatch('setIsMobileFlightSearchOpen', false);
      // Populate Loader

      // console.log('setting default loader');
      const { headline, subtitles } = store.getters.loader;
      const newLoaderData = Object.assign({}, { ...initialState.defaultLoader, headline, subtitles });
      newLoaderData.flightData.departure = fieldsToValidate.value.departure ? (fieldsToValidate.value.departure.city || fieldsToValidate.value.departure.label?.split(',')[0]) : store.getters.loader.flightData.departure;
      newLoaderData.flightData.image.url = newLoaderData.flightData.image.url.replace('s.svg', oneWay.value ? '.svg' : 's.svg');
      newLoaderData.flightData.arrival = fieldsToValidate.value.arrival ? (fieldsToValidate.value.arrival.city || fieldsToValidate.value.arrival.label?.split(',')[0]) : store.getters.loader.flightData.arrival;
      newLoaderData.flightData.departureDate = Array.isArray(fieldsToValidate.value.dates) ? fieldsToValidate.value.dates[0] : fieldsToValidate.value.dates;
      newLoaderData.flightData.returnDate = Array.isArray(fieldsToValidate.value.dates) ? fieldsToValidate.value.dates[1] : null;
      newLoaderData.flightData.travelers.qty = totalPassengerCount.value;
      newLoaderData.flightData.travelers.label = `${totalPassengerCount.value === 1 ? passengerLabel.value.singular : passengerLabel.value.plural}`;
      // console.log('new loader data', newLoaderData);
      store.dispatch('setLoader', newLoaderData);

      // const xhr = new XMLHttpRequest();
      // xhr.open('POST', `https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/search`, true);
      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState === 4) {
      //     const response = JSON.parse(xhr.responseText);
      //     if (response.status === 'failure') {
      //       if (Object.keys(response.data).includes('error')) {
      //         console.error(response.data.error);
      //         toast.add({ severity: 'error', summary: '', detail: response.data.error.showMessage, life: 5000 });
      //         store.dispatch('setLoading', false);
      //         return;
      //       }
      //     }
      //     if (response.status !== 'success') {
      //       return;
      //     }
      //     const config = response.data.config;
      //     window.localStorage.setItem('session', config.session.value);
      //     store.dispatch('setSession', config.session);
      //     store.dispatch('setSearchSessionlessParamsEncoded', config.searchSessionlessParamsEncoded);

      //     // console.warn(`${config.url}${config.searchSessionlessParamsEncoded}`);
      //     // console.warn(router.currentRoute.value.fullPath);

      //     if (router.currentRoute.value.fullPath === `${config.url}${config.searchSessionlessParamsEncoded}`) {
      //       // console.log('same route!!!');
      //       location.reload();
      //       setTimeout(() => {
      //         // router.push(`${config.url}${config.searchSessionlessParamsEncoded}`);
      //         store.dispatch('setLoading', false);
      //       }, 2500);
      //     } else {
      //       router.push(`${config.url}${config.searchSessionlessParamsEncoded}`);
      //     }
      //   }
      //   if (xhr.readyState === 19) {
      //     setTimeout(() => {
      //       const response = JSON.parse(xhr.responseText);
      //       // console.log(response);
      //       if (Object.keys(response.data).includes('error')) {
      //         console.error(response.data.error);
      //         toast.add({ severity: 'error', summary: '', detail: response.data.error.showMessage, life: 5000 });
      //         store.dispatch('setLoading', false);
      //         // return;
      //       }
      //     }, 200);
      //   }
      // };

      // const jsonStringifiedDates = ref('');
      // // Check if it is a round trip
      // if (Array.isArray(fieldsToValidate.value.dates)) {
      //   jsonStringifiedDates.value = `"departureDate": "${isoDate(fieldsToValidate.value.dates[0])}", "returnDate": "${isoDate(fieldsToValidate.value.dates[1])}",`;
      // } else {
      //   jsonStringifiedDates.value = `"departureDate": "${isoDate(fieldsToValidate.value.dates)}",`;
      // }

      // // Format travelers
      // const jsonStringifiedTravelers = () => {
      //   let t = `"travelers": {"ADT":"${adultCount.value}"`;
      //   if (childCount.value > 0) {
      //     t += `,"CHD": "${childCount.value}"`;
      //   }
      //   if (infantCount.value > 0) {
      //     t += `,"INF": "${infantCount.value}"`;
      //   }
      //   t += '},';

      //   return t;
      // };

      // const data = `{
      //   ${jsonStringifiedDates.value}
      //   ${jsonStringifiedTravelers()}
      //   "departure": "${fieldsToValidate.value.departure.code}",
      //   "arrival": "${fieldsToValidate.value.arrival.code}",
      //   "cabin": "${businessClass.value ? 'C' : 'Y'}"
      // }`;

      // xhr.send(data);

      const baseUrl = `https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/search`;

      const ftv = fieldsToValidate.value;
      const dates = ftv.dates;
      const departureDate = isoDate(Array.isArray(dates) ? dates[0] : dates);
      const returnDate = Array.isArray(dates) ? isoDate(dates[1]) : undefined;

      const travelers = {
        ADT: adultCount.value.toString()
      };
      if (childCount.value > 0) {
        travelers.CHD = childCount.value.toString();
      }
      if (infantCount.value > 0) {
        travelers.INF = infantCount.value.toString();
      }

      const data = {
        departureDate,
        ...(returnDate && { returnDate }),  // Only include returnDate if it exists
        travelers,
        departure: ftv.departure.code,
        arrival: ftv.arrival.code,
        cabin: businessClass.value ? 'C' : 'Y'
      };

      // fetch(baseUrl, {
      //   method: 'POST',
      //   body: data
      // })
      try {
        const res = await axios.post(baseUrl, data);
        const response = res.data;

        if (response?.data?.error) {
          console.error(response.data.error);
          toast.add({ severity: 'error', summary: '', detail: response.data.error.showMessage, life: 5000 });
          store.dispatch('setLoading', false);
          return;
        }
        if (response.status !== 'success') {
          return;
        }
        const config = response.data.config;
        window.localStorage.setItem('session', config.session.value);
        store.dispatch('setSession', config.session);

        const sspe = config.searchSessionlessParamsEncoded;
        store.dispatch('setSearchSessionlessParamsEncoded', sspe);

        if (router.currentRoute.value.fullPath === `${config.url}${sspe}`) {
          // console.log('same route!!!');
          location.reload();
          setTimeout(() => store.dispatch('setLoading', false), 2500);
        } else {
          router.push(`${config.url}${sspe}`);
        }
      } catch (error) {
        console.error('Network error:', error);
        store.dispatch('setLoading', false);
      }
    };

    const searchAirports = async (event) => {
      if (event.query.length < 3) {
        fetchedAirports.value = [];
        return;
      }

      const airports = await getAirports(event.query, store);
      const fields = fieldsToValidate.value;

      fetchedAirports.value = airports.filter(airport => !validateAirport(airport, fields));
    };

    // const searchAirports = (event) => {
    //   if (event.query.length < 3) {
    //     fetchedAirports.value = [];
    //     return;
    //   }
    //   setTimeout(() => {
    //     const a = getAirports(event.query, store);
    //     Promise.resolve(a).then(airports => {
    //       for (var i = 0; i < airports.length; i++) {
    //         const fields = fieldsToValidate.value;
    //         const departureAirport = JSON.stringify({ code: fields.departure?.code, label: fields.departure?.label, city: fields.departure?.city });
    //         const arrivalAirport = JSON.stringify({ code: fields.arrival?.code, label: fields.arrival?.label, city: fields.arrival?.city });

    //         const j = JSON.stringify({ code: airports[i].code, label: airports[i].label, city: airports[i].city });

    //         if (fields.departure) {
    //           if (departureAirport === j) {
    //             airports.splice(i, 1);
    //           }
    //         }
    //         if (fields.arrival) {
    //           if (arrivalAirport === j) {
    //             airports.splice(i, 1);
    //           }
    //         }
    //       }
    //       fetchedAirports.value = airports;
    //     });
    //   }, 250);
    // };

    const togglePassengerPickerOverlay = (event) => {
      passengerPickerOverlay.value.toggle(event);
    };

    const toggleCalendarOverlay = (option, event) => {
      if (window.innerWidth > 1200) {
        calendarOverlay.value.toggle(event);
      } else {
        showCalendarSidebar.value = true;
      }

      if (option === 'departure' || !departureCache.value) {
        setDatePickerToDeparture();
      } else if (option === 'return') {
        setDatePickerToReturn();
      }
    };

    const handleSubmit = (isFormValid) => {
      submitted.value = true;
      if (isFormValid) {
        submitQuery();
      }
      setTimeout(() => {
        submitted.value = false;
      }, 5000);
    };

    const preventInput = (event) => {
      event.preventDefault();
    };

    const swapAirports = () => {
      const temp = fieldsToValidate.value.departure;
      fieldsToValidate.value.departure = fieldsToValidate.value.arrival;
      fieldsToValidate.value.arrival = temp;
    };

    watch(() => store.getters.passengerCountObject, (nv_) => {
      adultCount.value = nv_.ADT ? Number.parseInt(nv_.ADT) : 1;
      infantCount.value = nv_.INF ? Number.parseInt(nv_.INF) : 0;
      childCount.value = nv_.CHD ? Number.parseInt(nv_.CHD) : 0;
    }, { deep: true });

    watch(() => infantCount.value, (newInfantCount) => {
      const x = store.getters.passengerCountObject;
      infantCount.value = newInfantCount < adultCount.value ? newInfantCount : adultCount.value;
      x.INF = infantCount.value;
      store.dispatch('setPassengerCountObject', x);
    }, { deep: true });

    watch(() => childCount.value, (newChildCount) => {
      const x = store.getters.passengerCountObject;
      x.CHD = childCount.value;
      store.dispatch('setPassengerCountObject', x);
    }, { deep: true });

    watch(() => adultCount.value, (newAdultCount) => {
      const x = store.getters.passengerCountObject;
      infantCount.value = infantCount.value < newAdultCount ? infantCount.value : newAdultCount;
      x.ADT = adultCount.value;
      store.dispatch('setPassengerCountObject', x);
    }, { deep: true });

    watch(() => oneWay.value, (newValue) => {
      if (newValue === true) {
        store.dispatch('setOneWayFromStore', true);
        if (Array.isArray(fieldsToValidate.value.dates)) {
          fieldsToValidate.value.dates = fieldsToValidate.value.dates[0];
        }
        returnCache.value = null;
      } else {
        store.dispatch('setOneWayFromStore', false);
        if (!Array.isArray(fieldsToValidate.value.dates)) {
          fieldsToValidate.value.dates = [departureCache.value, null];
        }
      }
    }, { deep: true });

    watch(() => fieldsToValidate.value.dates, (value) => {
      if (Array.isArray(value)) {
        departureCache.value = value[0];
        returnCache.value = value[1];
        oneWay.value = false;
        store.dispatch('setOneWayFromStore', false);
      }
      if (!departureCache.value) {
        departureCache.value = null;
      }
      if (!returnCache.value) {
        returnCache.value = null;
      }
    }, { deep: true });

    // watch(() => router.currentRoute.value.fullPath, (newValue) => {
    //   if (['/sr', '/de', '/en'].find(path => path === newValue)) {
    //     departureCache.value = null;
    //     returnCache.value = null;
    //     fieldsToValidate.value.dates = [];
    //   }
    // });

    watch(() => store.getters.language, (value) => {
      // change departing and arriving airport
      if (!fieldsToValidate.value.departure || !fieldsToValidate.value.arrival) {
        return;
      }
      if ('code' in fieldsToValidate.value.departure) {
        const a = getAirports(fieldsToValidate.value.departure.code, store);
        Promise.resolve(a).then(arr => {
          fieldsToValidate.value.departure = arr.find(element => {
            const x = element.code === fieldsToValidate.value.departure.code;
            return x;
          });
        });
      }
      if ('code' in fieldsToValidate.value.arrival) {
        const a = getAirports(fieldsToValidate.value.arrival.code, store);
        Promise.resolve(a).then(arr => {
          fieldsToValidate.value.arrival = arr.find(element => {
            return element.code === fieldsToValidate.value.arrival.code;
          });
        });
      }
    });

    const v$ = useVuelidate(rules, fieldsToValidate);

    const calendarOverlayPosition = computed(() => {
      try {
        return document.querySelectorAll('#flight-results').length > 0 ? 238 : 389;
      } catch (error) {
        return 389;
      }
    });

    return {
      adultCount,
      adultLabel,
      adultSubtitle,
      airplaneIcon,
      arrival,
      businessClass,
      businessClassLabel,
      calendarLabel: '',
      calendarOverlay,
      calendarOverlayPosition,
      calendarWeekdays,
      childCount,
      childLabel,
      childSubtitle,
      computedDateOption,
      dateError,
      dates,
      dateSelectionLabel,
      departingAirportError,
      departure,
      departureAirportPlaceholder,
      departureCalendarLabel,
      discretePassengerLabel,
      fetchedAirports,
      infantCount,
      infantLabel,
      infantSubtitle,
      isOneWay,
      maxAdultPassengers,
      maxChildPassengers,
      maxDate,
      maxInfantPassengers,
      maxPassengerCount,
      minAdultCount,
      minDate,
      oneWay,
      oneWayLabel,
      passengerPickerOverlay,
      returnAirportError,
      returnAirportPlaceholder,
      returnCalendarLabel,
      showCalendarOverlay,
      showCalendarSidebar,
      showOneWayOption,
      submitLabel,
      submitted,
      totalPassengerCount,
      v$,
      departureCalendarLabelIsDateCheck,
      returnCalendarLabelIsDateCheck,
      handleSubmit,
      preventInput,
      searchAirports,
      selectDate,
      submitQuery,
      swapAirports,
      toggleCalendarOverlay,
      toggleOneWay,
      togglePassengerPickerOverlay
    };

    function validateAirport(airport, fields) {
      const airportData = JSON.stringify({
        code: airport.code,
        label: airport.label,
        city: airport.city
      });

      return (fields.departure && JSON.stringify(fields.departure) === airportData)
        || (fields.arrival && JSON.stringify(fields.arrival) === airportData);
    }

    function formatCalendarLabel(cache, fieldsToValidate, placeholder, index) {
      if (!cache.value) {
        return placeholder.value;
      }

      if (!(cache.value instanceof Date)) {
        return cache.value.toLocaleString('de-DE', dateOptions);
      }

      const dates = fieldsToValidate.value.dates;
      if (Array.isArray(dates) && dates[index] instanceof Date) {
        return dates[index].toLocaleString('de-DE', dateOptions);
      }

      if (dates && dates instanceof Date) {
        return dates.toLocaleString('de-DE', dateOptions);
      }

      return placeholder.value;
    }

    function determineOneWay() {
      const dates = fieldsToValidate.value.dates;
      const isOneWay = oneWay.value;
      const isOneWayFromStore = oneWayFromStore.value;
      let hasFilters;

      // Check if filter elements are present on the page, safely handling any potential errors
      try {
        hasFilters = document.querySelectorAll('.filters').length > 0;
      } catch (error) {
        hasFilters = false;
      }

      // Determine if the journey is one-way based on various conditions
      const noSecondDate = !Array.isArray(dates) || dates.length < 2 || !(dates[1] instanceof Date && !isNaN(dates[1].getTime()));
      return noSecondDate && (isOneWay || isOneWayFromStore || hasFilters);
    }

    function isValidDateOrDateRange() {
      const isOneWay = oneWay.value;
      const isOneWayFromStore = oneWayFromStore.value;
      const dates = fieldsToValidate.value.dates;

      // Check if the journey is one-way and dates is a valid Date instance
      const isValidOneWayDate = (isOneWay || isOneWayFromStore) && dates instanceof Date;

      // Check if the journey is not one-way and dates is a valid Date array with exactly two dates
      const isValidDateRange = (!isOneWay && !isOneWayFromStore) && Array.isArray(dates) && dates.length === 2 && dates[0] && dates[1];

      return isValidOneWayDate || isValidDateRange;
    }
  }
};

async function getAirports(searchString, store) {
  try {
    const a = await fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/autocomplete/${searchString}`);
    const b = await a.json();
    return b.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

function isoDate(date) {
  return date.toLocaleString('ja', { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('/', '-').split(' ')[0];
}
