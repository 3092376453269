<script src="./Loader.js"></script>
<style src="./Loader.scss" scoped lang="scss"></style>

<template>
  <Sidebar class="loading-sidebar" :visible="loading" position="full" :showCloseIcon="false">
    <div v-if="!loaderLoading" class="load-container flex align-items-center justify-content-center">
      <div v-if="loader?.flightData && departureDateCorrect" class="flex justify-content-center">
        <div>
          <!-- <Button @click="m" class="absolute -mt-5">LOADER</Button> -->
          <h1 class="text-2xl lg:text-5xl font-semibold mt-0 mb-5 text-center">{{ loader.headline }}</h1>
          <div v-if="shouldBreak">
            <div class="details-wrapper flex justify-content-center align-items-center">
              <div class="text-sm lg:text-lg loader-departure-city">{{ loader.flightData.departure }}</div>
              <div class="arrow"><img v-if="loader.flightData.image" :src="loader.flightData.image.url"></div>
              <div class="text-sm lg:text-lg loader-arrival-city">{{ loader.flightData.arrival }}</div>
            </div>
            <div class="details-wrapper flex justify-content-center align-items-center">
              <div class="text-sm lg:text-lg loader-departure-date">
                {{ jan01ify(loader.flightData.departureDate) !== 'Invalid Date' &&
                  jan01ify(loader.flightData.departureDate) }}
              </div>
              <div v-if="loader.flightData.returnDate"></div>
              <div v-if="loader.flightData.returnDate" class="arrow"><img v-if="loader.flightData.image"
                  :src="loader.flightData.image.url"></div>
              <div v-if="loader.flightData.returnDate" class="text-sm lg:text-lg loader-arrival-date">
                {{ jan01ify(loader.flightData.returnDate) !== 'Invalid Date' && jan01ify(loader.flightData.returnDate) }}
              </div>
              <div class="text-sm lg:text-lg fw-300">   |   </div>
              <div v-if="loader.flightData.travelers" class="text-sm lg:text-lg passenger-count">{{
                loader.flightData.travelers.qty }} </div>
              <div v-if="loader.flightData.travelers" class="text-sm lg:text-lg passenger-label">{{
                loader.flightData.travelers.label }}</div>
            </div>
            <div class="loader"></div>
            <div v-for="item in loader.subtitles" :key="item" class="text-sm lg:text-lg loading-subtitle text-center">
              <div>{{ item }}</div>
              <div v-if="loader.subtitles.length > 1" class="pt-2"></div>
            </div>
          </div>
          <div v-if="!shouldBreak">
            <div v-if="departureDateCorrect">
              <div class="details-wrapper flex justify-content-center align-items-center">
                <div class="text-sm lg:text-lg loader-departure-city">{{ loader.flightData.departure }}</div>
                <div class="arrow"><img v-if="loader.flightData.image" :src="loader.flightData.image.url"></div>
                <div class="text-sm lg:text-lg loader-arrival-city">{{ loader.flightData.arrival }}</div>
                <div v-if="(jan01ify(loader.flightData.departureDate) !== 'Invalid Date Inv')" class="text-sm lg:text-lg fw-300">   |   </div>
                <div class="text-sm lg:text-lg loader-departure-date">
                  {{ (jan01ify(loader.flightData.departureDate) === 'Invalid Date Inv' && ' ' ) ||
                    jan01ify(loader.flightData.departureDate) }}
                </div>
                <div v-if="loader.flightData.returnDate"></div>
                <div v-if="loader.flightData.returnDate" class="arrow"><img v-if="loader.flightData.image"
                    :src="loader.flightData.image.url"></div>
                <div v-if="loader.flightData.returnDate" class="text-sm lg:text-lg loader-arrival-date">
                  {{ jan01ify(loader.flightData.returnDate) !== 'Invalid Date' && jan01ify(loader.flightData.returnDate)}}
                </div>
                <div v-if="(jan01ify(loader.flightData.departureDate) !== 'Invalid Date Inv')" class="text-sm lg:text-lg fw-300">   |   </div>
                <div v-if="loader.flightData.travelers" class="text-sm lg:text-lg passenger-count">{{
                  loader.flightData.travelers.qty }} </div>
                <div v-if="loader.flightData.travelers" class="text-sm lg:text-lg passenger-label">{{
                  loader.flightData.travelers.label }}</div>
              </div>
              <div class="loader"></div>
            </div>
            <div v-for="item in loader.subtitles" :key="item" class="text-sm lg:text-lg loading-subtitle text-center">
              <div>{{ item }}</div>
              <div v-if="loader.subtitles.length > 1" class="pt-2"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loader?.flightData" class="flex justify-content-center">
        <div>
          <!-- <Button @click="m" class="absolute -mt-5">LOADER</Button> -->
          <h1 class="text-2xl lg:text-5xl font-semibold mt-0 mb-5 text-center">{{ loader?.headline || ' ' }}</h1>
          <div v-if="shouldBreak">
            <div class="loader"></div>
          </div>
        <div v-if="!shouldBreak">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>
</Sidebar></template>
