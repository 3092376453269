import { computed, ref, unref } from 'vue';
import store from '../store/index';
import { useStore } from 'vuex';

const vuex = useStore();

const flightResults = computed(() => {
  if (window.location.href.includes('/payment')) return store.getters.paymentData.data.content;
  if (window.location.href.includes('/book')) return store.getters.flightBookData.content;
  return store.getters.flightResults;
});

const getAirport = (code) => {
  const airport = flightResults?.value ? flightResults.value.airports[code] : null;
  return airport || { airport: '', city: '', code: 'XXXX', country: '' };
};
const getPriceClass = (code) => {
  let priceClass;
  try {
    priceClass = code && flightResults?.value ? flightResults?.value.priceClasses[code] : null;
  } catch (error) {
    return { name: '', items: [] };
  }
  return priceClass || { name: '', items: [] };
};
// const getAirlineLogo = (code) => flightResults?.value ? flightResults.value.airlines[code].image : null;
const getAirlineLogo = (code) => {
  try {
    return flightResults.value.airlines[code].image;
  } catch (error) {
    const emptyImageString = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==';
    if (window.location.href.includes('search')) {
      try {
        vuex.dispatch('setAllFlightSearchImagesLoaded', false);
        vuex.dispatch('setLoading', true);
      } catch (error) {
        store.dispatch('setAllFlightSearchImagesLoaded', false);
        store.dispatch('setLoading', true);
      }
      window.location.reload();
    }
    return emptyImageString;
  }
};

const chunkifyPerks = (a) => {
  const arr = Object.entries(a);
  const x = [];
  while (arr.length) {
    x.push(arr.splice(0, 5));
  }
  return x;
};

const howManyCols = (c) => {
  return `col-${12 / c.length}`;
};

function filterFlightResultsFlights(flightResults, selectedAirlines, selectedTimes, selectedStops, selectedBaggage) {
  let flightResultFlights = flightResults.value.flights;

  const outboundFilters = ref([]);
  const inboundFilters = ref([]);
  if (selectedAirlines.value.length > 0) {
    outboundFilters.value = selectedAirlines.value.filter(filterObject => unref(filterObject).leg === 'outbound').map(filterObject => unref(filterObject).listItem);
    inboundFilters.value = selectedAirlines.value.filter(filterObject => unref(filterObject).leg === 'inbound').map(filterObject => unref(filterObject).listItem);
    flightResultFlights = flightResultFlights.filter(flight => {
      const list = Object.values(flight.filters.airlines.outbound.list);
      const list2 = flight.filters.airlines.inbound ? Object.values(flight.filters.airlines.inbound.list) : [];
      return (outboundFilters.value.length === 0 || doesContainFilters(list, outboundFilters)) && (inboundFilters.value.length === 0 || doesContainFilters(list2, inboundFilters));
    });
  }
  if (selectedTimes.value.length > 0) {
    outboundFilters.value = selectedTimes.value.filter(filterObject => unref(filterObject).leg === 'outbound').map(filterObject => unref(filterObject).listItem);
    inboundFilters.value = selectedTimes.value.filter(filterObject => unref(filterObject).leg === 'inbound').map(filterObject => unref(filterObject).listItem);
    flightResultFlights = flightResultFlights.filter(flight => {
      const list = Object.keys(flight.filters.times.outbound.list).map(item => item.toString());
      const list2 = flight.filters.times.inbound ? Object.keys(flight.filters.times.inbound.list).map(item => item.toString()) : [];
      return (outboundFilters.value.length === 0 || doesContainFilters(list, outboundFilters)) && (inboundFilters.value.length === 0 || doesContainFilters(list2, inboundFilters));
    });
  }
  if (selectedStops.value.length > 0) {
    outboundFilters.value = selectedStops.value.filter(filterObject => unref(filterObject).leg === 'outbound').map(filterObject => unref(filterObject).listItem.toString());
    inboundFilters.value = selectedStops.value.filter(filterObject => unref(filterObject).leg === 'inbound').map(filterObject => unref(filterObject).listItem.toString());
    flightResultFlights = flightResultFlights.filter(flight => {
      const list = Object.values(flight.filters.stops.outbound.list).map(item => item.toString());
      const list2 = flight.filters.stops.inbound ? Object.values(flight.filters.stops.inbound.list).map(item => item.toString()) : [];
      return (outboundFilters.value.length === 0 || doesContainFilters(list, outboundFilters)) && (inboundFilters.value.length === 0 || doesContainFilters(list2, inboundFilters));
    });
  }
  if (selectedBaggage.value.length > 0) {
    outboundFilters.value = selectedBaggage.value.filter(filterObject => unref(filterObject).leg === 'outbound').map(filterObject => unref(filterObject).listItem.toString());
    inboundFilters.value = selectedBaggage.value.filter(filterObject => unref(filterObject).leg === 'inbound').map(filterObject => unref(filterObject).listItem.toString());
    flightResultFlights = flightResultFlights.filter(flight => {
      const list = Object.values(flight.filters.baggage.outbound.list).map(item => item.toString());
      const list2 = flight.filters.baggage.inbound ? Object.values(flight.filters.baggage.inbound.list).map(item => item.toString()) : [];
      return (outboundFilters.value.length === 0 || doesContainFilters(list, outboundFilters)) && (inboundFilters.value.length === 0 || doesContainFilters(list2, inboundFilters));
    });
  }
  return flightResultFlights;
}

function doesContainFilters(list, filters) {
  const a = list.some(val => filters.value.includes(val));
  return a;
}

function getFilters(a) {
  const x = ref([a.outbound]);
  if ('inbound' in a) {
    x.value.push(a.inbound);
  }
  try {
    return x.value.map(item => {
      return {
        label: item.label,
        items: Object.keys(item.list).map(listItem => {
          return {
            label: item.list[listItem],
            value: {
              listItem,
              leg: Object.keys(a)
                .filter(key => key !== 'label')
                .find(key => a[key].label === item.label)
            }
          };
        })
      };
    });
  } catch (error) {
    return [];
  }
}

function jan01ify(dateString) {
  const date = new Date(Date.parse(dateString));
  let result = '';
  try {
    result = `${date.toLocaleString(store.getters.locale, { day: '2-digit' })} ${date.toLocaleDateString(store.getters.locale, { month: 'long' }).slice(0, 3)}`;
  } catch (error) {
    result = '';
  }
  return (date instanceof Date && result) || '';
}

export default { getAirlineLogo, getAirport, getPriceClass, chunkifyPerks, howManyCols, filterFlightResultsFlights, getFilters, jan01ify };
