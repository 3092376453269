// import { mapState } from 'vuex';
// import fb from '../../assets/images/facebook.svg'
// import FacebookSvg from '../components/UI/SvgImages/SocialIconsSvg/Facebook';
// import TwitterSvg from '../components/UI/SvgImages/SocialIconsSvg/Twitter';
// import LinkedinSvg from '../components/UI/SvgImages/SocialIconsSvg/Linkedin';

import { useStore } from 'vuex';
import { reactive, ref, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { email, required, helpers } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';

export default {
  name: 'Footer',
  components: {},
  setup: () => {
    const fieldsToValidate = reactive({ email: '' });

    const store = useStore();
    const menuRight = computed(() => store.getters.mainData.footer.menuRight);
    const menuLeft = computed(() => store.getters.mainData.footer.menuLeft);
    const menuMiddle = computed(() => store.getters.mainData.footer.menuMiddle);
    const copyright = computed(() => store.getters.mainData.footer.copyright);
    const pageLabels = computed(() => store.getters.mainData.footer.pageLabels);

    const rules = { email: { required: helpers.withMessage(pageLabels.value?.form.regexError.required || '', required), email: helpers.withMessage(pageLabels.value?.form.regexError.email || '', email) } };
    const v$ = useVuelidate(rules, fieldsToValidate);

    // store.getters.mainData.footer.menuLeft.forEach(list => {
    //   list.list.forEach(item => { item.url = '/' + item.url.substring(1).replace('/', '/page/'); (item.url); });
    // });

    const submitted = ref(false);
    const handleSubmit = (isFormValid) => {
      submitted.value = true;

      if (!isFormValid) {
        console.error('Subscribe from check fail');
        return;
      }

      subscribeToNewsletter().then(() => {
        submitted.value = false;
        fieldsToValidate.email = '';
        setTimeout(() => {
          subscriptionSuccessful.value = false;
          successfulSubscriptionResponse.value = '';
        }, 2000);
      });
    };

    const subscriptionSuccessful = ref(false);
    const successfulSubscriptionResponse = ref('');

    const subscribeToNewsletter = async () => {
      try {
        const response = await fetch(menuRight.value.topMenu?.form.apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: fieldsToValidate.email })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();

        if (responseData.status !== 'success') {
          console.error(responseData);
          return;
        }

        if (responseData.data?.error) {
          responseData.data.error.forEach(error => {
            useToast().add({ severity: 'error', summary: '', detail: error });
          });
          return;
        }

        subscriptionSuccessful.value = true;
        successfulSubscriptionResponse.value = responseData.data.message;
      } catch (error) {
        console.error('Error in subscription:', error);
      }
    };

    return {
      subscribeToNewsletter,
      successfulSubscriptionResponse,
      subscriptionSuccessful,
      v$,
      handleSubmit,
      submitted,
      copyright,
      menuLeft,
      menuRight,
      menuMiddle,
      pageLabels
    };
  }
};
