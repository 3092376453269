import { ref, reactive, computed, watch } from 'vue';
import { required, email, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';

export default {
  name: 'contact-page',
  components: {},
  props: [],
  setup() {
    const store = useStore();
    const toast = useToast();

    const language = ref(store.getters.language);

    const fieldsToValidate = reactive({
      name: ref(''),
      email: ref(''),
      message: ref('')
    });

    const resetForm = () => {
      fieldsToValidate.name = '';
      fieldsToValidate.email = '';
      fieldsToValidate.message = '';
      submitted.value = false;
    };

    const loading = ref(true);
    const isLoading = computed(() => loading.value);
    const contactData = ref();

    watch(() => contactData.value, (newValue) => {
      addressIcon.value = newValue.content.info.address.icon.url;
    }, { deep: true });

    const addressIcon = ref();

    let rules;
    const v$ = ref(null);

    fetch(
      `https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/contact`
    )
      .then((res) => res.json())
      .then((d) => {
        contactData.value = Object.assign({}, d.data);
        const c_ = contactData.value.content;
        rules = {
          name: {
            required: helpers.withMessage(c_.pageLabels.form.regexError.required, required),
            regex: helpers.withMessage(c_.pageLabels.form.regexError[c_.form.fields.name.regexError],
              helpers.regex(new RegExp(c_.form.fields.name.regex)))
          },
          email: {
            required: helpers.withMessage(c_.pageLabels.form.regexError.required, required),
            // email,
            email: helpers.withMessage(c_.pageLabels.form.regexError[c_.form.fields.email.regexError], email),
            regex: helpers.withMessage(c_.pageLabels.form.regexError[c_.form.fields.email.regexError],
              helpers.regex(new RegExp(c_.form.fields.email.regex)))
          },
          message: {
            required: helpers.withMessage(c_.pageLabels.form.regexError.required, required)
          }
        };
        v$.value = useVuelidate(rules, fieldsToValidate);
        setTimeout(() => {
          loading.value = false;
        }, 200);
      })
      .catch((err) => {
        console.error(err);
        return [];
      });

    const submitted = ref(false);

    const handleSubmit = (isFormValid) => {
      submitted.value = true;

      if (!isFormValid) {
        setTimeout(() => {
          submitted.value = true;
        }, 1000);
        return;
      }

      var xhr = new XMLHttpRequest();
      xhr.open(
        contactData.value.content.form.method,
        contactData.value.content.form.apiUrl
      );
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          // toast success
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            // console.log(response);
            toast.add({
              severity: 'success',
              // summary: 'Success',
              detail: response.data.message,
              life: 300000
            });
          }
        }
      };

      const data = `{"name":"${fieldsToValidate.name}","email":"${fieldsToValidate.email}","message":"${fieldsToValidate.message}"}`;

      xhr.send(data);

      submitted.value = false;
      resetForm();
      // }
    };

    return {
      language,
      fieldsToValidate,
      v$,
      resetForm,
      handleSubmit,
      submitted,
      isLoading,
      addressIcon,
      contactData
    };
  }
};
