/* eslint-disable quotes */
const pageLabels = {
  filters: 'Filteri',
  flightDuration: 'Trajanje leta',
  flightDurationTotal: 'Ukupno trajanje leta',
  pricePerPerson: 'Cena po osobi',
  priceFor: 'Cena za',
  traveler: 'Putnik',
  travelers: 'putnika',
  flightDetails: 'Detalji leta',
  buttonSelect: 'Izaberi',
  departure: 'Polazak',
  arrival: 'Povratak',
  stopIn: 'Presedanje u',
  timer: {
    headline: 'Preostalo vreme za odabir leta',
    dialog: {
      headline: 'Vreme za odabir leta je isteklo',
      subtitle: 'Da li želite da osvežite rezultate pretrage?',
      buttons: {
        yes: 'Ponovi pretragu',
        no: 'Odustani'
      }
    }
  }
};
export default {
  pageLabels,
  defaultLoader: {
    headline: '',
    flightData: {
      departure: '',
      image: {
        url: 'https://putnik-backend.ibe-servers.com/assets/images/general/arrows.svg'
      },
      arrival: '',
      departureDate: '2022-01-01',
      returnDate: '2022-02-01',
      travelers: {
        label: 'putnik',
        qty: 1
      }
    },
    subtitles: [
      ' '
    ]
  },
  defaultData: {
    config: {
      baseUrl: 'putnik-backend.ibe-servers.com',
      activeLanguage: 'sr',
      datepicker: {
        dayNames: [
          'Nedelja',
          'Ponedeljak',
          'Utorak',
          'Sreda',
          'Četvrtak',
          'Petak',
          'Subota'
        ],
        dayNamesShort: [
          'Ned',
          'Pon',
          'Uto',
          'Sre',
          'Čet',
          'Pet',
          'Sub'
        ],
        dayNamesMin: [
          'Ned',
          'Pon',
          'Uto',
          'Sre',
          'Čet',
          'Pet',
          'Sub'
        ],
        monthNames: [
          'Januar',
          'Februar',
          'Mart',
          'April',
          'Maj',
          'Jun',
          'Jul',
          'Avgust',
          'Septembar',
          'Oktobar',
          'Novembar',
          'Decembar'
        ],
        monthNamesShort: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Maj',
          'Jun',
          'Jul',
          'Avg',
          'Sep',
          'Okt',
          'Nov',
          'Dec'
        ],
        firstDayOfWeek: 1
      }
    },
    header: {
      logo: {
        image: 'https://putnik-backend.ibe-servers.com/assets/images/webpro-logo.png',
        alt: 'putnik-backend.ibe-servers.com'
      },
      menu: [
        {
          name: 'home',
          label: 'Home',
          url: '/sr',
          apiUrl: 'https://putnik-backend.ibe-servers.com/sr',
          method: 'GET'
        },
        {
          name: 'contact',
          label: 'Kontakt',
          url: '/sr/contact',
          apiUrl: 'https://putnik-backend.ibe-servers.com/sr/contact',
          method: 'GET'
        }
      ],
      languageMenu: [
        {
          label: 'SR',
          url: '/sr',
          apiUrl: 'https://putnik-backend.ibe-servers.com/sr',
          method: 'GET',
          selected: true
        },
        {
          label: 'DE',
          url: '/de',
          apiUrl: 'https://putnik-backend.ibe-servers.com/de',
          method: 'GET',
          selected: false
        },
        {
          label: 'EN',
          url: '/en',
          apiUrl: 'https://putnik-backend.ibe-servers.com/en',
          method: 'GET',
          selected: false
        }
      ]
    },
    content: {
      top: {
        headline: {
          label: 'Letite iz Beograda jeftinije!'
        },
        subtitle: {
          label: 'Webpro za Vas pronalazi najbolje cene'
        }
      },
      searchTabs: {
        flight: {
          form: {
            url: '/sr/flight/search',
            apiUrl: 'https://putnik-backend.ibe-servers.com/sr/flight/search',
            method: 'POST',
            image: {
              arrows: 'https://putnik-backend.ibe-servers.com/assets/images/general/arrows.svg'
            },
            fields: [
              {
                name: 'departure',
                regex: '^[a-zA-Z\\, ]+\\([a-zA-Z]{3,3}\\)',
                allowedCharacterSet: ' abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ(),\\-',
                apiUrl: 'https://putnik-backend.ibe-servers.com/sr/autocomplete/',
                method: 'GET',
                placeholder: 'Polazak',
                image: {
                  url: 'https://putnik-backend.ibe-servers.com/assets/images/general/airplane.svg'
                },
                errors: {
                  empty: 'Ovo polje je obavezno.'
                }
              },
              {
                name: 'destination',
                regex: '^[a-zA-Z\\, ]+\\([a-zA-Z]{3,3}\\)',
                allowedCharacterSet: ' abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ(),\\-',
                apiUrl: 'https://putnik-backend.ibe-servers.com/sr/autocomplete/',
                method: 'GET',
                placeholder: 'Destinacija',
                image: {
                  url: 'https://putnik-backend.ibe-servers.com/assets/images/general/airplane.svg'
                },
                errors: {
                  empty: 'Ovo polje je obavezno.',
                  unique: 'Polazak i destinacija ne mogu biti isti.'
                }
              },
              {
                name: 'departureDate',
                regex: '^([0-2][0-9]|(3)[0-1])(.)(((0)[0-9])|((1)[0-2]))(.)\\d{4}$',
                allowedCharacterSet: '0123456789.',
                placeholder: 'Polazak',
                headline: 'Izaberi datum polaska',
                image: {
                  url: 'https://putnik-backend.ibe-servers.com/assets/images/general/calendar.svg'
                },
                maxDate: '2024-07-31',
                oneWayFlight: {
                  type: 'button',
                  label: 'Želim let u jednom pravcu'
                },
                errors: {
                  empty: 'Ovo polje je obavezno.'
                }
              },
              {
                name: 'returnDate',
                regex: '^([0-2][0-9]|(3)[0-1])(.)(((0)[0-9])|((1)[0-2]))(.)\\d{4}$',
                allowedCharacterSet: '0123456789.',
                placeholder: 'Povratak',
                headline: 'Izaberi datum povratka',
                image: {
                  url: 'https://putnik-backend.ibe-servers.com/assets/images/general/calendar.svg'
                },
                maxDate: '2024-07-31',
                oneWayFlight: {
                  type: 'button',
                  label: 'Želim let u jednom pravcu'
                },
                errors: {
                  empty: 'Ovo polje je obavezno.'
                }
              },
              {
                name: 'travelers',
                placeholder: {
                  singular: 'putnik',
                  plural: 'putnika'
                },
                items: [
                  {
                    name: 'ADT',
                    label: 'Odrasli',
                    subtitle: '12+ godina'
                  },
                  {
                    name: 'CHD',
                    label: 'Deca',
                    subtitle: '2-12 godina'
                  },
                  {
                    name: 'INF',
                    label: 'Bebe',
                    subtitle: '0-24 meseca'
                  }
                ],
                cabin: {
                  type: 'checkbox',
                  name: 'cabin',
                  label: 'Biznis klasa',
                  values: {
                    economy: 'Y',
                    business: 'C'
                  }
                }
              },
              {
                type: 'button',
                label: 'Traži'
              }
            ]
          }
        }
      },
      services: {
        headline: 'Tri Razloga da putujete sa nama',
        list: [
          {
            image: {
              url: 'https://putnik-backend.ibe-servers.com/assets/images/homepage/service1.svg',
              alt: ''
            },
            title: 'Sjajne ponude',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            image: {
              url: 'https://putnik-backend.ibe-servers.com/assets/images/homepage/service2.svg',
              alt: ''
            },
            title: 'Call Centar',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            image: {
              url: 'https://putnik-backend.ibe-servers.com/assets/images/homepage/service3.svg',
              alt: ''
            },
            title: 'Usluga',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          }
        ]
      },
      offers: {
        headline: 'Uvek najbolje cene za sve destinacije',
        list: [
          {
            image: {
              url: 'https://putnik-backend.ibe-servers.com/assets/images/homepage/zurich.png',
              alt: ''
            },
            label: 'od 19.299 RSD',
            city: 'Cirih',
            country: 'Švajcarska',
            data: {
              departure: {
                code: 'BEG',
                label: 'Beograd, Nikola Tesla (BEG)'
              },
              departureDate: '2023-05-10',
              arrival: {
                code: 'ZRH',
                label: 'Cirih, Zurich Airport (ZRH)'
              },
              returnDate: '2023-05-15',
              travelers: {
                ADT: '1'
              },
              cabin: 'M'
            }
          },
          {
            image: {
              url: 'https://putnik-backend.ibe-servers.com/assets/images/homepage/barcelona.png',
              alt: ''
            },
            label: 'od 28.805 RSD',
            city: 'Barselona',
            country: 'Španija',
            data: {
              departure: {
                code: 'BEG',
                label: 'Beograd, Nikola Tesla (BEG)'
              },
              departureDate: '2023-05-10',
              arrival: {
                code: 'BCN',
                label: 'Barselona, Airport (BCN)'
              },
              returnDate: '2023-05-15',
              travelers: {
                ADT: '1'
              },
              cabin: 'M'
            }
          },
          {
            image: {
              url: 'https://putnik-backend.ibe-servers.com/assets/images/homepage/berlin.png',
              alt: ''
            },
            label: 'od 21.282 RSD',
            city: 'Berlin',
            country: 'Nemačka',
            data: {
              departure: {
                code: 'BEG',
                label: 'Beograd, Nikola Tesla (BEG)'
              },
              departureDate: '2023-05-10',
              arrival: {
                code: 'BER',
                label: 'Berlin, Brandenburg (BER)'
              },
              returnDate: '2023-05-15',
              travelers: {
                ADT: '1'
              },
              cabin: 'Y'
            }
          },
          {
            image: {
              url: 'https://putnik-backend.ibe-servers.com/assets/images/homepage/london.png',
              alt: ''
            },
            label: 'od 23.430 RSD ',
            city: 'London',
            country: 'Engleska',
            data: {
              departure: {
                code: 'BEG',
                label: 'Beograd, Nikola Tesla (BEG)'
              },
              departureDate: '2023-05-10',
              arrival: {
                code: 'LON',
                label: 'London, Svi aerodromi (LON)'
              },
              returnDate: '2023-05-15',
              travelers: {
                ADT: '1'
              },
              cabin: 'M'
            }
          }
        ]
      }
    },
    loader: {
      headline: ' ',
      flightData: {},
      subtitles: [
        'Pretraga letova je u toku.',
        'Sistem pretražuje bazu od preko 500 avio kompanija u potrazi za najjeftinijom avio kartom.'
      ]
    },
    footer: {
      menuLeft: [
        {
          label: 'USLOVI I UPUTSTVA',
          list: [
            {
              label: 'Uslovi korišćenja veb sajta',
              url: '/sr/info/uslovi-koriscenja-veb-sajta',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/info/uslovi-koriscenja-veb-sajta',
              method: 'GET'
            },
            {
              label: 'Opšti uslovi putovanja',
              url: '/sr/info/opsti-uslov-putovanja',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/info/opsti-uslov-putovanja',
              method: 'GET'
            },
            {
              label: 'Načini plaćanja',
              url: '/sr/info/nacini-placanja',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/info/nacini-placanja',
              method: 'GET'
            },
            {
              label: 'Uputstvo za uplatu',
              url: '/sr/info/uputstvo-za-uplatu',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/info/uputstvo-za-uplatu',
              method: 'GET'
            },
            {
              label: 'Politika privatnosti',
              url: '/sr/info/politika-privatnosti',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/info/politika-privatnosti',
              method: 'GET'
            }
          ]
        }
      ],
      menuRight: {
        topMenu: {
          label: 'PRETPLATITE SE NA NAŠ NEWS LETTER I PRVI SAZNAJTE NAJPOVOLJNIJE PONUDE',
          form: {
            apiUrl: 'https://putnik-backend.ibe-servers.com/sr/subscribe',
            method: 'POST',
            fields: {
              email: {
                regex: `^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$`,
                regexError: 'email',
                allowedCharacterSet: ' abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\\-+_@'
              }
            }
          }
        },
        bottomMenu: {
          label: 'PRATITE NAS',
          icons: [
            {
              icon: 'https://putnik-backend.ibe-servers.com/assets/images/fb.svg',
              url: 'https://www.facebook.com/mark'
            },
            {
              icon: 'https://putnik-backend.ibe-servers.com/assets/images/in.svg',
              url: 'https://www.linkedin.com/company/meta'
            },
            {
              icon: 'https://putnik-backend.ibe-servers.com/assets/images/twitter.svg',
              url: 'https://twitter.com/meta'
            }
          ]
        }
      },
      copyright: {
        label: '&copy; Copyright Webpro d.o.o. 2023'
      },
      pageLabels: {
        form: {
          regexError: {
            email: 'Nepravilan format email adrese',
            required: 'Ovo polje je obavezno'
          },
          email: 'Email adresa',
          button: 'Pretplatite se'
        }
      }
    }
  },
  defaultFlightResults: { pageLabels: Object.assign(pageLabels), flights: [], filters: { times: { label: '' }, stops: { label: '' }, airlines: { label: '' }, baggage: { label: '' } } },
  defaultPaymentData: {
    status: 'success',
    data: {
      config: {
        baseUrl: "putnik-backend.ibe-servers.com",
        activeLanguage: "de",
        session: {
          value: "667ae4e81737d",
          ttl: 630,
          timerWarningAt: 300
        },
        url: "/de/flight/payment/",
        apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/payment",
        paymentParamsEncoded: "NjY3YWU0ZTgxNzM3ZA==",
        method: "GET"
      },
      header: {
        logo: {
          image: "https://putnik-backend.ibe-servers.com/assets/images/logo.svg",
          alt: "putnik-backend.ibe-servers.com"
        },
        favicon: {
          image: "https://putnik-backend.ibe-servers.com/assets/images/favicon.ico"
        },
        title: "Putnik Travel",
        menu: [
          {
            name: "home",
            label: "Home",
            url: "/de",
            apiUrl: "https://putnik-backend.ibe-servers.com/de",
            method: "GET"
          },
          {
            name: "contact",
            label: "Kontakt",
            url: "/de/contact",
            apiUrl: "https://putnik-backend.ibe-servers.com/de/contact",
            method: "GET"
          }
        ],
        languageMenu: [
          {
            label: "SR",
            url: "/sr/flight/payment/NjY3YWU0ZTgxNzM3ZA==",
            apiUrl: "https://putnik-backend.ibe-servers.com/sr/flight/payment/NjY3YWU0ZTgxNzM3ZA==",
            method: "GET",
            selected: false
          },
          {
            label: "DE",
            url: "/de/flight/payment/NjY3YWU0ZTgxNzM3ZA==",
            apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/payment/NjY3YWU0ZTgxNzM3ZA==",
            method: "GET",
            selected: true
          },
          {
            label: "EN",
            url: "/en/flight/payment/NjY3YWU0ZTgxNzM3ZA==",
            apiUrl: "https://putnik-backend.ibe-servers.com/en/flight/payment/NjY3YWU0ZTgxNzM3ZA==",
            method: "GET",
            selected: false
          }
        ]
      },
      content: {
        breadcrumbNavigation: {
          icon: "https://putnik-backend.ibe-servers.com/assets/images/general/angle-right.svg",
          home: {
            number: "1",
            url: "/de",
            state: "previous",
            icon: "https://putnik-backend.ibe-servers.com/assets/images/general/home.svg",
            label: "Home"
          },
          items: [
            {
              number: "2",
              url: "/de/flight/search/WS9CRUcyMDI0LTA3LTA5L1pSSDIwMjQtMDctMTYvMTA=",
              apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/search/WS9CRUcyMDI0LTA3LTA5L1pSSDIwMjQtMDctMTYvMTA=",
              urlParams: "WS9CRUcyMDI0LTA3LTA5L1pSSDIwMjQtMDctMTYvMTA=",
              state: "previous",
              label: "Suchergebnisse"
            },
            {
              number: "3",
              url: "/de/flight/book",
              apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/book/NjY3YWU0ZTgxNzM3ZA==",
              urlParams: "NjY3YWU0ZTgxNzM3ZA==",
              state: "previous",
              label: "Buchung"
            },
            {
              number: "4",
              state: "current",
              label: "Zahlung"
            },
            {
              number: "5",
              state: "next",
              label: "Buchungsbestätigung"
            }
          ]
        },
        flightDetails: {
          outbound: {
            duration: "01h 55m",
            stops: 0,
            baggage: 0,
            segments: [
              {
                departure: {
                  code: "BEG",
                  date: "2024-07-09",
                  time: "18:00",
                  terminal: "2"
                },
                arrival: {
                  code: "ZRH",
                  date: "2024-07-09",
                  time: "19:55",
                  terminal: ""
                },
                marketingAirline: "JU",
                operatingAirline: "JU",
                flightNumber: "334",
                equipment: "E95",
                duration: "01h 55m",
                bookingClass: "O"
              }
            ]
          },
          inbound: {
            duration: "01h 40m",
            stops: 0,
            baggage: 0,
            segments: [
              {
                departure: {
                  code: "ZRH",
                  date: "2024-07-16",
                  time: "09:30",
                  terminal: ""
                },
                arrival: {
                  code: "BEG",
                  date: "2024-07-16",
                  time: "11:10",
                  terminal: "2"
                },
                marketingAirline: "JU",
                operatingAirline: "JU",
                flightNumber: "331",
                equipment: "E90",
                duration: "01h 40m",
                bookingClass: "L"
              }
            ]
          }
        },
        baggageAllowance: {
          outbound: [
            {
              departure: "BEG",
              arrival: "ZRH",
              travelers: {
                ADT: {
                  checked: {
                    id: "0kg",
                    image: "https://putnik-backend.ibe-servers.com/assets/images/general/suitcase_disabled.svg"
                  },
                  carryOn: {
                    id: "1x8kg",
                    image: "https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg"
                  }
                }
              }
            }
          ],
          inbound: [
            {
              departure: "ZRH",
              arrival: "BEG",
              travelers: {
                ADT: {
                  checked: {
                    id: "0kg",
                    image: "https://putnik-backend.ibe-servers.com/assets/images/general/suitcase_disabled.svg"
                  },
                  carryOn: {
                    id: "1x8kg",
                    image: "https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg"
                  }
                }
              }
            }
          ]
        },
        sidebar: {
          flight: {
            outbound: {
              icon: "https://putnik-backend.ibe-servers.com/assets/images/general/airplane.svg",
              departure: {
                code: "BEG",
                date: "2024-07-09",
                time: "18:00",
                terminal: "2"
              },
              arrival: {
                code: "ZRH",
                date: "2024-07-09",
                time: "19:55",
                terminal: ""
              }
            },
            inbound: {
              icon: "https://putnik-backend.ibe-servers.com/assets/images/general/airplane_left.svg",
              departure: {
                code: "ZRH",
                date: "2024-07-16",
                time: "09:30",
                terminal: ""
              },
              arrival: {
                code: "BEG",
                date: "2024-07-16",
                time: "11:10",
                terminal: "2"
              }
            }
          },
          priceClasses: {
            offerId: 1,
            display: "outbound",
            outbound: {
              name: "ECONOMY LIGHT",
              items: [
                {
                  label: "Handgepäck",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
                },
                {
                  label: "Geprüftes Gepäck",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg"
                },
                {
                  label: "Ticketwechsel",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
                },
                {
                  label: "Erstattung des Ticketpreises",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
                }
              ]
            },
            inbound: {
              name: "ECONOMY LIGHT",
              items: [
                {
                  label: "Handgepäck",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
                },
                {
                  label: "Geprüftes Gepäck",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg"
                },
                {
                  label: "Ticketwechsel",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
                },
                {
                  label: "Erstattung des Ticketpreises",
                  image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
                }
              ]
            }
          },
          priceInfo: {
            travelers: [
              {
                type: "ADT",
                quantity: "1",
                amount: "187,45",
                total: "187,45",
                currency: "CHF",
                currencyPosition: "left"
              }
            ],
            totalPrice: {
              cc: {
                amount: "187,45",
                serviceFee: null,
                currency: "CHF",
                currencyPosition: "left"
              },
              inv: {
                amount: "187,45",
                serviceFee: null,
                currency: "CHF",
                currencyPosition: "left"
              },
              swb: {
                amount: "196,82",
                serviceFee: "9,37",
                currency: "CHF",
                currencyPosition: "left"
              }
            }
          }
        },
        form: {
          apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/create-pnr",
          method: "POST",
          payment: {
            types: [
              "cc",
              "inv",
              "swb"
            ],
            cc: {
              fields: {
                cardHolder: {
                  regex: "^[a-zA-Z ]+",
                  regexError: "cardHolder",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
                },
                number: {
                  VI: "^4[0-9]{12}(?:[0-9]{3})?$",
                  MC: "^5[1-5][0-9]{14}$",
                  AX: "^3[47][0-9]{13}$",
                  regexError: "cardNumberError",
                  allowedCharacterSet: " 0123456789"
                },
                expireDate: {
                  minDate: "0724",
                  maxDate: "0634",
                  regexError: "expireDate"
                },
                cvv: {
                  regex: {
                    VI: "^[0-9]{3}$",
                    MC: "^[0-9]{3}$",
                    AX: "^[0-9]{4}$"
                  },
                  regexError: "cvv",
                  allowedCharacterSet: "0123456789"
                }
              },
              image: {
                VI: "https://putnik-backend.ibe-servers.com/assets/images/general/visa.svg",
                MC: "https://putnik-backend.ibe-servers.com/assets/images/general/mastercard.svg",
                AX: "https://putnik-backend.ibe-servers.com/assets/images/general/amex.svg"
              }
            }
          },
          consent: {
            consentPassport: "^[01]$",
            consentVisa: "^[01]$",
            consentTc: "^[01]$"
          },
          presetData: {}
        },
        airports: {
          BEG: {
            code: "BEG",
            city: "Belgrad",
            country: "Serbien",
            airport: "Nikola Tesla"
          },
          ZRH: {
            code: "ZRH",
            city: "Zürich",
            country: "Schweiz",
            airport: "Zurich Airport"
          }
        },
        airlines: {
          JU: {
            name: "Air Serbia",
            image: "https://putnik-backend.ibe-servers.com/assets/images/general/airlines/JU.png"
          }
        },
        pageLabels: {
          headline: "Zahlung",
          flightInfo: "Flugdaten",
          flightDuration: "Flugdauer",
          flightDurationTotal: "Gesamtflugdauer",
          flightDetails: "Flugdaten",
          baggage: "Gepäck",
          baggageAllowance: {
            checked: {
              "0kg": "Aufgegebenes Gepäck ist nicht im Preis inbegriffen"
            },
            carryOn: {
              "1x8kg": "Handgepäck: 1x8kg"
            }
          },
          sidebar: {
            headline: "Dein Flug",
            outbound: "Hinflug",
            inbound: "Rückflug",
            priceClasses: "Preisklasse",
            travelers: "Reisende",
            ADT: "Erwachsenen",
            CHD: "Kinder",
            INF: "Babys",
            totalPrice: "Gesamt",
            serviceFee: "Servicegebühr"
          },
          form: {
            payment: {
              headline: "Zahlungsarten",
              cc: {
                title: "Kreditkarte",
                cardHolder: "Kartenhalter",
                number: "Kartennummer",
                expireDate: "Ablaufdatum",
                cvv: "CVV"
              },
              inv: {
                title: "Bankkonto",
                headline: "Nach erfolgreicher Reservierung erhalten Sie eine E-Mail mit Zahlungsanweisungen.",
                subtitle: "Sie können die Zahlung von jedem Land der Welt aus durchführen."
              },
              swb: {
                title: "Zahlungsaufschub",
                image: "https://putnik-backend.ibe-servers.com/assets/images/swb_logo.png",
                text1: "Swissbilling SA is a service provider, that allows you to receive your order with an invoice in a simple and secure way.\n The Invoice will be sent from swissbilling separately from your order, per email or regular mail, depending on your choice.\n swissbilling’s services are based on a fix fee and your maximum order for the first purchase is CHF 2500.00.\n The invoice is payable within 30 days. For security matters, shipping address and billing address must be identical.\n Swissbilling keeps the right to have a credit check control done through it’s partner CRIF AG in Zurich (Tel.: 0848 333 222, www.crif.ch).",
                subtitle: "ACHTUNG!",
                text2: "Mit der Bestätigung ist der Kauf Ihrer Tickets endgültig. Bitte überprüfen Sie vor der Bestätigung noch ein mal Ihre Daten.",
                link: {
                  label: "Swissbilling - AGB",
                  url: "https://putnik-backend.ibe-servers.com/assets/documents/Swissbilling_AGB.pdf"
                }
              },
              regexError: {
                cardHolder: "Kann nur Buchstaben enthalten.",
                cardNumber: "Ungültige Kartennummer.",
                expireDate: "Ungültiges Datum.",
                cvv: "Ungültiges.",
                required: "Das Feld ist obligatorisch.",
                requiredShort: "Obligatorisch."
              }
            },
            consents: {
              consentPassport: "Ich bin damit einverstanden, dass alle Reisenden über gültige Reisedokumente verfügen und diese ab dem Rückreisedatum noch mindestens 6 Monate gültig sind.",
              consentVisa: "Ich stimme zu, dass alle Reisenden mit den Visabestimmungen des Landes, durch das und in das sie reisen, vertraut sind.",
              consentTc: "Ich stimme den Allgemeinen Reisebedingungen zu."
            },
            button: "Bestätige Zahlung"
          },
          outbound: "Hinflug",
          inbound: "Rückflug",
          stopIn: "Stopp",
          ADT: "Erwachsenen",
          CHD: "Kinder",
          INF: "Babys",
          timer: {
            headline: "Verbleibende Zeit, um ein Flugticket zu buchen",
            dialog: {
              headline: "Die Zeit für die Buchung eines Flugtickets ist abgelaufen.",
              subtitle: "",
              buttons: {
                yes: "Wiederholen Sie die Suche",
                no: "Stornieren"
              }
            }
          }
        }
      },
      loader: {
        headline: "Die Seite wird geladen, bitte warten.",
        flightData: {
          departure: "Belgrad",
          image: {
            url: "https://putnik-backend.ibe-servers.com/assets/images/general/arrows.svg"
          },
          arrival: "Zürich",
          departureDate: "2024-07-09",
          returnDate: "2024-07-16",
          travelers: {
            label: "reisender",
            qty: 1
          }
        },
        subtitles: [
          "Die Überprüfung der eingegebenen Daten wird durchgeführt.",
          "Ihre Buchung wird bearbeitet."
        ]
      },
      footer: {
        menuLeft: [
          {
            label: "GESCHÄFTSBEDINGUNGEN",
            list: [
              {
                label: "Allgemeine Reisebedingungen",
                url: "/de/info/opsti-uslov-putovanja",
                apiUrl: "https://putnik-backend.ibe-servers.com/de/info/opsti-uslov-putovanja",
                method: "GET"
              },
              {
                label: "Zahlungsarten",
                url: "/de/info/nacini-placanja",
                apiUrl: "https://putnik-backend.ibe-servers.com/de/info/nacini-placanja",
                method: "GET"
              },
              {
                label: "Datenschutzrichtlinie",
                url: "/de/info/uputstvo-za-uplatu",
                apiUrl: "https://putnik-backend.ibe-servers.com/de/info/politika-privatnosti",
                method: "GET"
              }
            ]
          }
        ],
        menuRight: {
          topMenu: {
            label: "ABONNIEREN SIE UNSEREN NEWSLETTER UND ERFAHREN SIE ALS ERSTER DIE BESTEN ANGEBOTE",
            form: {
              apiUrl: "https://putnik-backend.ibe-servers.com/de/subscribe",
              method: "POST",
              fields: {
                email: {
                  regex: "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$",
                  regexError: "email",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\\-+_@"
                }
              }
            }
          },
          bottomMenu: {
            label: "FOLGEN SIE UNS",
            icons: [
              {
                icon: "https://putnik-backend.ibe-servers.com/assets/images/fb.svg",
                url: "https://www.facebook.com/Putnik.ch"
              },
              {
                icon: "https://putnik-backend.ibe-servers.com/assets/images/in.svg",
                url: "https://www.linkedin.com/company/putnik-travel"
              },
              {
                icon: "https://putnik-backend.ibe-servers.com/assets/images/twitter.svg",
                url: "https://twitter.com/PutnikTravelCH"
              }
            ]
          }
        },
        copyright: {
          label: "&copy; Copyright Travel Gate AG 2024"
        },
        pageLabels: {
          form: {
            regexError: {
              email: "Falsche Email Adresse.",
              required: "Das Feld ist obligatorisch."
            },
            email: "Email-Adresse",
            button: "Abonnieren"
          }
        }
      }
    }
  },
  defaultBookData: {
    config: {
      baseUrl: "putnik-backend.ibe-servers.com",
      activeLanguage: "de",
      session: {
        value: "66581ebbcdb9c",
        ttl: 900,
        timerWarningAt: 300
      },
      url: "/de/flight/book/",
      apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/book",
      bookParamsEncoded: "NjY1ODFlYmJjZGI5Yw==",
      method: "GET",
      searchParams: {
        departure: {
          code: "BEG",
          label: "Belgrad, Nikola Tesla (BEG)",
          city: "Belgrad"
        },
        departureDate: "2024-06-05",
        arrival: {
          code: "ZRH",
          label: "Zürich, Zurich Airport (ZRH)",
          city: "Zürich"
        },
        returnDate: "2024-06-12",
        travelers: {
          ADT: "1"
        },
        cabin: "Y"
      }
    },
    header: {
      logo: {
        image: "https://putnik-backend.ibe-servers.com/assets/images/logo.svg",
        alt: "putnik-backend.ibe-servers.com"
      },
      favicon: {
        image: "https://putnik-backend.ibe-servers.com/assets/images/favicon.ico"
      },
      title: "Putnik Travel",
      menu: [
        {
          name: "home",
          label: "Home",
          url: "/de",
          apiUrl: "https://putnik-backend.ibe-servers.com/de",
          method: "GET"
        },
        {
          name: "contact",
          label: "Kontakt",
          url: "/de/contact",
          apiUrl: "https://putnik-backend.ibe-servers.com/de/contact",
          method: "GET"
        }
      ],
      languageMenu: [
        {
          label: "SR",
          url: "/sr/flight/book/NjY1ODFlYmJjZGI5Yw==",
          apiUrl: "https://putnik-backend.ibe-servers.com/sr/flight/book/NjY1ODFlYmJjZGI5Yw==",
          method: "GET",
          selected: false
        },
        {
          label: "DE",
          url: "/de/flight/book/NjY1ODFlYmJjZGI5Yw==",
          apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/book/NjY1ODFlYmJjZGI5Yw==",
          method: "GET",
          selected: true
        },
        {
          label: "EN",
          url: "/en/flight/book/NjY1ODFlYmJjZGI5Yw==",
          apiUrl: "https://putnik-backend.ibe-servers.com/en/flight/book/NjY1ODFlYmJjZGI5Yw==",
          method: "GET",
          selected: false
        }
      ]
    },
    content: {
      breadcrumbNavigation: {
        icon: "https://putnik-backend.ibe-servers.com/assets/images/general/angle-right.svg",
        home: {
          number: "1",
          url: "/de",
          state: "previous",
          icon: "https://putnik-backend.ibe-servers.com/assets/images/general/home.svg",
          label: "Home"
        },
        items: [
          {
            number: "2",
            url: "/de/flight/search/WS9CRUcyMDI0LTA2LTA1L1pSSDIwMjQtMDYtMTIvMTA=",
            apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/search/WS9CRUcyMDI0LTA2LTA1L1pSSDIwMjQtMDYtMTIvMTA=",
            urlParams: "WS9CRUcyMDI0LTA2LTA1L1pSSDIwMjQtMDYtMTIvMTA=",
            state: "previous",
            label: "Suchergebnisse"
          },
          {
            number: "3",
            state: "current",
            label: "Buchung"
          },
          {
            number: "4",
            state: "next",
            label: "Zahlung"
          },
          {
            number: "5",
            state: "next",
            label: "Buchungsbestätigung"
          }
        ]
      },
      priceClasses: [
        {
          offerId: 1,
          display: "outbound",
          outbound: {
            name: "ECONOMY LIGHT",
            items: [
              {
                label: "Handgepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
              },
              {
                label: "Geprüftes Gepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg"
              },
              {
                label: "Ticketwechsel",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
              },
              {
                label: "Erstattung des Ticketpreises",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
              }
            ]
          },
          inbound: {
            name: "ECONOMY LIGHT",
            items: [
              {
                label: "Handgepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
              },
              {
                label: "Geprüftes Gepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg"
              },
              {
                label: "Ticketwechsel",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
              },
              {
                label: "Erstattung des Ticketpreises",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
              }
            ]
          }
        },
        {
          offerId: 2,
          display: "outbound",
          outbound: {
            name: "ECONOMY STANDARD",
            items: [
              {
                label: "Handgepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
              },
              {
                label: "Geprüftes Gepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
              },
              {
                label: "Ticketwechsel",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg"
              },
              {
                label: "Erstattung des Ticketpreises",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
              }
            ]
          },
          inbound: {
            name: "ECONOMY STANDARD",
            items: [
              {
                label: "Handgepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
              },
              {
                label: "Geprüftes Gepäck",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
              },
              {
                label: "Ticketwechsel",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg"
              },
              {
                label: "Erstattung des Ticketpreises",
                image: "https://putnik-backend.ibe-servers.com/assets/images/general/x.svg"
              }
            ]
          }
        }
      ],
      airports: {
        BEG: {
          code: "BEG",
          city: "Belgrad",
          country: "Serbien",
          airport: "Nikola Tesla"
        },
        ZRH: {
          code: "ZRH",
          city: "Zürich",
          country: "Schweiz",
          airport: "Zurich Airport"
        }
      },
      airlines: {
        JU: {
          name: "Air Serbia",
          image: "https://putnik-backend.ibe-servers.com/assets/images/general/airlines/JU.png"
        }
      },
      offers: [
        {
          offerId: 1,
          selected: true,
          priceChange: {
            isChanged: false
          },
          flightDetails: {
            flight: {
              outbound: {
                duration: "01h 55m",
                stops: 0,
                baggage: 0,
                segments: [
                  {
                    departure: {
                      code: "BEG",
                      date: "2024-06-05",
                      time: "18:00",
                      terminal: "2"
                    },
                    arrival: {
                      code: "ZRH",
                      date: "2024-06-05",
                      time: "19:55",
                      terminal: ""
                    },
                    marketingAirline: "JU",
                    operatingAirline: "JU",
                    flightNumber: "334",
                    equipment: "320",
                    duration: "01h 55m",
                    bookingClass: "O"
                  }
                ]
              },
              inbound: {
                duration: "01h 40m",
                stops: 0,
                baggage: 0,
                segments: [
                  {
                    departure: {
                      code: "ZRH",
                      date: "2024-06-12",
                      time: "09:30",
                      terminal: ""
                    },
                    arrival: {
                      code: "BEG",
                      date: "2024-06-12",
                      time: "11:10",
                      terminal: "2"
                    },
                    marketingAirline: "JU",
                    operatingAirline: "JU",
                    flightNumber: "331",
                    equipment: "E95",
                    duration: "01h 40m",
                    bookingClass: "R"
                  }
                ]
              }
            }
          },
          baggageAllowance: {
            outbound: [
              {
                departure: "BEG",
                arrival: "ZRH",
                travelers: {
                  ADT: {
                    checked: {
                      id: "0kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/suitcase_disabled.svg"
                    },
                    carryOn: {
                      id: "1x8kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg"
                    }
                  }
                }
              }
            ],
            inbound: [
              {
                departure: "ZRH",
                arrival: "BEG",
                travelers: {
                  ADT: {
                    checked: {
                      id: "0kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/suitcase_disabled.svg"
                    },
                    carryOn: {
                      id: "1x8kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg"
                    }
                  }
                }
              }
            ]
          },
          sidebar: {
            flight: {
              outbound: {
                icon: "https://putnik-backend.ibe-servers.com/assets/images/general/airplane.svg",
                departure: {
                  code: "BEG",
                  date: "2024-06-05",
                  time: "18:00",
                  terminal: "2"
                },
                arrival: {
                  code: "ZRH",
                  date: "2024-06-05",
                  time: "19:55",
                  terminal: ""
                }
              },
              inbound: {
                icon: "https://putnik-backend.ibe-servers.com/assets/images/general/airplane_left.svg",
                departure: {
                  code: "ZRH",
                  date: "2024-06-12",
                  time: "09:30",
                  terminal: ""
                },
                arrival: {
                  code: "BEG",
                  date: "2024-06-12",
                  time: "11:10",
                  terminal: "2"
                }
              }
            },
            priceClasses: [
              "ECONOMYLIGHT",
              "ECONOMYLIGHT"
            ],
            priceInfo: {
              travelers: [
                {
                  type: "ADT",
                  quantity: "1",
                  amount: "126,85",
                  total: "126,85",
                  currency: "CHF",
                  currencyPosition: "left"
                }
              ],
              totalPrice: {
                label: "Total",
                amount: "126,85",
                currency: "CHF",
                currencyPosition: "left"
              }
            }
          },
          form: {
            apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/book",
            method: "POST",
            traveler: {
              fields: {
                travelers: {
                  ADT: "1"
                },
                names: {
                  firstName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]{2,}",
                    regexError: "firstName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  },
                  lastName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]{2,}",
                    regexError: "lastName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  },
                  firstNameLastName: {
                    regex: "^(?=.{1,46}$)([A-Za-zšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+ [A-Za-zšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+)",
                    regexError: "firstNameLastName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  }
                },
                genders: [
                  {
                    label: "Männlich",
                    value: "M"
                  },
                  {
                    label: "Weiblich",
                    value: "F"
                  }
                ],
                birthdates: {
                  ADT: {
                    minDate: "1934-06-12",
                    maxDate: "2012-06-12"
                  }
                },
                errors: {
                  required: "Das Feld ist obligatorisch.",
                  ageOver: "Zu alt.",
                  ageUnder: "Zu jung.",
                  invalidDate: "Ungültiges Datum.",
                  charactersLimitExceeded: "Die maximale Zeichenanzahl wurde überschritten."
                },
                passport: null,
                presetTravelers: {}
              }
            },
            buyer: {
              fields: {
                companyName: {
                  regex: "(^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ0-9\\-. ]+)*",
                  regexError: "companyName",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                },
                companyVAT: {
                  regex: "^[A-Za-z0-9 ]*",
                  regexError: "companyVAT",
                  allowedCharacterSet: " 0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
                },
                genders: [
                  {
                    label: "Männlich",
                    value: "M"
                  },
                  {
                    label: "Weiblich",
                    value: "F"
                  }
                ],
                names: {
                  firstName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+",
                    regexError: "firstName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  },
                  lastName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+",
                    regexError: "lastName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  }
                },
                email: {
                  regex: "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$",
                  regexError: "email",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\\-+_@"
                },
                contactNumber: {
                  regex: "^[\\+]?[(]?[0-9 ]{3}[)]?[-\\s\\.]?[0-9 ]{3}[-\\s\\.]?[0-9 ]+",
                  regexError: "contactNumber",
                  allowedCharacterSet: " +0123456789"
                },
                streetAddress: {
                  regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ0-9\\s,-.]+",
                  regexError: "streetAddress",
                  allowedCharacterSet: " 0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ\\-,."
                },
                postalCode: {
                  regex: "^[0-9]{5}(?:-[0-9]{4})?$",
                  regexError: "postalCode",
                  allowedCharacterSet: "0123456789"
                },
                city: {
                  regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ\\-. ]+",
                  regexError: "city",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ\\-."
                },
                country: {
                  apiUrl: "https://putnik-backend.ibe-servers.com/de/countries",
                  selected: "RS"
                },
                presetBuyer: {}
              }
            }
          }
        },
        {
          offerId: 2,
          selected: false,
          priceChange: {
            isChanged: false
          },
          flightDetails: {
            flight: {
              outbound: {
                duration: "01h 55m",
                stops: 0,
                baggage: 0,
                segments: [
                  {
                    departure: {
                      code: "BEG",
                      date: "2024-06-05",
                      time: "18:00",
                      terminal: "2"
                    },
                    arrival: {
                      code: "ZRH",
                      date: "2024-06-05",
                      time: "19:55",
                      terminal: ""
                    },
                    marketingAirline: "JU",
                    operatingAirline: "JU",
                    flightNumber: "334",
                    equipment: "320",
                    duration: "01h 55m",
                    bookingClass: "O"
                  }
                ]
              },
              inbound: {
                duration: "01h 40m",
                stops: 0,
                baggage: 0,
                segments: [
                  {
                    departure: {
                      code: "ZRH",
                      date: "2024-06-12",
                      time: "09:30",
                      terminal: ""
                    },
                    arrival: {
                      code: "BEG",
                      date: "2024-06-12",
                      time: "11:10",
                      terminal: "2"
                    },
                    marketingAirline: "JU",
                    operatingAirline: "JU",
                    flightNumber: "331",
                    equipment: "E95",
                    duration: "01h 40m",
                    bookingClass: "O"
                  }
                ]
              }
            }
          },
          baggageAllowance: {
            outbound: [
              {
                departure: "BEG",
                arrival: "ZRH",
                travelers: {
                  ADT: {
                    checked: {
                      id: "1x23kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg"
                    },
                    carryOn: {
                      id: "1x8kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg"
                    }
                  }
                }
              }
            ],
            inbound: [
              {
                departure: "ZRH",
                arrival: "BEG",
                travelers: {
                  ADT: {
                    checked: {
                      id: "1x23kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg"
                    },
                    carryOn: {
                      id: "1x8kg",
                      image: "https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg"
                    }
                  }
                }
              }
            ]
          },
          sidebar: {
            flight: {
              outbound: {
                icon: "https://putnik-backend.ibe-servers.com/assets/images/general/airplane.svg",
                departure: {
                  code: "BEG",
                  date: "2024-06-05",
                  time: "18:00",
                  terminal: "2"
                },
                arrival: {
                  code: "ZRH",
                  date: "2024-06-05",
                  time: "19:55",
                  terminal: ""
                }
              },
              inbound: {
                icon: "https://putnik-backend.ibe-servers.com/assets/images/general/airplane_left.svg",
                departure: {
                  code: "ZRH",
                  date: "2024-06-12",
                  time: "09:30",
                  terminal: ""
                },
                arrival: {
                  code: "BEG",
                  date: "2024-06-12",
                  time: "11:10",
                  terminal: "2"
                }
              }
            },
            priceClasses: [
              "ECONOMYSTANDARD",
              "ECONOMYSTANDARD"
            ],
            priceInfo: {
              travelers: [
                {
                  type: "ADT",
                  quantity: "1",
                  amount: "185,85",
                  total: "185,85",
                  currency: "CHF",
                  currencyPosition: "left"
                }
              ],
              totalPrice: {
                label: "Total",
                amount: "185,85",
                currency: "CHF",
                currencyPosition: "left"
              }
            }
          },
          form: {
            apiUrl: "https://putnik-backend.ibe-servers.com/de/flight/book",
            method: "POST",
            traveler: {
              fields: {
                travelers: {
                  ADT: "1"
                },
                names: {
                  firstName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]{2,}",
                    regexError: "firstName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  },
                  lastName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]{2,}",
                    regexError: "lastName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  },
                  firstNameLastName: {
                    regex: "^(?=.{1,46}$)([A-Za-zšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+ [A-Za-zšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+)",
                    regexError: "firstNameLastName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  }
                },
                genders: [
                  {
                    label: "Männlich",
                    value: "M"
                  },
                  {
                    label: "Weiblich",
                    value: "F"
                  }
                ],
                birthdates: {
                  ADT: {
                    minDate: "1934-06-12",
                    maxDate: "2012-06-12"
                  }
                },
                errors: {
                  required: "Das Feld ist obligatorisch.",
                  ageOver: "Zu alt.",
                  ageUnder: "Zu jung.",
                  invalidDate: "Ungültiges Datum.",
                  charactersLimitExceeded: "Die maximale Zeichenanzahl wurde überschritten."
                },
                passport: null,
                presetTravelers: {}
              }
            },
            buyer: {
              fields: {
                companyName: {
                  regex: "(^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ0-9\\-. ]+)*",
                  regexError: "companyName",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                },
                companyVAT: {
                  regex: "^[A-Za-z0-9 ]*",
                  regexError: "companyVAT",
                  allowedCharacterSet: " 0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
                },
                genders: [
                  {
                    label: "Männlich",
                    value: "M"
                  },
                  {
                    label: "Weiblich",
                    value: "F"
                  }
                ],
                names: {
                  firstName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+",
                    regexError: "firstName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  },
                  lastName: {
                    regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ ]+",
                    regexError: "lastName",
                    allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ"
                  }
                },
                email: {
                  regex: "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$",
                  regexError: "email",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\\-+_@"
                },
                contactNumber: {
                  regex: "^[\\+]?[(]?[0-9 ]{3}[)]?[-\\s\\.]?[0-9 ]{3}[-\\s\\.]?[0-9 ]+",
                  regexError: "contactNumber",
                  allowedCharacterSet: " +0123456789"
                },
                streetAddress: {
                  regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ0-9\\s,-.]+",
                  regexError: "streetAddress",
                  allowedCharacterSet: " 0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ\\-,."
                },
                postalCode: {
                  regex: "^[0-9]{5}(?:-[0-9]{4})?$",
                  regexError: "postalCode",
                  allowedCharacterSet: "0123456789"
                },
                city: {
                  regex: "^[a-zA-ZšđčćžŠĐČĆŽäöüßÄÖÜẞ\\-. ]+",
                  regexError: "city",
                  allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZšđčćžŠĐČĆŽäöüßÄÖÜẞ\\-."
                },
                country: {
                  apiUrl: "https://putnik-backend.ibe-servers.com/de/countries",
                  selected: "RS"
                },
                presetBuyer: {}
              }
            }
          }
        }
      ],
      baggage: [
        "https//putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg",
        "https//putnik-backend.ibe-servers.com/assets/images/general/suitcase_disabled.svg",
        "https//putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg"
      ],
      image: {
        arrows: "https://putnik-backend.ibe-servers.com/assets/images/general/arrows.svg",
        arrow: "https://putnik-backend.ibe-servers.com/assets/images/general/arrow.svg"
      },
      pageLabels: {
        headline: "Flugticketreservierung",
        flightInfo: "Flugdaten",
        flightDuration: "Flugdauer",
        flightDurationTotal: "Gesamtflugdauer",
        flightDetails: "Flugdaten",
        baggage: "Gepäck",
        sidebar: {
          headline: "Dein Flug",
          outbound: "Hinflug",
          inbound: "Rückflug",
          priceClasses: "Preisklasse",
          travelers: "Reisende",
          ADT: "Erwachsenen",
          CHD: "Kinder",
          INF: "Babys",
          totalPrice: "Gesamt"
        },
        priceClasses: {
          headline: "Verfügbare Preisklassen",
          subtitle: "Preisklasse auswählen"
        },
        baggageAllowance: {
          checked: {
            "0kg": "Aufgegebenes Gepäck ist nicht im Preis inbegriffen",
            "1x23kg": "Geprüftes Gepäck: 1x23kg"
          },
          carryOn: {
            "1x8kg": "Handgepäck: 1x8kg"
          }
        },
        form: {
          headline: "Reisende",
          firstName: "Vorname",
          lastName: "Nachname",
          gender: "Geschlecht",
          birthdate: "Geburtsdatum",
          ADT: "Erwachsene",
          CHD: "Kind",
          INF: "Kinder",
          passport: {
            number: "Ausweisnummer",
            passportCountry: "Ausstellungsland des Passports",
            citizenship: "Staatsbürgerschaft",
            expireDate: "Ablaufdatum des Reisepasses"
          },
          payment: {
            headline: "Gesamt",
            button: "Gehen Sie zur Zahlungsseite"
          },
          regexError: {
            firstName: "Kann nur Buchstaben enthalten, mindestens 2",
            lastName: "Kann nur Buchstaben enthalten, mindestens 2",
            firstNameLastName: "Der Vor- und Nachname darf maximal 46 Zeichen enthalten",
            passportNumber: "Darf Buchstaben und Zahlen enthalten, maximal 9 Zeichen"
          },
          buyer: {
            headline: "Käufer",
            natural: "Privatkunden",
            legal: "Firmenkunden",
            companyName: "Unternehmen",
            companyVAT: "UID-Nummer",
            gender: "Geschlecht",
            firstName: "Vorname",
            lastName: "Nachname",
            email: "Email",
            contactNumber: "Telefonnummer",
            streetAddress: "Adresse",
            postalCode: "Postleitzahl",
            city: "Stadt",
            country: "Land",
            note: "Bemerkung",
            regexError: {
              companyName: "Darf nur Buchstaben, Zahlen \".\" und \"-\".",
              companyVAT: "Darf nur Buchstaben, Zahlen und \"-\".",
              firstName: "Kann nur Buchstaben enthalten.",
              lastName: "Kann nur Buchstaben enthalten.",
              email: "Falsche Email Adresse.",
              contactNumber: "Falsche Telefonnummer.",
              streetAddress: "Falsches Adressformat.",
              postalCode: "Falsches Postleitzahlenformat.",
              city: "Falsches Stadtformat.",
              required: "Das Feld ist obligatorisch"
            }
          }
        },
        outbound: "Hinflug",
        inbound: "Rückflug",
        stopIn: "Stopp",
        timer: {
          headline: "Verbleibende Zeit, um ein Flugticket zu buchen",
          dialog: {
            headline: "Die Zeit für die Buchung eines Flugtickets ist abgelaufen.",
            subtitle: "",
            buttons: {
              yes: "Wiederholen Sie die Suche",
              no: "Stornieren"
            }
          }
        },
        priceChanged: {
          dialog: {
            headline: {
              pricier: "Der Preis für das Flugticket ist gestiegen",
              cheaper: "Das Flugticket ist günstiger geworden"
            },
            old: "Vorherige Gesamtsumme:",
            new: "Neuer Gesamtpreis:"
          },
          buttons: {
            yes: "Weitermachen",
            no: "Stornieren",
            noUrl: null
          }
        }
      }
    },
    loader: {
      headline: "Die Seite wird geladen, bitte warten.",
      flightData: {
        departure: "Belgrad",
        image: {
          url: "https://putnik-backend.ibe-servers.com/assets/images/general/arrows.svg"
        },
        arrival: "Zürich",
        departureDate: "2024-06-05",
        returnDate: "2024-06-12",
        travelers: {
          label: "reisender",
          qty: 1
        }
      },
      subtitles: [
        "Dieeingegebenen Daten werden verarbeitet.",
        "DieZahlungsseite wird vorbereitet."
      ]
    },
    footer: {
      menuLeft: [
        {
          label: "GESCHÄFTSBEDINGUNGEN",
          list: [
            {
              label: "Allgemeine Reisebedingungen",
              url: "/de/info/opsti-uslov-putovanja",
              apiUrl: "https://putnik-backend.ibe-servers.com/de/info/opsti-uslov-putovanja",
              method: "GET"
            },
            {
              label: "Zahlungsarten",
              url: "/de/info/nacini-placanja",
              apiUrl: "https://putnik-backend.ibe-servers.com/de/info/nacini-placanja",
              method: "GET"
            },
            {
              label: "Datenschutzrichtlinie",
              url: "/de/info/uputstvo-za-uplatu",
              apiUrl: "https://putnik-backend.ibe-servers.com/de/info/politika-privatnosti",
              method: "GET"
            }
          ]
        }
      ],
      menuRight: {
        topMenu: {
          label: "ABONNIEREN SIE UNSEREN NEWSLETTER UND ERFAHREN SIE ALS ERSTER DIE BESTEN ANGEBOTE",
          form: {
            apiUrl: "https://putnik-backend.ibe-servers.com/de/subscribe",
            method: "POST",
            fields: {
              email: {
                regex: "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$",
                regexError: "email",
                allowedCharacterSet: " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.\\-+_@"
              }
            }
          }
        },
        bottomMenu: {
          label: "FOLGEN SIE UNS",
          icons: [
            {
              icon: "https://putnik-backend.ibe-servers.com/assets/images/fb.svg",
              url: "https://www.facebook.com/Putnik.ch"
            },
            {
              icon: "https://putnik-backend.ibe-servers.com/assets/images/in.svg",
              url: "https://www.linkedin.com/company/putnik-travel"
            },
            {
              icon: "https://putnik-backend.ibe-servers.com/assets/images/twitter.svg",
              url: "https://twitter.com/PutnikTravelCH"
            }
          ]
        }
      },
      copyright: {
        label: "&copy; Copyright Travel Gate AG 2024"
      },
      pageLabels: {
        form: {
          regexError: {
            email: "Falsche Email Adresse.",
            required: "Das Feld ist obligatorisch."
          },
          email: "Email-Adresse",
          button: "Abonnieren"
        }
      }
    }
  },
  defaultPnrData: {
    dummy: true,
    config: {
      baseUrl: process.env.VUE_APP_BASE_URL,
      activeLanguage: 'sr',
      url: '/sr/flight/create-pnr',
      apiUrl: 'https://putnik-backend.ibe-servers.com/sr/flight/create-pnr',
      pnrParamsEncoded: 'NjNlYjRiYzJhNTA3Zg==',
      method: 'GET'
    },
    header: {
      logo: {
        image: '',
        alt: process.env
      },
      menu: [
        {
          name: 'home',
          label: 'Home',
          url: '/sr',
          apiUrl: 'https://putnik-backend.ibe-servers.com/sr',
          method: 'GET'
        },
        {
          name: 'contact',
          label: 'Kontakt',
          url: '/sr/contact',
          apiUrl: 'https://putnik-backend.ibe-servers.com/sr/contact',
          method: 'GET'
        }
      ],
      languageMenu: [
        {
          label: 'SR',
          url: '/sr/flight/create-pnr/NjNlYjRiYzJhNTA3Zg==',
          apiUrl: 'https://putnik-backend.ibe-servers.com/sr/flight/create-pnr/NjNlYjRiYzJhNTA3Zg==',
          method: 'GET',
          selected: true
        },
        {
          label: 'DE',
          url: '/de/flight/create-pnr/NjNlYjRiYzJhNTA3Zg==',
          apiUrl: 'https://putnik-backend.ibe-servers.com/de/flight/create-pnr/NjNlYjRiYzJhNTA3Zg==',
          method: 'GET',
          selected: false
        },
        {
          label: 'EN',
          url: '/en/flight/create-pnr/NjNlYjRiYzJhNTA3Zg==',
          apiUrl: 'https://putnik-backend.ibe-servers.com/en/flight/create-pnr/NjNlYjRiYzJhNTA3Zg==',
          method: 'GET',
          selected: false
        }
      ]
    },
    content: {
      breadcrumbNavigation: {
        icon: 'https://putnik-backend.ibe-servers.com/assets/images/general/angle-right.svg',
        home: {
          number: '1',
          url: '/sr',
          state: 'previous',
          icon: 'https://putnik-backend.ibe-servers.com/assets/images/general/home.svg',
          label: 'Home'
        },
        items: [
          {
            number: '2',
            state: 'next',
            label: 'Ponude'
          },
          {
            number: '3',
            state: 'next',
            label: 'Rezervacija'
          },
          {
            number: '4',
            state: 'next',
            label: 'Plaćanje'
          },
          {
            number: '5',
            state: 'current',
            label: 'Potvrda'
          }
        ]
      },
      pnr: {
        image: 'https://putnik-backend.ibe-servers.com/assets/images/general/checkmark_square.svg',
        controlNumber: 'PRG5YP',
        dateTime: '2023-02-14 09:54'
      },
      flightDetails: {
        outbound: {
          duration: '15h 30m',
          stops: '1',
          baggage: '1',
          segments: [
            {
              departure: {
                code: 'BEG',
                date: '2023-02-20',
                time: '08:55',
                terminal: '2'
              },
              arrival: {
                code: 'IST',
                date: '2023-02-20',
                time: '12:40',
                terminal: ''
              },
              marketingAirline: 'TK',
              operatingAirline: 'TK',
              flightNumber: '1082',
              equipment: '73J',
              duration: '01h 45m',
              bookingClass: 'V'
            },
            {
              departure: {
                code: 'IST',
                date: '2023-02-20',
                time: '15:20',
                terminal: ''
              },
              arrival: {
                code: 'JFK',
                date: '2023-02-20',
                time: '18:25',
                terminal: '1'
              },
              marketingAirline: 'TK',
              operatingAirline: 'TK',
              flightNumber: '1',
              equipment: '77W',
              duration: '11h 05m',
              stopDuration: '02h 40m',
              bookingClass: 'V'
            }
          ]
        },
        inbound: {
          duration: '13h 50m',
          stops: '1',
          baggage: '1',
          segments: [
            {
              departure: {
                code: 'JFK',
                date: '2023-02-25',
                time: '23:45',
                terminal: '1'
              },
              arrival: {
                code: 'IST',
                date: '2023-02-26',
                time: '17:15',
                terminal: ''
              },
              marketingAirline: 'TK',
              operatingAirline: 'TK',
              flightNumber: '12',
              equipment: '359',
              duration: '09h 30m',
              bookingClass: 'V'
            },
            {
              departure: {
                code: 'IST',
                date: '2023-02-26',
                time: '19:50',
                terminal: ''
              },
              arrival: {
                code: 'BEG',
                date: '2023-02-26',
                time: '19:35',
                terminal: '2'
              },
              marketingAirline: 'TK',
              operatingAirline: 'TK',
              flightNumber: '1083',
              equipment: '321',
              duration: '01h 45m',
              stopDuration: '02h 35m',
              bookingClass: 'V'
            }
          ]
        }
      },
      travelers: {
        ADT: [
          {
            gender: 'M',
            firstName: 'Relja',
            lastName: 'Dordevic',
            birthdate: '1983-05-30',
            fare: {
              total: '69.510',
              currency: 'RSD',
              currencyPosition: 'right'
            },
            passport: {
              number: '12312312312',
              passportCountry: 'RS',
              citizenship: 'RS',
              expireDate: '2024-02-01'
            }
          },
          {
            gender: 'M',
            firstName: 'Petar',
            lastName: 'Petrovic',
            birthdate: '1983-06-20',
            fare: {
              total: '69.510',
              currency: 'RSD',
              currencyPosition: 'right'
            },
            passport: {
              number: '1V344H78',
              passportCountry: 'RS',
              citizenship: 'RS',
              expireDate: '2023-02-01'
            }
          }
        ],
        CHD: [
          {
            gender: 'M',
            firstName: 'Jovan',
            lastName: 'Jovanovic',
            birthdate: '2020-07-11',
            fare: {
              total: '66.572',
              currency: 'RSD',
              currencyPosition: 'right'
            },
            passport: {
              number: '098TR300',
              passportCountry: 'RS',
              citizenship: 'RS',
              expireDate: '2025-05-01'
            }
          }
        ],
        INF: [
          {
            gender: 'M',
            firstName: 'Mile',
            lastName: 'Milanovic',
            birthdate: '2023-01-05',
            fare: {
              total: '8.271',
              currency: 'RSD',
              currencyPosition: 'right'
            },
            passport: {
              number: 'C345B',
              passportCountry: 'RS',
              citizenship: 'RS',
              expireDate: '2024-10-05'
            }
          },
          {
            gender: 'F',
            firstName: 'Milena',
            lastName: 'Milanovic',
            birthdate: '2023-01-05',
            fare: {
              total: '8.271',
              currency: 'RSD',
              currencyPosition: 'right'
            },
            passport: {
              number: 'PO098744F',
              passportCountry: 'RS',
              citizenship: 'RS',
              expireDate: '2025-12-05'
            }
          }
        ]
      },
      totalPrice: {
        amount: '222.134',
        currency: 'RSD',
        currencyPosition: 'right'
      },
      documents: true,
      buyer: {
        companyName: '$COMPANY$ d.o.o',
        companyVAT: '999922234',
        gender: 'M',
        firstName: 'Relja',
        lastName: 'Đorđević',
        email: 'relja.djordjevic@webpro.rs',
        contactNumber: '+381631841377',
        streetAddress: 'Nebojse Djukelica 2',
        postalCode: '11030',
        city: 'Beograd',
        country: 'RS',
        note: 'Sa mnom putuje i pas!'
      },
      priceClasses: {
        offerId: 1,
        display: 'outbound',
        outbound: {
          name: 'PROMOTIONAL',
          items: [
            {
              label: 'Ručni prtljag',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg'
            },
            {
              label: 'Čekirani prtljag',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg'
            },
            {
              label: 'Promena karte',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg'
            },
            {
              label: 'Refundacija karte',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/x.svg'
            }
          ]
        },
        inbound: {
          name: 'PROMOTIONAL',
          items: [
            {
              label: 'Ručni prtljag',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg'
            },
            {
              label: 'Čekirani prtljag',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg'
            },
            {
              label: 'Promena karte',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/coins.svg'
            },
            {
              label: 'Refundacija karte',
              image: 'https://putnik-backend.ibe-servers.com/assets/images/general/x.svg'
            }
          ]
        }
      },
      baggageAllowance: {
        outbound: [
          {
            departure: 'BEG',
            arrival: 'IST',
            travelers: {
              ADT: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              CHD: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              INF: {
                checked: {
                  id: '1x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              }
            }
          },
          {
            departure: 'IST',
            arrival: 'JFK',
            travelers: {
              ADT: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              CHD: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              INF: {
                checked: {
                  id: '1x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              }
            }
          }
        ],
        inbound: [
          {
            departure: 'JFK',
            arrival: 'IST',
            travelers: {
              ADT: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              CHD: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              INF: {
                checked: {
                  id: '1x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              }
            }
          },
          {
            departure: 'IST',
            arrival: 'BEG',
            travelers: {
              ADT: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              CHD: {
                checked: {
                  id: '2x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              },
              INF: {
                checked: {
                  id: '1x23kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
                },
                carryOn: {
                  id: '1x8kg',
                  image: 'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg'
                }
              }
            }
          }
        ]
      },
      formOfPayment: {
        type: 'CC',
        cardInfo: {
          cardHolder: 'Relja Djordjevic',
          number: '************1111',
          expireDate: '05/23'
        }
      },
      airports: {
        BEG: {
          code: 'BEG',
          city: 'Beograd',
          country: 'Srbija',
          airport: 'Nikola Tesla'
        },
        IST: {
          code: 'IST',
          city: 'Istanbul',
          country: 'Turska',
          airport: 'Ataturk'
        },
        JFK: {
          code: 'JFK',
          city: 'New York',
          country: 'Amerika',
          airport: 'John F Kennedy'
        }
      },
      airlines: {
        TK: {
          name: 'Turkish Airlines',
          image: 'https://putnik-backend.ibe-servers.com/assets/images/general/airlines/TK.png'
        }
      },
      baggage: [
        'https://putnik-backend.ibe-servers.com/assets/images/general/hand_baggage.svg',
        'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase_disabled.svg',
        'https://putnik-backend.ibe-servers.com/assets/images/general/suitcase.svg'
      ],
      image: {
        checkMarkSquare: 'https://putnik-backend.ibe-servers.com/assets/images/general/checkmark_square.svg'
      },
      pageLabels: {
        headline: 'Potvrda rezervacije',
        pnr: {
          controlNumber: 'Broj rezervacije',
          dateTime: 'Datum i vreme rezervacije'
        },
        subtitles: [
          'Ubrzo ćete primiti e-mail o potvrdi rezervacije sa svim detaljima vašeg leta.',
          'Emailsa potvrdom rezervacije može da stigne i u vašu neželjenu poštu (spam). Molimo vas da proverite vašu neželjenu poštu.',
          'Ukolikoniste primili email slobodno nas kontaktirajte telefonom na broj 011 310 89 99 svakog dana od 08 do 20h.'
        ],
        flightDetails: {
          headline: 'Detalji leta',
          outbound: 'Polazak',
          inbound: 'Povratak',
          takingOff: 'Poletanje u',
          landing: 'sletanje u',
          stop: 'presedanje'
        },
        travelers: {
          headline: 'Putnici',
          subtitle: 'Obračun cene',
          ADT: 'Odrasli',
          CHD: 'Deca',
          INF: 'Bebe',
          totalPrice: 'Ukupno',
          documents: {
            headline: 'Dokumenta',
            subtitles: [
              'Detaljneinformacije o priloženim dokumentima se nalaze u potvrdnom email-u.'
            ]
          }
        },
        buyer: {
          headline: 'Kupac',
          natural: 'Fizičko lice',
          legal: 'Pravno lice',
          companyName: 'Naziv kompanije',
          companyVAT: 'PIB',
          gender: 'Pol',
          firstName: 'Ime',
          lastName: 'Prezime',
          email: 'Email',
          contactNumber: 'Kontakt telefon',
          streetAddress: 'Adresa',
          postalCode: 'ZIP',
          city: 'Grad',
          country: 'Država',
          note: 'Napomena',
          consentPassport: 'Saglasan sam da svi putnici imaju validne putne isprave i da važe još najmanje 6 meseci od datuma povratka.',
          consentVisa: 'Saglasan sam da su svi putnici upoznati sa viznim režimom zemlje kroz i u koju putuju.',
          consentTc: 'Saglasan sam sa opštim uslovima putovanja'
        },
        priceClasses: {
          headline: 'Tarifa'
        },
        baggageAllowance: {
          headline: 'Prtljag',
          checked: {
            '2x23kg': 'Čekirani prtljag: 2x23kg',
            '1x23kg': 'Čekirani prtljag: 1x23kg'
          },
          carryOn: {
            '1x8kg': 'Ručni prtljag: 1x8kg'
          }
        },
        formOfPayment: {
          headline: 'Način plaćanja',
          CA: {
            subtitle: 'Preko računa',
            info: [
              'Instrukcije za uplatu se nalaze u potvrdnom email-u.'
            ]
          },
          CC: {
            subtitle: 'Kreditna kartica',
            cardHolder: 'Vlasnik kartice',
            number: 'Broj kartice',
            expireDate: 'Datum isteka',
            info: ['']
          }
        },
        email: {
          confirmationMessage: 'Uspešno ste izvršili rezervaciju broj',
          gender: {
            M: {
              welcomeMessage: 'Poštovani gospodine'
            },
            F: {
              welcomeMessage: 'Poštovana gospođo'
            }
          },
          paragraphs: {
            group1: 'Posle evidentirane uplate ispostavićemo Vam avionsku kartu u elektronskom formatu sa kojom možete započeti Vaše putovanje.Ukoliko Vae putovanje počinje u narednih 48 sati molimo Vas da nas odmah kontaktirate telefonom na broj 043 544 7007 zbog bržeg izdavanja karte.',
            group2: 'VAŽNA NAPOMENA: Zbog pravila aviokompanija, postoji mogućnost da Vaša karta mora da bude izdata u roku koji je kraći od 24 časa od trenutka pravljenja rezervacije. ukoliko pravite rezervaciju u periodu od 18h - 08h, preporučujemo Vam da kontaktirate naš Call Centar nakon pravljenja rezervacije, svakog dana od 08h-18h. Imajući u vidu napred navedeno,',
            group3: '*CENA KARTE NE MOŽE BITI GARANTOVANA DO TRENUTKA IZDAVANJA'
          },
          dateTime: 'Vreme i datum',
          duration: 'Trajanje leta',
          flightNumber: 'Broj leta',
          time: 'Vreme',
          date: 'Datum',
          terminal: 'Terminal',
          stop: 'Presedanju u',
          travelers: {
            gender: {
              title: 'Pol',
              M: 'Muški',
              F: 'Ženski'
            },
            ADT: 'Odrastao',
            CHD: 'Dete',
            INF: 'Beba',
            firstName: 'Ime',
            lastName: 'Prezime',
            type: 'Tip',
            birthdate: 'Datum rođenja',
            price: 'Cena',
            documents: {
              passport: {
                firstName: 'Ime',
                lastName: 'Prezime',
                type: 'Pasoš',
                number: 'Broj pasoša',
                passportCountry: 'Zemlja izdavanja',
                citizenship: 'Državljanstvo',
                expireDate: 'Datum isteka'
              }
            }
          },
          footer: {
            info: [
              'Hvala vam na Vašoj rezervaciji.Ukoliko imate bilo kakvih pitanja slobodno nas kontaktirajte.',
              'Vaš $COMPANY$ tim.'
            ],
            signature: {
              contactNumber: 'Telefon',
              email: 'Email',
              website: 'Web sajt'
            }
          }
        }
      }
    },
    footer: {
      menuLeft: [
        {
          label: 'USLOVI I UPUTSTVA',
          list: [
            {
              label: 'Uslovi korišćenja veb sajta',
              url: '/sr/uslovi-koriscenja-veb-sajta',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/uslovi-koriscenja-veb-sajta',
              method: 'GET'
            },
            {
              label: 'Opšti uslovi putovanja',
              url: '/sr/opsti-uslov-putovanja',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/opsti-uslov-putovanja',
              method: 'GET'
            },
            {
              label: 'Načini plaćanja',
              url: '/sr/nacini-placanja',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/nacini-placanja',
              method: 'GET'
            },
            {
              label: 'Uputstvo za uplatu',
              url: '/sr/uputstvo-za-uplatu',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/uputstvo-za-uplatu',
              method: 'GET'
            },
            {
              label: 'Politika privatnosti',
              url: '/sr/politika-privatnosti',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/politika-privatnosti',
              method: 'GET'
            }
          ]
        },
        {
          label: 'PROMOCIJE',
          list: [
            {
              label: 'Uslovi korišćenja veb sajta',
              url: '/sr/uslovi-koriscenja-veb-sajta',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/uslovi-koriscenja-veb-sajta',
              method: 'GET'
            },
            {
              label: 'Opšti uslovi putovanja',
              url: '/sr/opsti-uslov-putovanja',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/opsti-uslov-putovanja',
              method: 'GET'
            },
            {
              label: 'Načini plaćanja',
              url: '/sr/nacini-placanja',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/nacini-placanja',
              method: 'GET'
            },
            {
              label: 'Uputstvo za uplatu',
              url: '/sr/uputstvo-za-uplatu',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/nacini-placanja',
              method: 'GET'
            },
            {
              label: 'Politika privatnosti',
              url: '/sr/politika-privatnosti',
              apiUrl: 'https://putnik-backend.ibe-servers.com/sr/page/politika-privatnosti',
              method: 'GET'
            }
          ]
        }
      ],
      menuRight: {
        topMenu: {
          label: 'PRETPLATITE SE NA NAŠ NEWS LETTER I PRVI SAZNAJTE NAJPOVOLJNIJE PONUDE',
          form: {
            apiUrl: 'https://putnik-backend.ibe-servers.com/sr/subscribe',
            method: 'POST',
            fields: [
              {
                type: 'text',
                placeholder: 'E-mail',
                errors: {
                  empty: 'Ovo polje je obavezno.',
                  email: 'E-mail adresa nije validna.'
                }
              },
              {
                type: 'button',
                placeholder: 'Pretplati se'
              }
            ]
          }
        },
        bottomMenu: {
          label: 'PRATITE NAS',
          icons: [
            {
              icon: 'https://putnik-backend.ibe-servers.com/assets/images/fb.svg',
              url: 'https://www.facebook.com/$COMPANY$.Srbija'
            },
            {
              icon: 'https://putnik-backend.ibe-servers.com/assets/images/in.svg',
              url: 'https://www.linkedin.com/company/$COMPANY$-d-o-o-?trk=tyah'
            },
            {
              icon: 'https://putnik-backend.ibe-servers.com/assets/images/twitter.svg',
              url: 'https://twitter.com/aviokarta'
            }
          ]
        }
      },
      copyright: {
        label: '&copy; Copyright $COMPANY$ 2023'
      }
    }
  },
  default404: {
    dummy: true,
    status: null,
    data: {
      config: {},
      header: {},
      content: {
        headline: '404',
        subtitle: '',
        text: '',
        button: '',
        redirectUrl: ''
      },
      footer: {}
    }
  }
};
