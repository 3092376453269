import { computed } from 'vue';
import { useStore } from 'vuex';
import detailUtils from '../../utils/detailUtils';

export default {
  name: 'FlightTime',
  components: {},
  props: ['date', 'time', 'airport', 'description', 'dark'],
  inheritAttrs: false,
  setup: (props) => {
    const store    = useStore();
    const dark     = props.dark;
    const jan01ify = detailUtils.jan01ify;
    const airport     = computed(() => typeof props.airport === 'string' ? detailUtils.getAirport(props.airport) : props.airport);
    const airportName = computed(() => store.getters.flightResults.baggage);
    return {
      dark,
      airportName,
      description: props.description,
      jan01ify,
      airport,
      date: computed(() => props.date),
      time: computed(() => props.time)
    };
  }
};
