import { computed } from 'vue';
import * as bc from './dummyBreadcrumb';

export default {
  name: 'MyBreadcrumb',
  components: {},
  props: ['breadcrumbNavigation'],
  setup: (props) => {
    const mybreadcrumbs = computed(() => bc.unformatted);
    var breadcrumbs = computed(() => bc.formatted);

    return {
      breadcrumbs,
      mybreadcrumbs
    };
  }
};
