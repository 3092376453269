<section class="flight-details-modal-content">
  <!-- HEADER -->
  <div class="px-6 col-12 details-header">
    <div class="py-2">
      <div class="flex justify-content-end align-items-start -mr-5">
        <div class="close-sidebar-button p-button-lg flex align-items-center"
             @click="() => { isDetailsModalOpen = false }">
          <i class="pi pi-times text-xl"></i>
        </div>
      </div>
      <div class="flex justify-content-between align-items-center">
        <!-- CITIES -->
        <div class="flex justify-content-center align-items-center">
          <div class="text-right text-xl">{{ flightDetails.oda.city }}</div>
          <div class="px-2 text-center mt-1"><i class="pi pi-sort-alt"></i></div>
          <div class="text-xl">{{ flightDetails.oaa.city }}</div>
        </div>

        <!-- PRICE PER PERSON -->
        <div class="flex">
          <div>
            <div class="price-per-person-label text-xs mt-1">
              {{ pageLabels.pricePerPerson }}
            </div>
            <div class="price-per-person-amount text-xl font-semibold">
              <FormattedCurrency :currency="flightDetails.travelers.ADT.fare.currency"
                                 :amount="flightDetails.travelers.ADT.fare.totalAmount"
                                 :currencyPosition="flightDetails.totalPrice.currencyPosition" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gray-400 h1p"> </div>

  <!-- LEGS -->
  <!-- DEPARTURE -->
  <div class="departure-legs">
    <div class="departure-header flex flex-wrap">
      <div class="px-6 col-12 flex">
        <div class="col-7 px-0 text-lg font-medium"><span>{{ flightDetails.pageLabels.outbound }}</span></div>
        <div class="col-3 text-xs text-center">
          <div class="text-gray-600">{{ flightDetails.pageLabels.flightDurationTotal }}:</div>
          <div class="font-medium">{{ flightDetails.flight.outbound.duration }}</div>
        </div>
        <div class="col-2">
          <!-- empty col just in case -->
        </div>
      </div>
      <div class="col-12 px-6 pt-0">
        <div v-for="(leg, index) in flightDetails.flight.outbound.segments">
          <div v-if="index > 0"
               class="py-2">
            <div>
              <div class="flex bg-gray-200 border-round">
                <div class="col-7 text-xs">
                  {{ flightDetails.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city }} ({{ leg.departure.code
                  }})
                </div>
                <div class="col-3 text-xs text-center">
                  {{ leg.stopDuration }}
                </div>
                <div class="col-2">
                  <!-- empty col just in case -->
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-content-between">
            <div class="col-7 px-0 flex justify-content-between">
              <FlightTime class="start-time"
                          :description="true"
                          :airport="getAirport(leg.departure.code)"
                          :date="leg.departure.date"
                          :time="leg.departure.time"></FlightTime>
              <div class="flex flex-auto align-items-center">
                <div class="col-12 px-4">
                  <MobileStopDrawing></MobileStopDrawing>
                </div>
              </div>
              <FlightTime class="end-time"
                          :description="true"
                          :airport="getAirport(leg.arrival.code)"
                          :date="leg.arrival.date"
                          :time="leg.arrival.time">
              </FlightTime>
            </div>
            <div class="col-3 flex align-items-center text-xs text-gray-600 justify-content-center">
              {{ leg.duration }}
            </div>
            <div class="col-2 p-0 flex flex-wrap align-items-center justify-content-end flight-number">
              <div class="flex flex-wrap align-items-center">
                <img :src="getAirlineLogo(leg.marketingAirline)"
                     class="dialog-details-logo w-12"
                     alt="Airline logo">
                <div class="w-12">LUFTHANSA</div>
              </div>
              <div class="flight-code text-gray-600 text-xs pt-0 text-right pr-0 white-space-nowrap">
                {{ leg.marketingAirline }}-{{ leg.flightNumber }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-400 h1p"> </div>

  <!-- Return -->
  <div class="return-legs">
    <div v-if="flightDetails.flight.inbound"
         class="return-header flex flex-wrap justify-content-between">
      <div class="col-12 px-6 flex">
        <div class="col-7 px-0 text-lg font-medium"><span>{{ flightDetails.pageLabels.inbound }}</span></div>
        <div class="col-3 text-xs text-center">
          <div class="text-gray-600">{{ flightDetails.pageLabels.flightDurationTotal }}:</div>
          <div class="font-medium">{{ flightDetails.flight.inbound.duration }}</div>
        </div>
        <div class="col-2">
          <!-- empty col just in case -->
        </div>
      </div>
      <div class="col-12 px-6 pt-0">
        <div v-for="(leg, index) in flightDetails.flight.inbound.segments">
          <div v-if="index > 0"
               class="py-2">
            <div>
              <div class="flex bg-gray-200 border-round">
                <div class="col-7 text-xs">
                  {{ flightDetails.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city }} ({{ leg.departure.code
                  }})
                </div>
                <div class="col-3 text-xs text-center">
                  {{ leg.stopDuration }}
                </div>
                <div class="col-2">
                  <!-- empty col just in case -->
                </div>
              </div>
            </div>

          </div>
          <div class="flex flex-wrap justify-content-between">
            <div class="col-7 px-0 flex justify-content-between">
              <FlightTime class="start-time"
                          :description="true"
                          :airport="getAirport(leg.departure.code)"
                          :date="leg.departure.date"
                          :time="leg.departure.time"></FlightTime>
              <div class="flex flex-auto align-items-center">
                <div class="col-12 px-4">
                  <MobileStopDrawing></MobileStopDrawing>
                </div>
              </div>
              <FlightTime class="end-time"
                          :description="true"
                          :airport="getAirport(leg.arrival.code)"
                          :date="leg.arrival.date"
                          :time="leg.arrival.time">
              </FlightTime>
            </div>
            <div class="col-3 flex align-items-center text-xs text-gray-600 justify-content-center">
              {{ leg.duration }}
            </div>
            <div class="col-2 p-0 flex flex-wrap align-items-center justify-content-end flight-number">
              <div class="flex align-items-center">
                <img :src="getAirlineLogo(leg.marketingAirline)"
                     class="dialog-details-logo"
                     alt="Airline logo">
                <div class="w-12">LUFTHANSA</div>
              </div>
              <div class="flight-code text-gray-600 text-xs pt-0 text-right pr-0 white-space-nowrap">
                {{ leg.marketingAirline }}-{{ leg.flightNumber }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white justify-content-between class-perks-and-select-button">
    <div class="bg-gray-400 h1p"> </div>
    <div class="flex">

      <!-- CLASS PERKS -->
      <div class="col-10 p-3 pl-6 grid grid-nogutter class-perks">
        <div v-if="'outbound' in flightDetails.priceClasses"
             class="col-6">
          <div class="col-12">
            <div v-if="!comparePriceClasses">{{ flightDetails.pageLabels.departure }}</div>
            <div v-if="'outbound' in flightDetails.priceClasses"
                 class="font-semibold col-12 pl-0 pb-1">
              {{ getPriceClass(flightDetails.priceClasses.outbound.priceCode).name }}
            </div>
            <div class="col-12 grid flex p-0">
              <div v-if="'outbound' in flightDetails.priceClasses"
                   class="col-12 p-0"
                   v-for="(perk, key) in getPriceClass(flightDetails.priceClasses.outbound.priceCode).items">
                <div class="flex align-items-center h-2rem">
                  <div class="flex align-items-center justify-content-center pr-2">
                    <img :src="perk"
                         class="perks-image" />
                  </div>
                  <div class="font-medium text-xs">
                    <span>{{ key }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="'inbound' in flightDetails.priceClasses"
             class="col-6">
          <div class="col-12">
            <div class="test1" v-if="!comparePriceClasses">{{ flightDetails.pageLabels.arrival }}</div>
            <div v-if="!comparePriceClasses"
                 class="font-semibold col-12 pl-0 pb-1">
              {{ getPriceClass(flightDetails.priceClasses.inbound.priceCode).name }}
            </div>
            <div v-if="'inbound' in flightDetails.priceClasses && !comparePriceClasses"
                 class="col-12 p-0"
                 v-for="(perk, key) in getPriceClass(flightDetails.priceClasses.inbound.priceCode).items">
              <div class="flex align-items-center h-2rem">
                <div class="flex align-items-center justify-content-center pr-2">
                  <img :src="perk"
                       class="perks-image" />
                </div>
                <div class="font-medium text-xs">
                  <span>{{ key }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TOTAL PRICE AND SELECT BUTTON -->
      <div class="col-2 p-3 pr-6 total-price-and-select-button relative">
        <div class="absolute bottom-0 mb-3 right-0 mr-6">
          <div class="text-left">
            <div class="text-xs">{{ pageLabels.priceFor }} {{ totalTravelers }} {{ totalTravelers > 1 &&
              pageLabels.travelers || pageLabels.traveler
              }}</div>
            <div class="price-per-person-amount text-lg font-semibold">
              <FormattedCurrency :currencyPosition="flightDetails.totalPrice.currencyPosition"
                                 :currency="flightDetails.totalPrice.currency"
                                 :amount="flightDetails.totalPrice.amount" />
            </div>
          </div>
          <div class="col-12 p-0">
            <Button class="col-12 dialog-pick-flight flex justify-content-center ">
              {{ pageLabels.buttonSelect }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>