
  <section class="breadcrumb">
    <Breadcrumb :home="breadcrumbs.home" :model="breadcrumbs.items">
      <template #item="{ item }">
        <a :href="item.url" :class="{ active: item.state == 'current', 'text-gray-300': item.state == 'next' }" class="breadcrumb-item flex justify-content-center align-items-center">
          <img v-if="item.icon" :src="item.icon" class="home-icon"><span v-if="!item.icon">{{item.label}}</span>
        </a>
      </template>
    </Breadcrumb>
  </section>
