<template>
  <Toast />
  <Toast group="expiry" />
  <Loader v-if="loading"></Loader>
  <router-view :style="{ overflow: loading ? 'hidden' : 'visible' }"
               ref="app"></router-view>
</template>

<script>
/* eslint-disable */
import Header from "../src/components/Header/HeaderComponent.vue";
import Footer from "../src/components/Footer/FooterComponent.vue";
import DateTextInput from "../src/components/DateTextInput/DateTextInputComponent.vue";
import Toast from "primevue/toast";
import { computed, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { usePrimeVue } from "primevue/config";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";

export default {
  name: "App",
  Components: {
    Header,
    Footer,
    Toast,
    DateTextInput
  },
  setup: () => {

    const store = useStore();

    const primeVue = usePrimeVue();
    const router = useRouter();
    const toast = useToast();
    primeVue.config.locale.dayNames = computed(() => store.getters.mainData.config.datepicker.dayNames);
    primeVue.config.locale.dayNamesShort = computed(() => store.getters.mainData.config.datepicker.dayNamesShort);
    primeVue.config.locale.dayNamesMin = computed(() => store.getters.mainData.config.datepicker.dayNamesMin);
    primeVue.config.locale.monthNames = computed(() => store.getters.mainData.config.datepicker.monthNames);
    primeVue.config.locale.monthNamesShort = computed(() => store.getters.mainData.config.datepicker.monthNamesShort);
    primeVue.config.locale.firstDayOfWeek = computed(() => store.getters.mainData.config.datepicker.firstDayOfWeek);

    // load language from href
    const href = window.location.href;
    if (href.includes("/en")) {
      store.dispatch("setLanguage", "en");
    } else if (href.includes("/sr")) {
      // console.log('here')
      store.dispatch("setLanguage", "sr");
    } else if (href.includes("/de")) {
      store.dispatch("setLanguage", "de");
    }

    const checkExpiry = () => {
      const expiryDate = new Date(store.getters.expiry);
      const now = new Date();

      if (expiryDate < now) {
        store.dispatch("setDisplayExpiredFlightModal", true);
      }
    };

    window.addEventListener("focus", (_e) => {
      checkExpiry();
    });


    window.addEventListener("blur", (_e) => {
      checkExpiry();
    });

    const routerValue = computed(() => router.currentRoute.value);
    watch(() => routerValue, (nv_) => {
      toast.removeAllGroups();
    });
    const loading = computed(() => store.getters.loading);
    return { loading };
  },
  components: { DateTextInput }
};
/* eslint-enable */
</script>

<style lang="scss">
@import "./primeflex.css";
@import "./saga-blue-overrides.scss";
// @import "./primeflex-overrides.css";
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
/* @import 'primeicons/primeicons.css'; */

/* @import "modules/vue-hotel-datepicker/dist/vueHotelDatepicker.css"; */

body {
  margin: 0 !important;
  font-family: Poppins, Verdana, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  overscroll-behavior-y: contain;
}

input::placeholder {
  color: #808080 !important;
}

.p-paginator {
  button:disabled {
    background-color: #898c8f;
  }
}

.flight-results .single-flight .grid .col-4:nth-of-type(4) .flight-time .city-and-airport {
  position: absolute;
  right: 0;
  padding-right: 0.5rem;
}

.flight-results .single-flight .grid .col-4:nth-of-type(3) .flight-time .city-and-airport {
  position: absolute;
  right: 0;
  padding-right: 0.5rem;
}

.dialog-pick-flight {
  background-color: #4570f1 !important;
  width: 118px !important;
}

@media (max-width: 1200px) {
  .expired-modal {
    width: 90vw !important;
  }
}

.triangle {
  &.up {
    color: red;
  }

  &.down {
    color: green;
    transform: rotate(180deg);
  }
}

.expired-modal {
  width: 35vw;

  .p-dialog-header {
    display: flex;
    justify-content: center;
    text-align: center;

    .p-dialog-title {
      font-weight: 600;
    }
  }

  .p-dialog-content {
    display: flex;
    justify-content: center;
  }

  .p-dialog-header-icons {
    display: none !important;
  }

  .p-dialog-footer {
    padding-bottom: 1rem;

    .p-button {
      outline-color: transparent !important;
    }
  }
}

.gray {
  background-color: #f2f2f2;
}

@media (max-width: 1200px) {
  .p-toast-top-right {
    width: 100vw !important;
    right: 0 !important;
    position: fixed !important;
    bottom: -17px !important;
    top: unset !important;

    & .p-toast-message {
      border-radius: 0px;
    }
  }
}

.fw-300 {
  font-weight: 300;
}

.header-sidebar {
  & a {
    text-decoration: none;
    color: #393641;

    &.router-link-exact-active {
      color: #4570f1;
    }
  }

  & Button {
    height: 40px;
    width: 40px;
    padding: 0;
    justify-content: center;
  }
}

.calendar-sidebar {
  & .p-datepicker-group {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  & .p-datepicker table td {
    width: 14.2857vw !important;
  }

  & .p-datepicker td>span {
    width: 14.285715vw !important;
    margin: 0 !important;

    &.p-highlight {
      border-right: 1px solid #4570f1;
      border-bottom: 1px solid #4570f1;
      box-shadow: 0px 1px 0px 0px #4570f1;
    }
  }

  & .p-sidebar-content {
    padding-left: 0;
    padding-right: 0;
  }

  & .upper-gradient {
    left: 0px;
    width: 100%;
    height: 0.5em;
    background: transparent linear-gradient(0deg, #ffffff00 0%, #0000001a 100%) 0% 0% no-repeat padding-box;
  }

  & .lower-gradient {
    left: 0px;
    width: 100%;
    height: 0.5em;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #0000001a 100%) 0% 0% no-repeat padding-box;
  }

  & .date-buttons {
    width: 263px;

    @media (max-width: 1200px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    & button {
      width: 49%;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }

    &:nth-child(2) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0.5px;

      &.p-disabled {
        border-left: 1px solid #020350 !important;
      }
    }

    & .departure-calendar-button {

      &:active,
      &:hover {
        z-index: 99;
        border-color: #2196f3 !important;
        box-shadow: 1px 0px 0px 0px #36a0f4 !important;
        border-right-width: 0;
      }
    }
  }
}

/* scrollbar */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.mobile-details-logo {
  max-height: 50px;
  max-width: 50px;
}

.dialog-details-logo {
  max-width: 50px;
  max-height: 50px;
  margin-right: 0.5rem;
}

.perks-image {
  width: 18px;
  height: 18px;
}

.flight-details-dialog {
  &-header {
    position: absolute;
  }

  border-radius: 5px;
  width: 1140px;
  box-shadow: 0px 1px 6px #00000066;

  .class-perks {
    min-height: 116px;
    border-bottom-left-radius: 4px;
    background: white;
  }

  .p-dialog-content {
    &::-webkit-scrollbar {
      display: none !important;

      &-thumb,
      &-track {
        display: none !important;
      }
    }

    border: 1px solid #c4c6d6;
    border-radius: 5px;
    padding: 0;
  }

  .pi-sort-alt {
    transform: rotate(90deg);
  }

  .total-price-and-select-button {
    border-bottom-right-radius: 4px;
    background: white;
  }
}

.h1p {
  height: 0.99px;
}

.grid {
  margin: 0 !important;
}

.dynwid {
  @media (min-width: 1200px) {
    width: 1140px;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.disable-user-select-for-all {
  -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  -webkit-focus-ring-color: #2196f3 !important;
  color: #383640;
  overflow: visible;
}

* {
  -webkit-touch-callout: none;
  -webkit-focus-ring-color: #2196f3 !important;
  color: #383640;
  overflow: visible;
}

.details-sidebar {
  .flight-times-and-wrapper {
    overflow: visible;

    .takeoff-airport {
      text-align: left;

      .airport-name {
        left: 0;
      }
    }

    .landing-airport {
      text-align: right;

      .airport-name {
        right: 0;
      }
    }
  }

  .p-sidebar-content {
    padding: 0;
  }

  .pi-sort-alt {
    transform: rotate(90deg);
  }
}

.close-sidebar-button {
  background: transparent;

  .pi {
    color: #4570f1;
  }
}

.loading-sidebar {
  & .p-sidebar-content {
    height: 100%;
  }
}

.load-container {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .loader {
    margin: 100px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(127, 127, 127, 0.2);
    border-right: 1.1em solid rgba(127, 127, 127, 0.2);
    border-bottom: 1.1em solid rgba(127, 127, 127, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
