import { computed } from 'vue';

export default {
  name: 'StopDrawing',
  components: {},
  props: ['stops'],
  setup: (props) => {
    const stops = computed(() => props.stops);
    return {
      stops
    };
  }
};
