<section class="header ibe-header" v-if="headerSessionResolved">
  <div class="flex justify-content-center">
    <div class="dynwid flex justify-content-between align-items-center">
      <div class="logo flex">
        <router-link class="flex align-items-center" :to="toHome">
          <img :src="imgsrc" />
        </router-link>
      </div>

      <div>
        <div class="menu">
          <div class="flex">
            <div v-for="menuItem in navMenu">
              <router-link class="menu-item" class-active="active" :to="menuItem.url" exact>
                {{ menuItem.label }}
              </router-link>
            </div>
            <div class="flex align-items-center">
              <div class="divider ml-3 mr-3"></div>
              <Dropdown class="language-dropdown pt-0 ml-2" v-model="selectedLanguage" :options="languageMenu" optionLabel="label" @change="onLanguageChange($event)" :panelClass="'language-panel'" />
            </div>
          </div>
        </div>
        <div>
          <Button class="sidebar-button justify-content-center" @click="sidebarChecked = true">
            <div class="flex justify-content-center">
              <i class="pi pi-bars"></i>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="header-bottom"> </div>

  <Sidebar class="pt-1 header-sidebar" v-model:visible="sidebarChecked" :position="'right'" @hide="sidebarChecked = false">
    <div class="grid">
      <div class="col-12 border-bottom-1 border-gray-300 text-center py-4" v-for="menuItem in navMenu">
        <router-link class="menu-item" class-active="active" :to="menuItem.url" exact>
          {{ menuItem.label }}
        </router-link>
      </div>
    </div>
    <div class="grid mt-2">
      <div class="col-4 text-center" v-for="menuItem in languageMenu">
        <!-- <a :href="menuItem.url"> -->
          <Button @click="goToNewLang(menuItem.label)" :class="{ 'bg-white text-gray-900': !(menuItem.label.toLowerCase() == selectedLanguage.label?.toLowerCase()),  }">
            {{ menuItem.label }}
          </Button>
          <!-- <Button @click="onSidebarLanguageChange($event, menuItem)" :class="{ 'bg-white text-gray-900': !(menuItem.label.toLowerCase() == selectedLanguage.label?.toLowerCase()),  }"> -->
            <!-- {{ menuItem.label }} -->
          <!-- </Button> -->
        <!-- </a> -->
      </div>
    </div>
  </Sidebar>
</section>