<div class="flight-time">
  <div>
    <span v-if="time" class="mr-2 time">{{ time }}</span>
    <span v-if="date" class="date" :class="{'date-dark': dark}">{{ jan01ify(date) }}</span>
  </div>
  <div v-if="airport" class="city-and-airport">
    <div class="city-and-code">
      <span>{{ airport.city }}</span>
      <!-- <span class="text-gray-600"> {{ airport.code }}</span> -->
      <span> {{ airport.code }}</span>
    </div>
    <div v-if="description" class="scroll-holder relative">
      <div class="airport-name">
        {{ airport.airport }}
      </div>
    </div>
  </div>
</div>