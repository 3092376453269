import axios from 'axios';
import { useRouter } from 'vue-router';

const api = axios.create({
  baseURL: 'https://' + process.env.VUE_APP_BASE_URL + '/',
  headers: {
    'Content-Type': 'application/vnd.api+json'
  }
});

// request interceptor
api.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

// response interceptor
api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const router = useRouter();
  router.push('/sr');
  return Promise.reject(error);
});

export default api;
